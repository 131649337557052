import { FieldProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { numberAsText } from '../utils/formatters';

export const NumberField = (props: FieldProps<number | "" | null> & { ignorePrecision?: boolean, disabled?: boolean, placeholder: string, className: string | undefined, onChange: (value: number | null) => void }) => {

    const [value, setValue] = useState("");

    useEffect(() => {

        if (typeof props.field.value == "number") {
            setValue(numberAsText(props.field.value, props.ignorePrecision))
        }
        else
            setValue("")

    }, [props.field.value])

    return <input type="text"
        name={props.field.name}
        className={props.className}
        disabled={props.disabled}
        onKeyDown={e => {
            const key = e.key;

            if (key === 'Enter' || key === 'Tab') {
                props.form.submitForm();
                return;
            }

            const isValidKey = key === 'ArrowLeft' || key === 'ArrowRight' || key === "-" || key === 'Backspace' || key === 'Delete' || key === ',' || key === '.' || /\d/.test(key) || e.ctrlKey || e.metaKey;

            if (!isValidKey) {
                e.preventDefault();
            }
        }}
        onPaste={e => {
            e.preventDefault();

            const value = e.clipboardData.getData('text/plain');

            let finalValue: number | "";

            if (value) {
                const formattedNumber = value.replace(",", ".");

                finalValue = Number.parseFloat(formattedNumber)
            }
            else {
                finalValue = 0;
            }

            if (Number.isNaN(finalValue))
                return;

            setValue(numberAsText(0, props.ignorePrecision));

            if (props.onChange)
                props.onChange(finalValue);
            else
                props.form.setFieldValue(props.field.name, finalValue);

        }}
        onFocus={() => {
            setValue(props.field.value == 0 ? '' : numberAsText(props.field.value, props.ignorePrecision))
        }}
        onChange={(e) => {
            setValue(e.target.value);
        }}
        onBlur={e => {
            const fieldValue = e.target.value

            let finalValue: number | null;

            if (fieldValue) {
                const formattedNumber = fieldValue.replace(",", ".");

                finalValue = Number.parseFloat(formattedNumber)
            }
            else if (typeof props.field.value == "number" && props.field.value == 0) {
                finalValue = 0;
                setValue("0")
            }
            else {
                finalValue = null;
            }

            if (props.onChange)
                props.onChange(finalValue);
            else
                props.form.setFieldValue(props.field.name, finalValue);
        }}
        placeholder={props.placeholder}
        value={value} />
}