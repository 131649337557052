import React, { useEffect, useRef, useState } from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { InvoiceList } from '../invoice/InvoiceListContainer';
import { useHistory } from 'react-router-dom';
import { ConcreteTenderList } from '../concrete-tender/ConcreteTenderListContainer';
import { toastWarning } from '../../shared/toastr';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';
import { DropdownMenu, DropdownMenuActionLink } from '../../shared/elements/DropdownMenu';

export const DashboardContainer = () => {
    const { t } = useOfferionTranslation();

    const dashboardLabel = t("Dashboard.pageTitle");

    return <div className="dashboard-container">

        <BreadcrumbsItem to="/dashboard">
            {dashboardLabel}
        </BreadcrumbsItem>

        <InvoiceList
            gridTitleKey="Dashboard.lastFiveInvoices"
            pageTitleKey={dashboardLabel}
            hideFilters={true}
            hidePaging={true}
            defaultPageSize={5}
            buttons={({ canCreateTenders, canCreateInvoices }) => {
                return <Actions canCreateTenders={canCreateTenders} canCreateInvoices={canCreateInvoices} />
            }} />

        <ConcreteTenderList
            gridTitleKey=""
            pageTitleKey="Dashboard.lastFiveOffers"
            hideFilters={true}
            hidePaging={true}
            hideHeader={true}
            defaultPageSize={5}
            buttons={() => null}
        />
    </div>
}

type ActionProps = {
    canCreateTenders: boolean,
    canCreateInvoices: boolean,
}

function Actions({ canCreateTenders, canCreateInvoices }: ActionProps) {
    const { t } = useOfferionTranslation();
    const history = useHistory();

    const [showSideMenu, setShowSideMenu] = useState(false)
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {

        const handleClick = (e: MouseEvent) => {
            const isClickInside = ref.current && ref.current.contains(e.target as any);

            if (!isClickInside)
                setShowSideMenu(false);
        }

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        }
    }, [])


    function handleCreateTender() {
        if (canCreateTenders) {
            history.push("/offer/new")
        }
        else {
            const message = t('Allaround.Message.packageLimitReached', {
                offersOrInvoicesGenitiv: t('Allaround.Message.offersGenitiv'),
            });

            toastWarning(message, true)
        }
    }

    function handleCreateInvoice() {

        if (canCreateInvoices) {
            history.push("/invoice/new")
        }
        else {
            const message = t('Allaround.Message.packageLimitReached', {
                offersOrInvoicesGenitiv: t('Allaround.Message.invoicesGenitiv'),
            });

            toastWarning(message, true)
        }
    }

    return (
        <>
            {/* Mobile screen - dropdown */}
            <div className="table__button-container small-only">
                <DropdownMenu>
                    <DropdownMenuActionLink onClick={handleCreateTender} translationKey="Offers.Header.newOffer" />
                    <DropdownMenuActionLink onClick={handleCreateInvoice} translationKey="Invoices.Header.newInvoice" />
                </DropdownMenu>
            </div>
            {/* <div ref={ref} className="table__cell table__cell__menu" onClick={() => showSideMenu && setShowSideMenu(false)}>
                <span className="table__cell table__cell__menu" ref={ref} onClick={() => showSideMenu && setShowSideMenu(false)}>
                    <div
                        className={`button-outer overlay__item overlay__menu overlay__item--bottom-left ${showSideMenu ? 'overlay__item--active overlay__item--show-overlay' : ''
                            }`}>
                        <button
                            type="button"
                            onClick={() => setShowSideMenu(!showSideMenu)}
                            className={`button button--gray overlay__button  ${showSideMenu ? 'overlay__button--active' : ''}`}>
                            <span className="icon icon__menu-blue"></span>
                        </button>

                        <div className="overlay">
                            <ul className="overlay__list">
                                <li onClick={handleCreateTender}>
                                    <span className="button-text table__button-text mod-margin-left-4">{t('Offers.Header.newOffer')}</span>
                                </li>
                                <li onClick={handleCreateInvoice}>
                                    <span className="button-text table__button-text mod-margin-left-4">{t('Invoices.Header.newInvoice')}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </span>
            </div> */}

            {/* Larger screen - regular buttons */}
            <div className="table__button-container small-hide">
                <button className="button button--white table__button" onClick={handleCreateTender}>
                    <span className="icon icon__plus"></span>
                </button>
                <span className="button-text table__button-text mod-margin-left-4">{t('Offers.Header.newOffer')}</span>
            </div>

            <div className="table__button-container small-hide">
                <button className="button button--white table__button" onClick={handleCreateInvoice}>
                    <span className="icon icon__plus"></span>
                </button>
                <span className="button-text table__button-text mod-margin-left-4">{t('Invoices.Header.newInvoice')}</span>
            </div>
        </>
    )
}