export const croatian = {												
    "Allaround.Button.save": `Spremi`,												
    "Allaround.Button.accept": `Prihvati`,												
    "Allaround.Button.select": `Odaberi`,												
    "Allaround.Button.cancel": `Odustani`,												
    "Allaround.Button.modify": `Uredi`,												
    "Allaround.Button.delete": `Izbriši`,												
    "Allaround.Button.yes": `Da`,												
    "Allaround.Button.no": `Ne`,												
    "Allaround.Button.send": `Pošalji`,												
    "Allaround.Button.print": `Ispiši`,												
    "Allaround.Button.download": `Preuzmi`,												
    "Allaround.Button.search": `Traži`,												
    "Allaround.selectFilePlaceHolder": `Dokument nije odabran`,												
    "Allaround.Popup.warningTitle": `Upozorenje`,												
    "Allaround.Popup.deleteTitle": `Jeste li sigurni?`,												
    "Allaround.Question.delete": `Da li zaista želite izbrisati`,												
    "Allaround.Message.successDelete": `Uspješno ste izbrisali`,												
    "Allaround.Message.packageLimitReached": `Dosegli ste dozvoljeni broj {{offersOrInvoicesGenitiv}} u ovom mjesecu. Uvjete prelaska na veći paket možete pogledati <a href=\"http://www.izracunko.com/cijene#nadoplata\" target=\"_blank\">ovdje (kliknite)</a>.`,												
    "Allaround.Message.offersGenitiv": `ponuda`,												
    "Allaround.Message.offersGenitiv:lawyer": `troškovnika`,												
    "Allaround.Message.invoicesGenitiv": `računa`,												
    "Allaround.Message.offersAndInvoicesGenitiv": `ponuda i računa`,												
    "Allaround.Message.offersAndInvoicesGenitiv:lawyer": `troškovnika i računa`,												
    "Allaround.Message.packageThresholdReached": `Možete napraviti još <b>{{numOffersOrInvoicesLeft}} {{offerOrInvoiceSingular}}</b> u ovom mjesecu. Napravili ste <b>{{numOffersOrInvoices}}</b> od <b>{{numOffersOrInvoicesInPackage}}</b> u vašem paketu. Za pogledati uvjete prelaska na veći paket <a href=\"http://www.izracunko.com/cijene#nadoplata\" target=\"_blank\">kliknite ovdje</a>.`,												
    "Allaround.Message.packageThresholdReachedTwofold": `Možete napraviti još <b>{{numOffersLeft}} {{offerSingular}}</b> i <b>{{numInvoicesLeft}} {{invoiceSingular}}</b> u ovom mjesecu. Napravili ste <b>{{numOffers}}</b> od <b>{{numOffersInPackage}}</b> {{offerPlural}} i <b>{{numInvoces}}</b> od <b>{{numInvoicesInPackage}}</b> {{invoicePlural}} u vašem paketu. Za pogledati uvjete prelaska na veći paket <a href=\"http://www.izracunko.com/cijene#nadoplata\" target=\"_blank\">kliknite ovdje</a>.`,												
    "Allaround.Message.offerAkuzativ": `ponudu`,												
    "Allaround.Message.offerAkuzativ:lawyer": `troškovnik`,												
    "Allaround.Message.offerPlural1": `ponude`,												
    "Allaround.Message.offerPlural1:lawyer": `troškovnika`,												
    "Allaround.Message.offerPlural2": `ponuda`,												
    "Allaround.Message.offerPlural2:lawyer": `troškovnika`,												
    "Allaround.Message.invoiceAkuzativ": `račun`,												
    "Allaround.Message.invoicePlural": `računa`,												
    "Allaround.Message.subscriptionExpiresSoon": `Do isteka pretplatnog perioda ostalo vam je <b>{{numDays}} {{days}}</b>. Za produžetak korištenja naše usluge molimo uplatite željeni paket. Za uvjete <a href=\"http://www.izracunko.com/cijene\" target=\"_blank\">kliknite ovdje</a>.`,												
    "Allaround.Message.day": `dan`,												
    "Allaround.Message.dayPlural": `dana`,												
    "Allaround.Message.subscriptionExpired": `Vaš paket istekao je <b>{{packageExpirationDate}}</b>. Automatski ste prešli u paket besplatno, u kojem možete napraviti znatno manji broj dokumenata i nemate mogućnost fiskalizacije računa. Za prelazak na veći paket <a href=\"http://www.izracunko.com/cijene\" target=\"_blank\">kliknite ovdje</a>.`,												
    "Allaround.Message.invoicesNotFiscalized": `Imate račune koji nisu fiskalizirani, a trebali bi biti u roku od 2 dana nakon izdavanja. Ukoliko ih želite fiskalizirati, odaberite njihov ispis. <a href=\"{{link}}\">Kliknite ovdje</a> kako bi pronašli sve takve račune.`,												
    "Allaround.Message.unknownError": `Dogodila se pogreška i nije uspjela tražena radnja.`,												
    "Allaround.Message.AddressAndOIBNotChanged": `Kako bi dovršili podešavanje programa za rad, trebate unijeti vaš porezni broj i adresu. To možete napraviti u <a href=\"{{link}}\">postavkama</a>.`,												
    "Allaround.Message.OIBNotChanged": `Kako bi dovršili podešavanje programa za rad, trebate unijeti vaš porezni broj. To možete napraviti u <a href=\"{{link}}\">postavkama</a>.`,												
    "Allaround.Message.AddressNotChanged": `Kako bi dovršili osnovno podešavanje programa za rad, trebate unijeti vašu adresu. To možete napraviti u <a href=\"{{link}}\">postavkama</a>.`,												
    "Allaround.CompanyType.basic": `Osnovno`,												
    "Allaround.CompanyType.lawyer": `Odvjetnik`,												
    "Allaround.CompanyType.windowMaker": `Prodavatelj/proizvođač prozora i vrata`,												
    "Allaround.CompanyType.renter": `Iznajmljivač`,												
    "Allaround.CompanyType.other": `Ostalo`,												
    "Allaround.Currency.EUR.title": `Euro`,												
    "Allaround.Currency.EUR.shortcode": `€`,												
    "Allaround.Currency.HRK.title": `Hrvatska kuna`,												
    "Allaround.Currency.HRK.shortcode": `kn`,												
    "Allaround.Currency.USD.title": `Američki dolar`,												
    "Allaround.Currency.USD.shortcode": `$`,												
    "Allaround.Currency.GBP.title": `Britanska funta`,												
    "Allaround.Currency.GBP.shortcode": `£`,												
    "Allaround.Currency.BAM.title": `Konvertibilna marka BiH`,												
    "Allaround.Currency.BAM.shortcode": `KM`,												
    "Allaround.Currency.CHF.title": `Švicarski franak`,												
    "Allaround.Currency.CHF.shortcode": `Fr`,												
    "Allaround.Currency.RSD.title": `Srpski dinar`,												
    "Allaround.Currency.RSD.shortcode": `din`,												
    "Allaround.Language.InvoiceOrOffer.printSettings": `Iz postavki ispisa`,												
    "Allaround.Language.InvoiceOrOffer.cs-cz": `Češki`,												
    "Allaround.Language.InvoiceOrOffer.de-de": `Njemački`,												
    "Allaround.Language.InvoiceOrOffer.en-gb": `Engleski`,												
    "Allaround.Language.InvoiceOrOffer.es-es": `Španjolski`,												
    "Allaround.Language.InvoiceOrOffer.fr-fr": `Francuski`,												
    "Allaround.Language.InvoiceOrOffer.hr-hr": `Hrvatski`,												
    "Allaround.Language.InvoiceOrOffer.hu-hu": `Madžarski`,												
    "Allaround.Language.InvoiceOrOffer.it-it": `Talijanski`,												
    "Allaround.Language.InvoiceOrOffer.pl-pl": `Poljski`,												
    "Allaround.Language.InvoiceOrOffer.sr-Latn": `Srpski`,												
    "Allaround.Language.Interface.hr-hr": `Hrvatski`,												
    "Allaround.Language.Interface.en-en": `English`,												
    "Allaround.PaymentType.bankAccount.title": `Transakcijski račun`,												
    "Allaround.PaymentType.bankAccount.shortcode": `T`,												
    "Allaround.PaymentType.cash.title": `Novčanice`,												
    "Allaround.PaymentType.cash.shortcode": `G`,												
    "Allaround.PaymentType.check.title": `Ček`,												
    "Allaround.PaymentType.check.shortcode": `C`,												
    "Allaround.PaymentType.creditCard.title": `Kartica`,												
    "Allaround.PaymentType.creditCard.shortcode": `K`,												
    "Allaround.PaymentType.other.title": `Ostalo`,												
    "Allaround.PaymentType.other.shortcode": `O`,												
    "Allaround.Picture.title": `Slika`,												
    "Allaround.Picture.addPicture": `Dodaj sliku`,												
    "Allaround.Picture.removePicture": `Ukloni sliku`,												
    "Allaround.Status.draft": `U izradi`,												
    "Allaround.Status.offerSent": `Poslana`,												
    "Allaround.Status.invoiceSent": `Poslan`,												
    "Allaround.Status.offerAccepted": `Prihvaćena`,												
    "Allaround.Status.offerDeclined": `Odbijena`,												
    "Allaround.Status.invoicePastDue": `Kasni plaćanje`,												
    "Allaround.Status.invoicePaid": `Plaćen`,												
    "Allaround.Status.invoiceCancelled": `Storniran`,												
    "Allaround.Validation.required": `{{fieldName}} je obavezan podatak.`,												
    "Allaround.Validation.requiredSelect": `Ovaj podatak ({{fieldName}}) je nužno odabrati.`,												
    "Allaround.Validation.maxValue": `{{fieldName}} ne smije biti veći od {{maxValue}}.`,												
    "Allaround.Validation.maxLength": `{{fieldName}} ne smije sadržavati više od {{maxLength}} znakova.`,												
    "Allaround.Validation.minLength": `{{fieldName}} mora imati barem {{minLength}} znakova.`,												
    "Allaround.Validation.exactLength": `{{fieldName}} mora imati {{exactLength}} znakova.`,												
    "Allaround.Validation.number": `{{fieldName}} mora biti broj.`,												
    "Allaround.Validation.positiveNumber": `{{fieldName}} mora biti pozitivan broj.`,												
    "Allaround.Validation.alphanumeric": `{{fieldName}} mora sadržavati isključivo slova engleske abecede i brojeve.`,												
    "Allaround.Validation.paymentDateInFuture": `{{fieldName}} mora biti u budućnosti.`,												
    "Allaround.Validation.incorrectEntry": `{{fieldName}} nije ispravno unesen.`,												
    "Allaround.Validation.incorrectVAT": `PDV mora biti između 0 i 30%.`,												
    "Allaround.Validation.discountNegative": `Popust mora biti veći od nule.`,												
    "Allaround.Validation.discountMoreThan100": `Popust ne može biti veći od 100%.`,												
    "Allaround.Validation.discountCantBe100": `Popust ne može biti 100% kod unosa sveukupne cijene. Ako želite unijeti 100% popusta u postavkama promijenite način računanja cijene.`,												
    "Allaround.Validation.invalidPriceWithoutDiscount": `Cijena bez popusta treba biti veća od sveukupne cijene (i popust pozitivan).`,												
    "Allaround.Validation.date": `Uneseni datum nije ispravan. Datum mora biti u obliku 31.01.2020. 14:59`,												
    "Allaround.Validation.mail": `Upisana vrijednost nije u obliku e-mail adrese.`,												
    "Allaround.Validation.mailExists": `Ta e-mail adresa već postoji.`,												
    "Allaround.Validation.password": `Lozinka mora imati barem 6 znakova, od čega jedno malo, jedno veliko slovo i jedan broj.`,												
    "Allaround.Validation.passwordsDontMatch": `Unesene lozinke nisu iste.`,												
    "Allaround.Validation.maxFileSize": `Datoteka je prevelika. Maksimalna veličina je {{maxFileSize}} kb.`,												
    "Allaround.Validation.incorrectFileType": `Nedozvoljeni tip dokumenta. Dozvoljeni tipovi su: {{allowedFileTypes}}.`,												
    "Allaround.Validation.invalidAnswer": `Odgovor nije točan.`,												
    "Allaround.Validation.countryNotCroatia": `S obzirom da vaša adresa nije iz Hrvatske, račun nije moguće fiskalizirati. Kako bi mogli fiskalizirali račun, molimo promijenite u postavkama adresu da piše Hrvatska.`,												
    "Allaround.Validation.currencyNotHRK": `S obzirom da vam je adresa u Hrvatskoj, sve stavke na računu moraju biti u kunama. Kako bi to napravili, molimo promijenite valutu računa u postavkama računa.`,												
    "Allaround.Validation.Fiscalization.generalIntro": `Račun nije moguće fiskalizirati`,												
    "Allaround.Validation.Fiscalization.certificateExpired": `jer je certifikat istekao.`,												
    "Allaround.Validation.Fiscalization.OIBsDontMatch": `jer porezni broj (OIB) u postavkama ne odgovara OIB-u u certifikatu.`,												
    "Allaround.Validation.Fiscalization.tooLate": `jer je datum računa stariji od obaveznog roka za slanje na poreznu upravu (2 dana). Kontaktirajte vašeg knjigovođu za savjet.`,												
    "Allaround.Validation.Fiscalization.noRights": `jer vaša korisnička prava ne dopuštaju fiskalizaciju računa. Ovaj račun je potrebno fiskalizirati prije ispisa ili slanja pa molimo kontaktirajte vašeg administratora kako bi to napravili.`,												
    "Allaround.Validation.Fiscalization.settingsIntroMessage": `Račun nije moguće fiskalizirati dok u postavkama ne unesete`,												
    "Allaround.Validation.Fiscalization.missingCompanyAddress": `adresu`,												
    "Allaround.Validation.Fiscalization.missingCompanyCity": `grad`,												
    "Allaround.Validation.Fiscalization.missingCompanyTaxID": `porezni broj (OIB)`,												
    "Allaround.Validation.Fiscalization.missingPersonalIDs": `OIB-ove svih operatera (korisnika programa)`,												
    "Allaround.Validation.Fiscalization.zipCodeNotNumeric": `poštanski broj koji ima samo brojke.`,												
    "Allaround.DropDown.Other": `Drugo`,												
    "Allaround.DropDown.Select": `Odaberi`,												
    "Clients.Header.pageTitle": `Klijenti`,												
    "Clients.Header.newClient": `Novi klijent`,												
    "Clients.Side.searchTitle": `Pretraživanje`,												
    "Clients.Side.searchPlaceholder": `Dio naziva`,												
    "Clients.Side.searchButton": `Traži`,												
    "Clients.Table.Header.title": `Popis klijenata`,												
    "Clients.Table.emptyMessage": `<b>Nemate još niti jednog klijenta.</b><br /><br />Klijenta možete dodati pritiskom na botun 'Novi klijent'.`,												
    "Clients.Table.emptySearchMessage": `Klijent kakvog ste tražili nije pronađen.<br /><br />Možete ili promijeniti pretragu ili napraviti novog klijenta.`,												
    "Clients.Table.Header.numberOfClients": `Broj klijenata`,												
    "Clients.Table.Header.perPage": `po stranici`,												
    "Clients.Table.Header.name": `Ime`,												
    "Clients.Table.Header.city": `Mjesto`,												
    "Clients.Table.Header.mail": `Mail`,												
    "Clients.Table.ItemMenu.edit": `Uredi`,												
    "Clients.Table.ItemMenu.delete": `Izbriši`,												
    "Clients.Table.ItemMenu.agreedPrices": `Dogovoreni uvjeti`,												
    "Clients.Edit.pageTitleEdit": `Uredi klijenta`,												
    "Clients.Edit.pageTitleNew": `Unesi klijenta`,												
    "Clients.Edit.name": `Ime`,												
    "Clients.Edit.namePlaceholder": `Unesi ime klijenta`,												
    "Clients.Edit.address": `Ulica i broj`,												
    "Clients.Edit.addressPlaceholder": `Unesi adresu`,												
    "Clients.Edit.city": `Mjesto`,												
    "Clients.Edit.cityPlaceholder": `Unesi mjesto`,												
    "Clients.Edit.zipCode": `Poštanski broj`,												
    "Clients.Edit.zipCodePlaceholder": `Unesi poštanski broj`,												
    "Clients.Edit.companyNumber": `Porezni broj (OIB)`,												
    "Clients.Edit.companyNumberPlaceholder": `Unesi identifikator klijenta`,												
    "Clients.Edit.email": `Mail`,												
    "Clients.Edit.emailPlaceholder": `Unesi mail adresu`,												
    "Clients.Edit.additionalInformation": `Dodatne informacije`,												
    "Clients.Edit.country": `Država`,												
    "Clients.Edit.countryPlaceholder": `Unesi državu`,												
    "Clients.Edit.phone": `Telefon`,												
    "Clients.Edit.phonePlaceholder": `Unesi broj telefona`,												
    "Clients.Edit.cellphone": `Mobitel`,												
    "Clients.Edit.cellphonePlaceholder": `Unesi broj mobitela`,												
    "Clients.Edit.fax": `Fax`,												
    "Clients.Edit.faxPlaceholder": `Unesi broj faxa`,												
    "Clients.Edit.clientRemark": `Napomena o klijentu (interna)`,												
    "Clients.Edit.clientRemarkPlaceholder": `Unesi internu napomenu o klijentu`,												
    "Clients.Edit.printRemark": `Napomena za ispis`,												
    "Clients.Edit.printRemarkPlaceholder": `Ukoliko ovdje nešto unesete, to će se ispisati uz klijenta na ponudi ili računu.`,												
    "Clients.Edit.printRemarkPlaceholder:lawyer": `Ukoliko ovdje nešto unesete, to će se ispisati uz klijenta na troškovniku ili računu.`,												
    "Clients.Edit.Message.successUpdate": `Podaci o klijentu su uspješno spremljeni.`,												
    "Clients.Edit.Message.errorUpdate": `Greška prilikom mijenjanja podataka o klijentu.`,												
    "Clients.Edit.Message.successCreate": `Klijent uspješno dodan.`,												
    "Clients.Edit.Message.errorCreate": `Greška prilikom spremanja klijenta`,												
    "Clients.Delete.pageTitle": `Izbriši klijenta`,												
    "Clients.Delete.questionYesNo": `Da li zaista želite izbrisati klijenta {{clientName}}?`,												
    "Clients.Delete.Message.successDelete": `Uspješno ste izbrisali klijenta {{clientName}}.`,												
    "Clients.Delete.Message.cancelDelete": `Odustali ste od brisanja klijenta.`,												
    "Clients.Delete.Message.errorDelete": `Klijent nije uspješno izbrisan.`,												
    "Clients.AgreedPrices.Header.pageTitle": `Dogovoreni uvjeti`,												
    "Clients.AgreedPrices.Table.emptyMessage": `<b>Nemate još niti jednu dogovorenu cijenu za ovog klijenta.</b> <br /><br />Dogovorenu cijenu možete dodati pritiskom na botun 'Odaberi iz cjenika'.`,												
    "Clients.AgreedPrices.Header.selectFromPricelist": `Odaberi iz cjenika`,												
    "Clients.AgreedPrices.Header.noProductsMessage": `Ne možete odabrati proizvod iz cjenika, jer u cjeniku nemate niti jedan proizvod.`,												
    "Clients.AgreedPrices.Side.conditionsTitle": `Opći uvjeti`,												
    "Clients.AgreedPrices.Side.paymentDelay": `Broj dana odgode plaćanja`,												
    "Clients.AgreedPrices.Side.generalDiscount": `Opći popust`,												
    "Clients.AgreedPrices.Side.deleteAllPrices": `Izbriši sve dogovorene cijene`,												
    "Clients.AgreedPrices.Table.Header.title": `Uvjeti za {{clientName}}`,												
    "Clients.AgreedPrices.Table.Header.perPage": `po stranici`,												
    "Clients.AgreedPrices.Table.Header.name": `Naziv proizvoda/grupe`,												
    "Clients.AgreedPrices.Table.Header.discount": `Popust`,												
    "Clients.AgreedPrices.Table.Header.price": `Cijena`,												
    "Clients.AgreedPrices.Table.ItemMenu.edit": `Uredi`,												
    "Clients.AgreedPrices.Table.ItemMenu.delete": `Izbriši`,												
    "Clients.AgreedPrices.Select.pageTitle": `Odaberi`,												
    "Clients.AgreedPrices.Select.title": `Naziv`,												
    "Clients.AgreedPrices.Select.price": `Cijena`,												
    "Clients.AgreedPrices.Select.select": `Odaberi`,												
    "Clients.AgreedPrices.Edit.pageTitle": `Uvjeti za proizvod/grupu`,												
    "Clients.AgreedPrices.Edit.product": `Proizvod`,												
    "Clients.AgreedPrices.Edit.group": `Grupa`,												
    "Clients.AgreedPrices.Edit.discount": `Popust`,												
    "Clients.AgreedPrices.Edit.discountPlaceHolder": `Unesite dogovoreni popust`,												
    "Clients.AgreedPrices.Edit.agreedSellPrice": `Dogovorena prodajna cijena`,												
    "Clients.AgreedPrices.Edit.agreedSellPricePlaceholder": `Unesite dogovorenu cijenu`,												
    "Clients.AgreedPrices.Edit.priceFromPriceList": `Prodajna cijena iz cjenika je {{priceFromPriceList}}`,												
    "Clients.AgreedPrices.Edit.Product.Message.successSave": `Uvjeti za proizvod {{productName}} su uspješno spremljeni.`,												
    "Clients.AgreedPrices.Edit.Product.Message.errorSave": `Uvjeti za proizvod {{productName}} nisu uspješno spremljeni.`,												
    "Clients.AgreedPrices.Edit.Group.Message.successSave": `Dogovoreni uvjeti za grupu {{groupName}} su uspješno spremljeni.`,												
    "Clients.AgreedPrices.Edit.Group.Message.errorSave": `Uvjeti za grupu {{groupName}} nisu uspješno spremljeni.`,												
    "Clients.AgreedPrices.Delete.pageTitle": `Brisanje dogovorene cijene`,												
    "Clients.AgreedPrices.Delete.Product.questionYesNo": `Da li zaista želite izbrisati dogovorenu cijenu za proizvod '{{productName}}'?`,												
    "Clients.AgreedPrices.Delete.Product.successMessage": `Uvjeti za proizvod {{productName}} uspješno izbrisani.`,												
    "Clients.AgreedPrices.Delete.Product.errorMessage": `Uvjeti za proizvod {{productName}} nisu uspješno izbrisani.`,												
    "Clients.AgreedPrices.Delete.Group.questionYesNo": `Da li zaista želite izbrisati dogovorene uvjete za grupu '{{groupName}}' i sve što je u njoj?`,												
    "Clients.AgreedPrices.Delete.Group.successMessage": `Dogovoreni uvjeti za grupu {{groupName}} i sve u njoj su uspješno izbrisani.`,												
    "Clients.AgreedPrices.Delete.Group.errorMessage": `Dogovoreni uvjeti za grupu {{groupName}} nisu uspješno izbrisani.`,												
    "Clients.AgreedPrices.AddProductOrGroup.pageTitle": `Odaberi iz cjenika`,												
    "Clients.AgreedPrices.AddProductOrGroup.search": `Pretraživanje`,												
    "Clients.AgreedPrices.AddProductOrGroup.pagePlaceholder": `Dio naziva proizvoda/grupe`,												
    "Clients.AgreedPrices.AddProductOrGroup.discount": `Popust`,												
    "Clients.AgreedPrices.AddProductOrGroup.discountPlaceholder": `Dogovoreni popust`,												
    "Clients.AgreedPrices.AddProductOrGroup.agreedSellPrice": `Dogovorena prodajna cijena`,												
    "Clients.AgreedPrices.AddProductOrGroup.agreedSellPricePlaceholder": `Unesite dogovorenu cijenu`,												
    "Clients.AgreedPrices.AddProductOrGroup.priceValidation": `{{discount}} ili {{agreedSellPrice}} je nužno unijeti. `,												
    "Clients.AgreedPrices.AddProductOrGroup.Message.successAdd": `Uspješno su dodani novi uvjeti.`,												
    "Clients.AgreedPrices.AddProductOrGroup.Message.errorAdd": `Novi uvjeti nisu uspješno dodani.`,												
    "Clients.AgreedPrices.Side.GeneralTerms.Message.successSave": `Dogovoreni uvjeti su uspješno spremljeni.`,												
    "Clients.AgreedPrices.Side.GeneralTerms.Message.errorSave": `Dogovoreni uvjeti nisu uspješno spremljeni.`,												
    "Clients.AgreedPrices.Side.Delete.pageTitle": `Brisanje dogovorenih uvjeta`,												
    "Clients.AgreedPrices.Side.Delete.questionYesNo": `Da li zaista želite izbrisati sve dogovorene uvjete za ovog klijenta?`,												
    "Clients.AgreedPrices.Side.Delete.Message.successDelete": `Uspješno ste izbrisali sve dogovorene uvjete.`,												
    "Clients.AgreedPrices.Side.Delete.Message.errorDelete": `Dogovoreni uvjeti nisu uspješno izbrisani.`,												
    "Dashboard.pageTitle": `Glavna ploča`,												
    "Dashboard.amount": `Iznos`,												
    "Dashboard.client": `Klijent`,												
    "Dashboard.date": `Datum`,												
    "Dashboard.invoiceNumber": `Broj računa`,												
    "Dashboard.offerNumber": `Broj ponude`,												
    "Dashboard.offerNumber:lawyer": `Broj troškovnika`,												
    "Dashboard.lastFiveInvoices": `Zadnjih 5 računa`,												
    "Dashboard.lastFiveOffers": `Zadnjih 5 ponuda`,												
    "Dashboard.lastFiveOffers:lawyer": `Zadnjih 5 troškovnika`,												
    "Dashboard.newInvoiceButton": `Novi račun`,												
    "Dashboard.newOfferButton": `Nova ponuda`,												
    "Dashboard.newOfferButton:lawyer": `Novi troškovnik`,												
    "Dashboard.searchButton": `Traži`,												
    "Dashboard.searchPlaceholder": `Unesi tekst pretrage`,												
    "Dashboard.viewAllButton": `Vidi sve`,												
    "Dashboard.Chart.TopProducts.title": `Najprodavanijih 5 proizvoda`,												
    "Dashboard.Chart.TopProducts.chartTitle": `Proizvodi po broju`,												
    "Dashboard.Chart.TopProductsPerIncome.title": `Najuspješnijih 5 proizvoda`,												
    "Dashboard.Chart.TopProductsPerIncome.chartTitle": `Proizvodi po prihodu`,												
    "Dashboard.Chart.TopOfferedProducts.title": `Najtraženijih 5 proizvoda`,												
    "Dashboard.Chart.TopOfferedProducts.chartTitle": `Proizvodi po traganju`,												
    "Dashboard.Chart.IncomePerMonth.title": `Prodaja po mjesecima`,												
    "Dashboard.Chart.SalesPerMonth.title": `Prihod po mjesecima`,												
    "Dashboard.Chart.Month": `Mjesec`,												
    "Dashboard.Chart.Income": `Prihod`,												
    "Dashboard.Chart.Month.january": `Siječanj`,												
    "Dashboard.Chart.Month.february": `Veljača`,												
    "Dashboard.Chart.Month.march": `Ožujak`,												
    "Dashboard.Chart.Month.april": `Travanj`,												
    "Dashboard.Chart.Month.may": `Svibanj`,												
    "Dashboard.Chart.Month.june": `Lipanj`,												
    "Dashboard.Chart.Month.july": `Srpanj`,												
    "Dashboard.Chart.Month.august": `Kolovoz`,												
    "Dashboard.Chart.Month.september": `Rujan`,												
    "Dashboard.Chart.Month.october": `Listopad`,												
    "Dashboard.Chart.Month.november": `Studeni`,												
    "Dashboard.Chart.Month.december": `Prosinac`,												
    "Footer.copyright": `© 2010 - {{currentYear}} Izračunko`,												
    "Footer.privacyPolicy": `<a href=\"https://www.izracunko.com/politika-privatnosti/\">Politika privatnosti</a>`,												
    "Footer.help": `<a href=\"https://izracunko.com/help\">Pomoć</a>`,												
    "Footer.termsOfService": `<a href=\"https://www.izracunko.com/uvjeti-koristenja/\">Uvjeti korištenja</a>`,												
    "ForgotPassword.pageTitle": `Zaboravili ste lozinku?`,												
    "ForgotPassword.popupErrorWrongMail": `Korisnik s tom email adresom nije pronađen.`,												
    "ForgotPassword.resetPasswordButton": `Pošalji upute`,												
    "ForgotPassword.Message.InstructionsSent": `Ukoliko ste unijeli mail adresu koju imamo u sustavu, upute za promjenu lozinke poslane su na email adresu {{email}}. Lozinku možete promijeniti sljedećih sat vremena.`,												
    "ForgotPassword.showSuccessMessage": `Uspješno ste poslali zahtjev`,												
    "ForgotPassword.showErrorMessage": `Greška prilikom slanja emaila. Neispravan email.`,												
    "Header.settings": `Postavke`,												
    "Header.search": `Pretraživanje`,												
    "Header.clients": `Klijenti`,												
    "Header.invoices": `Računi`,												
    "Header.offers": `Ponude`,												
    "Header.offers:lawyer": `Troškovnici`,												
    "Header.priceList": `Cjenik`,												
    "Header.reports": `Izvještaji`,												
    "Header.signOut": `Odjavi se`,												
    "Header.templates": `Predlošci`,												
    "Invoice.Header.pageTitleNewInvoice": `Novi račun`,												
    "Invoice.Header.pageTitleEditInvoice": `Uredi račun`,												
    "Invoice.Header.pageTitleNewOffer": `Nova ponuda`,												
    "Invoice.Header.pageTitleNewOffer:lawyer": `Novi troškovnik`,												
    "Invoice.Header.pageTitleEditOffer": `Uredi ponudu`,												
    "Invoice.Header.pageTitleEditOffer:lawyer": `Uredi troškovnik`,												
    "Invoice.Header.pageTitleNewTemplate": `Novi predložak`,												
    "Invoice.Header.pageTitleEditTemplate": `Uredi predložak`,												
    "Invoice.Header.CancelledInvoice.warningMessage": `Ovaj račun je storniran i ne može se mijenjati niti brisati.`,												
    "Invoice.Header.FiscalizedInvoice.warningMessage": `Ovaj račun je fiskaliziran i ne može se mijenjati niti brisati.`,												
    "Invoice.Header.VATIsNowOff.warningMessage": `U trenutku kad je ovaj dokument (ili dokument iz kojeg je napravljen) rađen, bili ste u sustavu PDV-a te zato sve stavke i dalje imaju PDV.`,												
    "Invoice.Header.VATIsNowOn.warningMessage": `U trenutku kad je ovaj dokument (ili dokument iz kojeg je napravljen) rađen, niste bili u sustavu PDV-a te zato nemate mogućnost unositi PDV za njegove stavke.`,												
    "Invoice.Header.TotalPriceIsNowOn.warningMessage": `U trenutku kad je ovaj dokument (ili dokument iz kojeg je napravljen) rađen, bilo je uključeno unošenje Sveukupne cijene te zato sve stavke i dalje rade na taj način.`,												
    "Invoice.Header.TotalPriceIsNowOff.warningMessage": `U trenutku kad je ovaj dokument (ili dokument iz kojeg je napravljen) rađen, bilo je isključeno unošenje Sveukupne cijene te zato sve stavke i dalje rade na taj način.`,												
    "Invoice.Header.pageHeaderChange": `Dodaj ili zamijeni zaglavlje ponuda i računa`,												
    "Invoice.Header.pageHeaderChange:lawyer": `Dodaj ili zamijeni zaglavlje troškovnika i računa`,												
    "Invoice.Header.pageFooterChange": `Dodaj ili zamijeni podnožje ponuda i računa`,												
    "Invoice.Header.pageFooterChange:lawyer": `Dodaj ili zamijeni podnožje troškovnika i računa`,												
    "Invoice.Header.paymentCodeActivation": `Uključi ili isključi ispis koda za plaćanje`,												
    "Invoice.Header.Menu.print": `Ispiši`,												
    "Invoice.Header.Menu.sendMail": `Pošalji e-mailom`,												
    "Invoice.Header.Menu.sendEInvoice": `Pošalji eRačun`,												
    "Invoice.Header.Menu.changePrices": `Promjena svih cijena`,												
    "Invoice.Header.Menu.makeTemplate": `Napravi predložak`,												
    "Invoice.Header.Menu.delete": `Izbriši`,												
    "Invoice.Header.Menu.cancelInvoice": `Storniraj račun`,												
    "Invoice.Header.Menu.invoiceSettings": `Postavke računa`,												
    "Invoice.Header.Menu.log": `Povijest dokumenta`,												
    "Invoice.Header.Menu.offerSettings": `Postavke ponude`,												
    "Invoice.Header.Menu.offerSettings:lawyer": `Postavke troškovnika`,												
    "Invoice.Header.Menu.templateSettings": `Postavke predloška`,												
    "Invoice.Header.Menu.createOffer": `Napravi ponudu`,												
    "Invoice.Header.Menu.createOffer:lawyer": `Napravi troškovnik`,												
    "Invoice.Header.Menu.createInvoice": `Napravi račun`,												
    "Invoice.Header.Menu.Message.warningNoItems": `Prethodno trebate dodati barem jednu stavku.`,												
    "Invoice.Header.Menu.Message.requiresSaveForNewDocuments": `Prethodno trebate snimiti dokument.`,												
    "Invoice.Side.productsTitle": `Proizvodi`,												
    "Invoice.Side.productsDescription": `Lista najprodavanijih proizvoda. Možete ih dodati klikom na `,												
    "Invoice.Side.actionsTitle": `Brze akcije`,												
    "Invoice.Side.actionsDescription": `Najčešće radnje. Ostale dobijate klikom na`,												
    "Invoice.Side.print": `Ispiši`,												
    "Invoice.Side.sendMail": `Pošalji e-mailom`,												
    "Invoice.Side.changePrices": `Promjena svih cijena`,												
    "Invoice.Side.makeTemplate": `Napravi predložak`,												
    "Invoice.Side.delete": `Izbriši`,												
    "Invoice.Side.cancelInvoice": `Storniraj račun`,												
    "Invoice.Side.changeStatus": `Promijeni status`,												
    "Invoice.Side.createOffer": `Napravi ponudu`,												
    "Invoice.Side.createOffer:lawyer": `Napravi troškovnik`,												
    "Invoice.Side.createInvoice": `Napravi račun`,												
    "Invoice.Table.headerNone": `Dodajte ovdje vaše zaglavlje`,												
    "Invoice.Table.footerNone": `Dodajte ovdje vaše podnožje`,												
    "Invoice.Table.Client.title": `Klijent`,												
    "Invoice.Table.Client.buttonNew": `Novi klijent`,												
    "Invoice.Table.Client.noClientPlaceholder": `Pronađi po dijelu imena klijenta`,												
    "Invoice.Table.Client.createClient": `Napravi klijenta`,												
    "Invoice.Table.Client.Menu.edit": `Uredi klijenta`,												
    "Invoice.Table.Client.Menu.select": `Odaberi klijenta`,												
    "Invoice.Table.Client.Menu.new": `Novi klijent`,												
    "Invoice.Table.Client.Menu.remove": `Ukloni klijenta`,												
    "Invoice.Table.Date.title": `Datum i vrijeme`,												
    "Invoice.Table.DateDue.title": `Datum dospijeća`,												
    "Invoice.Table.Payment.title": `Način plaćanja`,												
    "Invoice.Table.Currency.title": `Valuta klijenta`,												
    "Invoice.Table.Operator.title": `Operater`,												
    "Invoice.Table.DocumentLanguage.title": `Jezik dokumenta`,												
    "Invoice.Table.OfferNumber.infoTitle": `Brojevi ponuda`,												
    "Invoice.Table.OfferNumber.infoTitle:lawyer": `Brojevi troškovnika`,												
    "Invoice.Table.OfferNumber.infoText": `Brojevi ponuda automatski se kreiraju počevši od broja 00001. Izgled broja ponude možete izmijeniti po volji, ali samo skroz lijevi dio će se automatski kreirati. Na primjer ako stavite da vaš broj ponude bude 23-01-2023, sljedeći broj ponude će biti 24-01-2023.`,												
    "Invoice.Table.OfferNumber.infoText:lawyer": `Brojevi troškovnika automatski se kreiraju počevši od broja 00001. Izgled broja troškovnika možete izmijeniti po volji, ali samo skroz lijevi dio će se automatski kreirati. Na primjer ako stavite da vaš broj troškovnika bude 23-01-2023, sljedeći broj troškovnika će biti 24-01-2023.`,												
    "Invoice.Table.InvoiceNumber.infoTitle": `Brojevi računa`,												
    "Invoice.Table.InvoiceNumber.infoText": `Brojevi računa automatski se kreiraju počevši od broja 00001. Izgled broja računa možete izmijeniti po volji, ali samo skroz lijevi dio će se automatski kreirati. Na primjer ako stavite da vaš broj računa bude 23-01-2023, sljedeći broj računa će biti 24-01-2023.`,												
    "Invoice.Table.InvoiceNumber.offerNumberPlaceholder": `Unesite broj ponude`,												
    "Invoice.Table.InvoiceNumber.offerNumberPlaceholder:lawyer": `Unesite broj troškovnika`,												
    "Invoice.Table.InvoiceNumber.invoiceNumberPlaceholder": `Unesite broj računa`,												
    "Invoice.Table.InvoiceNumber.duplicateSuffix": `-(! isti broj)`,												
    "Invoice.Table.InvoiceNumber.DuplicateSuffix.warningTitle": `Isti broj računa`,												
    "Invoice.Table.InvoiceNumber.DuplicateSuffix.warningText": `Račun s ovim brojem već imate iz razloga što vam računi prije zadnjeg imaju veći broj (to može biti iz razloga što ste nekom računu vrijeme podesili prije zadnjeg računa).<br />Ako radite u Hrvatskoj, tada po Zakonu o fiskalizaciji vaši računi trebaju ići uzlazno po broju i datumu pa ih pokušajte dovesti u pravilan redoslijed (prvo izbrišite ovaj račun pa pronađite račun s ovim brojem), a ako ne možete, javite se za pomoć.<br />Ako ne radite u Hrvatskoj onda provjerite ima li u vašoj zemlji ovakva obaveza, a ako nema, samo pobrišite ovo '-(! isti broj)' iz broja računa.`,												
    "Invoice.Table.TemplateName.title": `Naziv predloška`,												
    "Invoice.Table.TemplateName.noTitlePlaceholder": `Predložak za proizvod ABC`,												
    "Invoice.Table.TemplateItems.title": `Stavke predloška`,												
    "Invoice.Table.Header.rowNumber": `#`,												
    "Invoice.Table.Header.name": `Naziv`,												
    "Invoice.Table.Header.amount": `Količina`,												
    "Invoice.Table.Header.price": `Cijena`,												
    "Invoice.Table.Header.discount": `Popust`,												
    "Invoice.Table.Header.total": `Ukupno`,												
    "Invoice.Table.Item.titlePlaceholder": `Unesite naziv vašeg proizvoda/usluge.`,												
    "Invoice.Table.Item.descriptionPlaceholder": `Ovdje možete unijeti opis vašeg proizvoda/usluge.`,												
    "Invoice.Table.ItemMenu.edit": `Uredi stavku`,												
    "Invoice.Table.ItemMenu.delete": `Izbriši stavku`,												
    "Invoice.Table.buttonNew": `Nova stavka`,												
    "Invoice.Table.buttonNewFromPricelist": `Dodaj stavku iz cjenika`,												
    "Invoice.Table.buttonChangeAllPrices": `Promjena svih cijena`,												
    "Invoice.Table.Totals.totalWithoutVAT": `Ukupno`,												
    "Invoice.Table.Totals.vat": `PDV {{vatPercentage}}`,												
    "Invoice.Table.Totals.taxFree": `Neoporezivo`,												
    "Invoice.Table.Totals.total": `Sveukupno`,												
    "Invoice.Table.Footer.remark": `Napomena`,												
    "Invoice.Table.Footer.remarkOffer": `Napomena na ponudi`,												
    "Invoice.Table.Footer.remarkOffer:lawyer": `Napomena na troškovniku`,												
    "Invoice.Table.Footer.remarkInvoice": `Napomena na računu`,												
    "Invoice.Table.Footer.remarkPlaceholder": `Ovdje možete unijeti napomenu koja će biti vidljiva na računu. Npr. o uvjetima plaćanja, predujmu, … ili neku obaveznu napomenu koju vam zakon nameće.`,												
    "Invoice.Table.Footer.remarkPlaceholderOffer": `Ovdje možete unijeti napomenu koja će biti vidljiva na ponudi. Npr. o uvjetima plaćanja, predujmu, … ili neku obaveznu napomenu koju vam zakon nameće.`,												
    "Invoice.Table.Footer.remarkPlaceholderOffer:lawyer": `Ovdje možete unijeti napomenu koja će biti vidljiva na troškovniku. Npr. o uvjetima plaćanja, predujmu, … ili neku obaveznu napomenu koju vam zakon nameće.`,												
    "Invoice.Table.Footer.remarkText": `Plaćanje predujma 30% prije početka izvođenja radova, ostatak vrijednosti plaća se nakon izvođenja radova. Rok isporuke 10 dana od uplate predujma. Ponuda vrijedi 15 dana.`,												
    "Invoice.Table.Footer.remarkText:lawyer": `Radnje u ovom troškovniku obračunate su prema važećoj odvjetničkoj tarifi.`,												
    "Invoice.Table.Footer.personResponsibleTitle": `Odgovorna osoba`,												
    "Invoice.Table.Footer.personResponsibleSignatureChange": `Dodaj ili zamijeni potpis`,												
    "Invoice.Settings.pageTitleInvoice": `Postavke računa`,												
    "Invoice.Settings.pageTitleOffer": `Postavke ponude`,												
    "Invoice.Settings.pageTitleOffer:lawyer": `Postavke troškovnika`,												
    "Invoice.Settings.pageTitleTemplate": `Postavke predloška`,												
    "Invoice.Settings.currencyTitle": `Tečaj i valuta`,												
    "Invoice.Settings.invoiceCurrency": `Valuta računa`,												
    "Invoice.Settings.offerCurrency": `Valuta ponude`,												
    "Invoice.Settings.offerCurrency:lawyer": `Valuta troškovnika`,												
    "Invoice.Settings.templateCurrency": `Valuta predloška`,												
    "Invoice.Settings.recalculateItems": `Preračunaj sve stavke u novu valutu?`,												
    "Invoice.Settings.clientCurrency": `Valuta klijenta`,												
    "Invoice.Settings.fixedHrkEurRate": `1 EUR = 7,53450 kn (fiksni tečaj konverzije)`,												
    "Invoice.Settings.clientCurrencyUnavailable": `Valutu klijenta moći ćete promijeniti nakon što završite s mijenjanjem osnovne valute.`,												
    "Invoice.Settings.updatingDocument": `Preuzimanje vaših aktualnih podataka`,												
    "Invoice.Settings.UpdatingDocument.infoTitle": `Zašto dokument nema aktualne podatke?`,												
    "Invoice.Settings.UpdatingDocument.infoText": `Svaki dokument se sprema s onakvim podacima kakvi su bili u trenutku njegove izrade. To je potrebno iz razloga što ako se npr. vaša adresa promijeni, sve do trenutka promjene svi računi trebaju imati adresu koja je tada bila.<br />Na isti način funkcioniraju i promjene vezane uz izgled dokumenata koje možete napraviti u postavkama ispisa.<br />Međutim, nekada promjene želite odmah vidjeti u dokumentima npr. kada ste izradili promijenili adresu, napravili novi račun i onda na njemu uočili da je stara adresa. U takvim situacijama, nakon što u postavkama promijenite podatke, pritisak na donji botun će te promjene unijeti i u dokument. Ako ste dokument već poslali klijentu sa starim podacima, trebali bi ga poslati i nakon što ga izmijenite.<br /><br />Fiskalizirane ili stornirane račune nije moguće mijenjati.`,												
    "Invoice.Settings.updateDocumentActive": `Preuzmi aktualne podatke (kontakt, postavke ispisa)`,												
    "Invoice.Settings.updateDocumentNotActive": `Dokument ima aktualne postavke`,												
    "Invoice.Settings.updateDocumentNotActiveDisallowed": `Promjene dokumenta nisu dozvoljene`,												
    "Invoice.Settings.languageTitle": `Jezik`,												
    "Invoice.Settings.documentLanguage": `Jezik dokumenta`,												
    "Invoice.Settings.Message.successUpdate": `{{pageTitle}} spremljene.`,												
    "Invoice.Settings.Message.successUpdateWithData": `{{pageTitle}} spremljene i dokument ima aktualne podatke iz postavki.`,												
    "Invoice.Settings.Message.errorUpdate": `Greška prilikom spremanja postavki.`,												
    "Invoice.Edit.pageTitle": `Uredi stavku`,												
    "Invoice.Edit.itemName": `Naziv stavke`,												
    "Invoice.Edit.itemNamePlaceholder": `Unesite naziv`,												
    "Invoice.Edit.itemCode": `Šifra`,												
    "Invoice.Edit.itemCodePlaceholder": `Šifra proizvoda/usluge`,												
    "Invoice.Edit.totalPrice": `Sveukupna cijena`,												
    "Invoice.Edit.totalPricePlaceholder": `Cijena koju plaća klijent (sve je uključeno)`,												
    "Invoice.Edit.rememberTotalPrice": `Zapamti trenutnu sveukupnu cijenu i pomoću nje računaj popust`,												
    "Invoice.Edit.basicPrice": `Osnovna cijena`,												
    "Invoice.Edit.basicPricePlaceholder": `Unesite osnovnu cijenu`,												
    "Invoice.Edit.salesPrice": `Prodajna cijena`,												
    "Invoice.Edit.quantity": `Količina`,												
    "Invoice.Edit.pricePerUnit": `Jedinična cijena:`,												
    "Invoice.Edit.additionalInformation": `Dodatne informacije`,												
    "Invoice.Edit.price": `Cijena`,												
    "Invoice.Edit.measurementUnit": `Jedinica mjere`,												
    "Invoice.Edit.width": `Širina`,												
    "Invoice.Edit.widthPlaceholder": `Širina proizvoda`,												
    "Invoice.Edit.height": `Visina`,												
    "Invoice.Edit.heightPlaceholder": `Visina proizvoda`,												
    "Invoice.Edit.depth": `Dubina`,												
    "Invoice.Edit.depthPlaceholder": `Dubina proizvoda`,												
    "Invoice.Edit.description": `Opis`,												
    "Invoice.Edit.descriptionPlaceholder": `Unesite opis`,												
    "Invoice.Edit.discount": `Popust {{discountPercentage}}`,												
    "Invoice.Edit.discountPrice": `Cijena s popustom:`,												
    "Invoice.Edit.priceWithoutDiscount": `Cijena bez popusta`,												
    "Invoice.Edit.vat": `PDV {{vatPercentage}}`,												
    "Invoice.Edit.priceWithVAT": `Cijena s PDV-om:`,												
    "Invoice.Edit.itemTaxFree": `Ovaj proizvod/usluga je oslobođen plaćanja poreza.`,												
    "Invoice.Edit.purchasePrice": `Nabavna cijena`,												
    "Invoice.Edit.priceDifference": `Razlika u cijeni`,												
    "Invoice.Edit.income": `Zarada:`,												
    "Invoice.Edit.totalWithoutVAT": `Ukupno:`,												
    "Invoice.Edit.totalVAT": `PDV {{vatPercentage}}:`,												
    "Invoice.Edit.totalWithVAT": `Sveukupno:`,												
    "Invoice.Edit.picture": `Slika proizvoda`,												
    "Invoice.Edit.addPicture": `Dodaj sliku`,												
    "Invoice.Edit.removePicture": `Makni sliku`,												
    "Invoice.Edit.buttonSave": `Prihvati`,												
    "Invoice.Edit.buttonCancel": `Odustani`,												
    "Invoice.Edit.Message.SuccessUpdate": `Stavka spremljena.`,												
    "Invoice.Edit.Message.ErrorUpdate": `Greška prilikom mijenjanja podataka o stavci.`,												
    "Invoice.Edit.Message.SuccessCreate": `Stavka uspješno dodana.`,												
    "Invoice.Edit.Message.ErrorCreate": `Greška prilikom spremanja stavke`,												
    "Invoice.Delete.pageTitle": `Izbriši stavku`,												
    "Invoice.Delete.questionYesNo": `Da li zaista želite izbrisati stavku {{itemName}}?`,												
    "Invoice.Delete.Message.SuccessDelete": `Uspješno ste izbrisali stavku {{itemName}}.`,												
    "Invoice.Delete.Message.CancelDelete": `Odustali ste od brisanja stavke.`,												
    "Invoice.Delete.Message.ErrorDelete": `Stavka nije uspješno izbrisana.`,												
    "Invoice.Save.Message.successUpdate": `Račun uspješno spremljen.`,												
    "Invoice.Save.Message.errorUpdate": `Greška prilikom spremanja podataka o računu.`,												
    "Invoice.Save.Message.successCreate": `Račun uspješno dodan.`,												
    "Invoice.Save.Message.errorCreate": `Greška prilikom spremanja računa.`,												
    "Invoice.SaveOffer.Message.successUpdate": `Ponuda uspješno spremljena.`,												
    "Invoice.SaveOffer.Message.successUpdate:lawyer": `Troškovnik uspješno spremljen.`,												
    "Invoice.SaveOffer.Message.errorUpdate": `Greška prilikom spremanja podataka o ponudi.`,												
    "Invoice.SaveOffer.Message.errorUpdate:lawyer": `Greška prilikom spremanja podataka o troškovniku.`,												
    "Invoice.SaveOffer.Message.successCreate": `Ponuda uspješno dodana.`,												
    "Invoice.SaveOffer.Message.successCreate:lawyer": `Troškovnik uspješno dodan.`,												
    "Invoice.SaveOffer.Message.errorCreate": `Greška prilikom spremanja ponude.`,												
    "Invoice.SaveOffer.Message.errorCreate:lawyer": `Greška prilikom spremanja troškovnika.`,												
    "Invoice.SaveTemplate.Message.successUpdate": `Predložak uspješno spremljen.`,												
    "Invoice.SaveTemplate.Message.errorUpdate": `Greška prilikom spremanja podataka o predlošku.`,												
    "Invoice.SaveTemplate.Message.successCreate": `Predložak uspješno dodan.`,												
    "Invoice.SaveTemplate.Message.errorCreate": `Greška prilikom spremanja predloška.`,												
    "Invoice.Cancel.Message.success": `Promjene podataka o računu nisu spremljene.`,												
    "Invoice.AddProduct.pageTitle": `Dodaj stavku iz cjenika`,												
    "Invoice.AddProduct.messageProductExists": `Ovaj proizvod/usluga već postoji u računu i ne možete ga/je ponovno dodati. Ako želite dodati veću količinu tog proizvoda/usluge, promijenite količinu u postojećoj stavci.`,												
    "Invoice.AddProduct.messageProductExistsOffer": `Ovaj proizvod/usluga već postoji u ponudi i ne možete ga/je ponovno dodati. Ako želite dodati veću količinu tog proizvoda/usluge, promijenite količinu u postojećoj stavci.`,												
    "Invoice.AddProduct.messageProductExistsOffer:lawyer": `Ova usluga već postoji u troškovniku i ne možete je ponovno dodati. Ako želite dodati veću količinu te usluge, promijenite količinu u postojećoj stavci.`,												
    "Invoice.AddProduct.Message.AgreedPrice.infoTitle": `Dogovorena cijena`,												
    "Invoice.AddProduct.Message.AgreedPrice.infoText": `Iznos je različit od cijene iz cjenika, jer ste tako dogovorili za ovog klijenta.`,												
    "Invoice.CancelInvoice.pageTitle": `Storniraj račun`,												
    "Invoice.CancelInvoice.invoiceToBeCancelled": `Račun koji se stornira`,												
    "Invoice.CancelInvoice.questionYesNo": `Da li zaista želite stornirati račun {{invoiceNumber}}?`,												
    "Invoice.CancelInvoice.questionInfoTitle": `Storniranje računa`,												
    "Invoice.CancelInvoice.questionInfo": `Kada račun stornirate, više ga nećete moći mijenjati. Ujedno, napraviti će se novi račun, koji će imati sve isto kao i ovaj račun, samo što će biti negativan. Na taj način ova dva računa se poništavaju i završeno je storniranje računa.`,												
    "Invoice.Cancelnvoice.Message.SuccessDelete": `Uspješno ste stornirali račun.`,												
    "Invoice.CancelInvoice.Message.CancelDelete": `Odustali ste od storniranja računa.`,												
    "Invoice.ChangeAllPrices.pageTitle": `Promjena svih cijena`,												
    "Invoice.ChangeAllPrices.new": `Novo stanje`,												
    "Invoice.ChangeAllPrices.current": `Trenutno stanje`,												
    "Invoice.ChangeAllPrices.profit": `Zarada:`,												
    "Invoice.ChangeAllPrices.total": `Ukupno:`,												
    "Invoice.ChangeAllPrices.enableVATChange": `Omogući promjenu PDV-a`,												
    "Invoice.ChangeAllPrices.changeVAT": `Promijeni PDV svih stavki (0-30%)`,												
    "Invoice.ChangeStatus.pageTitle": `Promjena statusa`,												
    "Invoice.ChangeStatus.status": `Status`,												
    "Invoice.DeleteInvoice.pageTitle": `Izbriši račun`,												
    "Invoice.DeleteInvoice.questionYesNo": `Da li zaista želite izbrisati račun {{invoiceNumber}}?`,												
    "Invoice.DeleteInvoice.Message.SuccessDelete": `Uspješno ste izbrisali račun.`,												
    "Invoice.DeleteInvoice.Message.CancelDelete": `Odustali ste od brisanja računa.`,												
    "Invoice.DeleteInvoice.Message.ErrorDelete": `Račun nije uspješno izbrisan.`,												
    "Invoice.DeleteOffer.pageTitle": `Izbriši ponudu`,												
    "Invoice.DeleteOffer.pageTitle:lawyer": `Izbriši troškovnik`,												
    "Invoice.DeleteOffer.questionYesNo": `Da li zaista želite izbrisati ponudu {{offerNumber}}?`,												
    "Invoice.DeleteOffer.questionYesNo:lawyer": `Da li zaista želite izbrisati troškovnik {{offerNumber}}?`,												
    "Invoice.DeleteOffer.Message.successDelete": `Uspješno ste izbrisali ponudu.`,												
    "Invoice.DeleteOffer.Message.successDelete:lawyer": `Uspješno ste izbrisali troškovnik.`,												
    "Invoice.DeleteOffer.Message.cancelDelete": `Odustali ste od brisanja ponude.`,												
    "Invoice.DeleteOffer.Message.cancelDelete:lawyer": `Odustali ste od brisanja troškovnika.`,												
    "Invoice.DeleteOffer.Message.errorDelete": `Ponuda nije uspješno izbrisana.`,												
    "Invoice.DeleteOffer.Message.errorDelete:lawyer": `Troškovnik nije uspješno izbrisan.`,												
    "Invoice.DeleteTemplate.pageTitle": `Izbriši predložak`,												
    "Invoice.DeleteTemplate.questionYesNo": `Da li zaista želite izbrisati predložak {{templateName}}?`,												
    "Invoice.DeleteTemplate.Message.successDelete": `Uspješno ste izbrisali predložak.`,												
    "Invoice.DeleteTemplate.Message.cancelDelete": `Odustali ste od brisanja predloška.`,												
    "Invoice.DeleteTemplate.Message.errorDelete": `Predložak nije uspješno izbrisan.`,												
    "Invoice.Log.pageTitle": `Povijest dokumenta`,												
    "Invoice.Log.documentCreated": `Napravljen`,												
    "Invoice.Log.documentSent": `Poslan na {{mailAddress}}`,												
    "Invoice.Log.documentSentAsEInvoice": `Poslan kao eRačun na {{mailAddress}}`,												
    "Invoice.Log.StatusChanged": `Status promijenjen u '{{status}}'`,												
    "Invoice.Log.invoiceCreated": `Iz ponude {{offerNumber}} napravljen račun`,												
    "Invoice.Log.fiscalized": `Fiskaliziran`,												
    "Invoice.Log.System": `Sistem`,												
    "Invoice.Print.invoice": `Račun`,												
    "Invoice.Print.offer": `Ponuda`,												
    "Invoice.Print.offer:lawyer": `Troškovnik`,												
    "Invoice.Print.Fiscalisation.pageTitle": `Ispis računa (fiskalizacija)`,												
    "Invoice.Print.Fiscalisation.questionYesNo": `Prije ispisa ili slanja, račun se mora fiskalizirati. Nakon uspješne fiskalizacije, račun više nećete moći mijenjati ni izbrisati. Da li želite fiskalizirati račun?`,												
    "Invoice.Print.Fiscalisation.printDontFiscalize": `Ispiši bez fiskalizacije`,												
    "Invoice.Print.Fiscalisation.messageTooLate": `Vrijeme računa starije je od obaveznog roka za slanje na poreznu upravu (2 dana). Ovaj račun više se ne može fiskalizirati. Kontaktirajte vašeg knjigovođu za savjet.`,												
    "Invoice.Print.Fiscalisation.messageNoRights": `Ovaj račun potrebno je fiskalizirati prije ispisa ili slanja. Vaša korisnička prava ne dopuštaju fiskalizaciju računa. Molimo kontaktirajte vašeg administratora.`,												
    "Invoice.Print.Fiscalisation.messageSuccess": `Račun uspješno fiskaliziran`,												
    "Invoice.Send.pageTitleInvoice": `Slanje računa`,												
    "Invoice.Send.pageTitleOffer": `Slanje ponude`,												
    "Invoice.Send.pageTitleOffer:lawyer": `Slanje troškovnika`,												
    "Invoice.Send.invoiceAttached": `Račun u prilogu`,												
    "Invoice.Send.offerAttached": `Ponuda u prilogu`,												
    "Invoice.Send.offerAttached:lawyer": `Troškovnik u prilogu`,												
    "Invoice.Send.email": `E-mail primatelja`,												
    "Invoice.Send.emailPlaceholder": `primjer@gmail.com`,												
    "Invoice.Send.additionalRecipients": `E-mail dodatnih primatelja (cc)`,												
    "Invoice.Send.additionalRecipientsInfoTitle": `Dodatni primatelji`,												
    "Invoice.Send.additionalRecipientsInfo": `Ukoliko želite poslati mail na više e-mail adresa, trebate ih odvojiti zarezom.`,												
    "Invoice.Send.additionalRecipientsPlaceholder": `primjer@gmail.com, primjer2@gmail.com`,												
    "Invoice.Send.messageTitle": `Račun`,												
    "Invoice.Send.messageTitleOffer": `Ponuda`,												
    "Invoice.Send.messageTitleOffer:lawyer": `Troškovnik`,												
    "Invoice.Send.message": `Tekst e-maila`,												
    "Invoice.Send.messagePlaceholder": `Poštovani,\r\n\r\nu prilogu Vam šaljemo naš račun za obavljene isporuke.\r\n\r\nLijep pozdrav, \r\n\r\n Izračunko`,												
    "Invoice.Send.messagePlaceholderOffer": `Poštovani, \r\n\r\nu prilogu Vam šaljemo našu ponudu. \r\n\r\nLijep pozdrav, \r\n\r\n Izračunko`,												
    "Invoice.Send.messagePlaceholderOffer:lawyer": `Poštovani,\r\n\r\nu prilogu Vam šaljemo naš troškovnik za obavljene radnje.\r\n\r\nLijep pozdrav, \r\n\r\n Izračunko`,												
    "Invoice.Send.sendCopyToYourselfYesNo": `Pošalji kopiju sebi na {{userMail}}`,												
    "Invoice.Send.fiscalizeYesNo": `Fiskaliziraj račun prije slanja`,												
    "Invoice.Send.messageSuccess": `Mail uspješno poslan`,												
    "Invoice.Send.messageFail": `Mail nije uspješno poslan`,												
    "Invoice.SendEInvoice.pageTitleSend": `Slanje eRačuna`,												
    "Invoice.SendEInvoice.pageTitleDownload": `Preuzimanje eRačuna`,												
    "Invoice.SendEInvoice.Message.noCompanyData": `Kako bi mogli poslati eRačun, prethodno morate u postavkama unijeti OIB i kontakt email te punu adresu  (ulica, broj, grad, poštanski broj i država).`,												
    "Invoice.SendEInvoice.Message.noClientData": `Kako bi mogli poslati eRačun, prethodno trebate odabrati klijenta i unijeti njegov email, OIB i punu adresu (ulica, broj, grad, poštanski broj i država).`,												
    "Invoice.SendEInvoice.pageTitleDownload.infoTitle": `Kako poslati eRačun?`,												
    "Invoice.SendEInvoice.pageTitleDownload.infoText": `Ukoliko direktno iz našeg sustava želite slati eRačune, potrebno je da ugovorite ovu uslugu s vanjskim servisom 'Moj eRačun' te da njihove pristupne podatke unesete u postavkama eRačuna.<br />Ukoliko ovo želite napraviti, javite nam se na info@izracunko.com kako bi vam poslali upute.<br />Ukoliko želite slati eRačune putem nekog drugog servisa, niže možete preuzeti eRačun i potom ga poslati putem bilo kojeg drugog servisa.`,												
    "Invoice.SendEInvoice.deliveryDate": `Datum isporuke`,												
    "Invoice.SendEInvoice.invoiceProcess": `Oznaka poslovnog procesa`,												
    "Invoice.SendEInvoice.InvoiceProcess.p1": `P1 - Izdavanje računa za isporuke robe i usluga prema narudžbenici, na temelju ugovora`,												
    "Invoice.SendEInvoice.InvoiceProcess.p2": `P2 - Periodično izdavanje računa za isporuke robe i usluga, na temelju ugovora, bez narudžbenice`,												
    "Invoice.SendEInvoice.InvoiceProcess.p3": `P3 - Izdavanje računa za isporuke prema samostalnoj narudžbi/narudžbenici, bez ugovora`,												
    "Invoice.SendEInvoice.InvoiceProcess.p4": `P4 - Izdavanje računa za predujam (avans)`,												
    "Invoice.SendEInvoice.InvoiceProcess.p5": `P5 - Izdavanje računa za plaćanje na licu mjesta`,												
    "Invoice.SendEInvoice.InvoiceProcess.p6": `P6 - Izdavanje računa za plaćanje prije isporuke, na temelju narudžbenice`,												
    "Invoice.SendEInvoice.InvoiceProcess.p7": `P7 - Izdavanje računa s referencom na otpremnicu, na temelju narudžbenice`,												
    "Invoice.SendEInvoice.InvoiceProcess.p8": `P8 - Izdavanje računa s referencom na otpremnicu i primku, na temelju narudžbenice`,												
    "Invoice.SendEInvoice.InvoiceProcess.p9": `P9 - Izdavanje odobrenja ili računa s negativnim iznosima`,												
    "Invoice.SendEInvoice.InvoiceProcess.p10": `P10 - Izdavanje ispravka računa (korektivno fakturiranje), prema izvornom računu`,												
    "Invoice.SendEInvoice.InvoiceProcess.p11": `P11 - Izdavanje djelomičnog i završnog računa`,												
    "Invoice.SendEInvoice.InvoiceProcess.p12": `P12 - Samoizdavanje računa`,												
    "Invoice.SendEInvoice.purchaseOrderNum": `Broj narudžbenice`,												
    "Invoice.SendEInvoice.globalExemptionReason": `Zajednički razlog oslobođenja od poreza`,												
    "Invoice.SendEInvoice.isTaxExemptionReasonGlobal": `Isti je razlog oslobođenja od poreza za sve stavke`,												
    "Invoice.SendEInvoice.exemptionReasonFor": `{{itemName}} razlog oslobođenja od poreza `,												
    "Invoice.SendEInvoice.itemExemptionReason": `Razlog oslobođenja od poreza stavke`,												
    "Invoice.SendEInvoice.downloadAfterSendYesNo": `Preuzmi eRačun u XML obliku nakon slanja`,												
    "Invoice.SendEInvoice.fiscalizeYesNo": `Fiskaliziraj račun prije slanja/preuzimanja`,												
    "Invoice.CreateTemplate.pageTitle": `Unesi naziv predloška`,												
    "Invoice.CreateTemplate.templateTitle": `Naziv`,												
    "Invoice.CreateTemplate.templateTitlePlaceholder": `Račun/ponuda za proizvod ABC`,												
    "Invoice.CreateTemplate.templateTitlePlaceholder:lawyer": `Račun/troškovnik za uslugu ABC`,												
    "Invoice.CreateTemplate.saveClientYesNo": `Spremi klijenta u predložak`,												
    "Invoice.CreateTemplate.Message.successCreate": `Predložak uspješno stvoren.`,												
    "Invoice.CreateTemplate.Message.errorCreate": `Predložak nije uspješno stvoren.`,												
    "Invoices.Header.pageTitle": `Računi`,												
    "Invoices.Header.newInvoice": `Novi račun`,												
    "Invoices.Header.reports": `Izvještaji`,												
    "Invoices.Side.searchTitle": `Pretraživanje`,												
    "Invoices.Side.clientPlaceholder": `Klijent (upiši dio imena)`,												
    "Invoices.Side.invoiceNumberPlaceholder": `Broj računa (dio broja)`,												
    "Invoices.Side.day": `Dan (svi)`,												
    "Invoices.Side.month": `Mjesec (svi)`,												
    "Invoices.Side.year": `Godina (sve)`,												
    "Invoices.Side.statusAll": `Status (svi)`,												
    "Invoices.Side.paymentAndFiscalization": `Naplata i fiskalizacija (svi)`,												
    "Invoices.Side.paymentOnly": `Naplata (svi)`,												
    "Invoices.Side.fiscalizationNotDone": `Nefiskalizirani`,												
    "Invoices.Side.buttonSearch": `Traži`,												
    "Invoices.Table.Header.title": `Popis računa`,												
    "Invoices.Table.emptyMessage": `Niste napravili još niti jedan račun.<br /><br />Račune najčešće izdajete po obavljenom poslu ili prije odrađenog posla (tada je to plaćanje unaprijed).`,												
    "Invoices.Table.emptySearchMessage": `Račun kakvog ste tražili nije pronađen.<br /><br />Možete ili promijeniti pretragu ili napraviti novi račun.`,												
    "Invoices.Table.Header.perPage": `po stranici`,												
    "Invoices.Table.Header.invoiceNumber": `Račun broj`,												
    "Invoices.Table.Header.date": `Datum`,												
    "Invoices.Table.Header.client": `Klijent`,												
    "Invoices.Table.Header.amount": `Iznos`,												
    "Invoices.Table.ItemMenu.edit": `Uredi`,												
    "Invoices.Table.ItemMenu.delete": `Izbriši`,												
    "Invoices.Table.ItemMenu.print": `Ispiši`,												
    "Invoices.Table.ItemMenu.sendMail": `Pošalji mailom`,												
    "Invoices.Table.ItemMenu.cancel": `Storniraj`,												
    "Invoices.Table.Mobile.created": `Izdao`,												
    "Invoices.Table.Mobile.date": `Datum`,												
    "Invoices.Table.Mobile.client": `Klijent`,												
    "Invoices.Table.Mobile.amount": `Iznos`,												
    "Offers.Header.pageTitle": `Ponude`,												
    "Offers.Header.pageTitle:lawyer": `Troškovnici`,												
    "Offers.Header.newOffer": `Nova ponuda`,												
    "Offers.Header.newOffer:lawyer": `Novi troškovnik`,												
    "Offers.Header.templates": `Predlošci`,												
    "Offers.Side.searchTitle": `Pretraživanje`,												
    "Offers.Side.clientPlaceholder": `Klijent (upiši dio imena)`,												
    "Offers.Side.offerNumberPlaceholder": `Broj ponude (dio broja)`,												
    "Offers.Side.offerNumberPlaceholder:lawyer": `Broj troškovnika (dio broja)`,												
    "Offers.Side.day": `Dan (svi)`,												
    "Offers.Side.month": `Mjesec (svi)`,												
    "Offers.Side.year": `Godina (sve)`,												
    "Offers.Side.buttonSearch": `Traži`,												
    "Offers.Table.Header.title": `Popis ponuda`,												
    "Offers.Table.Header.title:lawyer": `Popis troškovnika`,												
    "Offers.Table.emptyMessage": `Nemate još niti jednu ponudu.<br /><br />Kad je napravite (+ 'Nova ponuda' pri vrhu), izrazito jednostavno je možete poslati klijentu. Kad vam klijent potvrdi ponudu, iz ponude možete izraditi račun te i njega poslati klijentu.`,												
    "Offers.Table.emptyMessage:lawyer": `U troškovnik upisujete radnje kako ih obavljate po pojedinom predmetu.<br /><br />Kad ga napravite (+ 'Novi troškovnik' pri vrhu) i vrijeme je za naplatu, izrazito jednostavno ga možete poslati klijentu na uvid te onda direktno iz troškovnika izraditi račun te i njega poslati klijentu.`,												
    "Offers.Table.emptySearchMessage": `Ponuda kakvu ste tražili nije pronađena.<br /><br />Možete ili promijeniti pretragu ili napraviti novu ponudu.`,												
    "Offers.Table.emptySearchMessage:lawyer": `Troškovnik kakvog ste tražili nije pronađen.<br /><br />Možete ili promijeniti pretragu ili napraviti novi troškovnik.`,												
    "Offers.Table.Header.perPage": `po stranici`,												
    "Offers.Table.Header.tenderNr": `Ponuda broj`,												
    "Offers.Table.Header.tenderNr:lawyer": `Troškovnik broj`,												
    "Offers.Table.Header.date": `Datum`,												
    "Offers.Table.Header.client": `Klijent`,												
    "Offers.Table.Header.amount": `Iznos`,												
    "Offers.Table.ItemMenu.edit": `Uredi`,												
    "Offers.Table.ItemMenu.delete": `Izbriši`,												
    "Offers.Table.ItemMenu.print": `Ispiši`,												
    "Offers.Table.ItemMenu.sendMail": `Pošalji mailom`,												
    "Offers.Table.ItemMenu.cancel": `Storniraj`,												
    "Templates.Header.pageTitle": `Predlošci`,												
    "Templates.Header.newTemplate": `Novi predložak`,												
    "Templates.Side.searchTitle": `Pretraživanje`,												
    "Templates.Side.searchPlaceholder": `Predložak (dio naziva)`,												
    "Templates.Side.buttonSearch": `Traži`,												
    "Templates.Table.Header.title": `Popis predložaka`,												
    "Templates.Table.emptyMessage": `Nemate niti jedan predložak.<br /><br />Predlošci služe da bi se iz njih stvarale istovrsne ponude ili računi. Predložak se stvara ili iz već postojeće ponude ili računa ili odavde pritiskom na 'Novi predložak'.`,												
    "Templates.Table.emptyMessage:lawyer": `Nemate niti jedan predložak.<br /><br />Predlošci služe da bi se iz njih stvarale istovrsne ponude ili računi. Predložak se stvara ili iz već postojećeg troškovnika ili računa ili odavde pritiskom na 'Novi predložak'.`,												
    "Templates.Table.emptySearchMessage": `Predložak kakvog ste tražili nije pronađen.<br /><br />Možete ili promijeniti pretragu ili napraviti novi predložak.`,												
    "Templates.Table.Header.perPage": `po stranici`,												
    "Templates.Table.Header.templateName": `Naziv`,												
    "Templates.Table.Header.creator": `Napravio`,												
    "Templates.Table.ItemMenu.edit": `Uredi`,												
    "Templates.Table.ItemMenu.delete": `Izbriši`,												
    "Templates.Table.ItemMenu.createOffer": `Napravi ponudu`,												
    "Templates.Table.ItemMenu.createOffer:lawyer": `Napravi troškovnik`,												
    "Templates.Table.ItemMenu.createInvoice": `Napravi račun`,												
    "Login.email": `Email`,												
    "Login.forgotPassword": `Zaboravili ste lozinku?`,												
    "Login.loginButton": `Prijavi se`,												
    "Login.pageTitle": `Prijava`,												
    "Login.password": `Lozinka`,												
    "Login.popupErrorWrongUser": `Nismo pronašli korisnika s podacima koje ste unijeli. Provjerite unesene podatke i ponovno se prijavite ili odaberite 'Zaboravili ste lozinku?' kako bi promijenili lozinku.`,												
    "Login.popupErrorCantConnect": `Ne možemo se spojiti na bazu podataka, molim provjerite vašu internet vezu.`,												
    "Login.popupSuccess": `Uspješno ste se ulogirali`,												
    "Popup.errorTitle": `Greška`,												
    "Popup.infoTitle": `Obavijest`,												
    "Popup.deleteTitle": `Želite izbrisati?`,												
    "Popup.no": `Ne`,												
    "Popup.successTitle": `Uspjeh`,												
    "Popup.warningTitle": `Upozorenje`,												
    "Popup.close": `Zatvori`,												
    "Popup.yes": `Da`,												
    "PriceList.Header.pageTitle": `Cjenik`,												
    "PriceList.Header.pageTitle:lawyer": `Tarifa`,												
    "PriceList.Header.pageTitleInfoTitle": `Uvoz cjenika`,												
    "PriceList.Header.pageTitleInfo": `Ako želite cijeli cjenik pripremiti u npr. Excelu pa ga ubaciti u Izračunka, molimo preuzmite ovaj dokument koji je primjer CSV datoteke koju Izračunko može pročitati. Pa po njoj možete pripremiti svoj cjenik.<br /><a href=\"https://www.izracunko.com/wp-content/uploads/Izracunko_import.csv\">Primjer CSV datoteke za uvoz</a>`,												
    "PriceList.Header.addProduct": `Dodaj proizvod`,												
    "PriceList.Header.addProduct:lawyer": `Dodaj uslugu`,												
    "PriceList.Header.addGroup": `Dodaj grupu`,												
    "PriceList.Side.searchTitle": `Pretraživanje`,												
    "PriceList.Side.searchPlaceholder": `Dio naziva ili šifre`,												
    "PriceList.Side.searchButton": `Traži`,												
    "PriceList.Side.importPriceList": `Uvezi cjenik`,												
    "PriceList.Side.exportPriceList": `Izvezi cjenik`,												
    "PriceList.Side.deletePriceList": `Izbriši cjenik`,												
    "PriceList.Table.Header.title": `Popis proizvoda`,												
    "PriceList.Table.emptyMessage": `Nemate još niti jedan proizvod u cjeniku.<br /><br />Jednom unesen proizvod omogućuje vam da ga jednostavno možete odabrati prilikom kreiranja ponude ili računa. Ukoliko imate veći broj sličnih proizvoda možete ih organizirati unutar grupa.`,												
    "PriceList.Table.emptyMessage:lawyer": `Nemate još niti jednu uslugu u cjeniku.<br /><br />Jednom unesena usluga omogućuje vam da je jednostavno možete odabrati prilikom kreiranja troškovnika ili računa. Ukoliko imate veći broj sličnih usluga možete ih organizirati unutar grupa.`,												
    "PriceList.Table.emptyGroupMessage": `Ova grupa nema niti jedan uneseni proizvod niti podgrupu.<br /><br />Možete se vratiti u nadređenu grupu pritiskom na strelicu 'Nazad' da bi odabrali proizvod u drugoj grupi ili možete unijeti novu stavku.`,												
    "PriceList.Table.emptyGroupMessage:lawyer": `Ova grupa nema niti jednu unesenu uslugu niti podgrupu.<br /><br />Možete se vratiti u nadređenu grupu pritiskom na strelicu 'Nazad' da bi odabrali uslugu u drugoj grupi ili možete unijeti novu stavku.`,												
    "PriceList.Table.emptySearchMessage": `Traženi pojam nije pronađen ni među grupama ni među proizvodima.<br /><br />Jeste li sigurni da imate takav proizvod ili grupu?`,												
    "PriceList.Table.emptySearchMessage:lawyer": `Traženi pojam nije pronađen ni među grupama ni među uslugama.<br /><br />Jeste li sigurni da imate takvu uslugu ili grupu?`,												
    "PriceList.Table.Header.perPage": `po stranici`,												
    "PriceList.Table.Header.name": `Naziv [šifra]`,												
    "PriceList.Table.Header.price": `Cijena`,												
    "PriceList.Table.Item.back": `… (povratak)`,												
    "PriceList.Table.ItemMenu.edit": `Uredi`,												
    "PriceList.Table.ItemMenu.delete": `Izbriši`,												
    "PriceList.EditGroup.pageTitleEdit": `Uredi grupu`,												
    "PriceList.EditGroup.pageTitleNew": `Unesi grupu`,												
    "PriceList.EditGroup.name": `Naziv grupe`,												
    "PriceList.EditGroup.namePlaceholder": `Unesi grupu`,												
    "PriceList.EditGroup.picture": `Slika`,												
    "PriceList.EditGroup.selectedPicture": `Odabrana slika`,												
    "PriceList.EditGroup.Message.successUpdate": `Grupa uspješno spremljena.`,												
    "PriceList.EditGroup.Message.errorUpdate": `Greška prilikom mijenjanja podataka o grupi.`,												
    "PriceList.EditGroup.Message.successCreate": `Grupa uspješno dodana.`,												
    "PriceList.EditGroup.Message.errorCreate": `Greška prilikom spremanja grupe.`,												
    "PriceList.EditProduct.pageTitleEdit": `Uredi proizvod`,												
    "PriceList.EditProduct.pageTitleEdit:lawyer": `Uredi uslugu`,												
    "PriceList.EditProduct.pageTitleNew": `Unesi proizvod`,												
    "PriceList.EditProduct.pageTitleNew:lawyer": `Unesi uslugu`,												
    "PriceList.EditProduct.product": `Proizvod`,												
    "PriceList.EditProduct.product:lawyer": `Usluga`,												
    "PriceList.EditProduct.productPlaceholder": `Unesi naziv proizvoda`,												
    "PriceList.EditProduct.productPlaceholder:lawyer": `Unesi naziv usluge`,												
    "PriceList.EditProduct.salesPrice": `Prodajna cijena`,												
    "PriceList.EditProduct.salesPrice:lawyer": `Cijena`,												
    "PriceList.EditProduct.additionalInformation": `Dodatne informacije`,												
    "PriceList.EditProduct.priceDifference": `Razlika u cijeni`,												
    "PriceList.EditProduct.purchasePrice": `Nabavna cijena`,												
    "PriceList.EditProduct.measurementUnit": `Jedinica mjere`,												
    "PriceList.EditProduct.width": `Širina`,												
    "PriceList.EditProduct.widthPlaceholder": `Unesite širinu proizvoda`,												
    "PriceList.EditProduct.height": `Visina`,												
    "PriceList.EditProduct.heightPlaceholder": `Unesite visinu proizvoda`,												
    "PriceList.EditProduct.depth": `Dubina`,												
    "PriceList.EditProduct.depthPlaceholder": `Unesite dubinu proizvoda`,												
    "PriceList.EditProduct.productCode": `Šifra proizvoda`,												
    "PriceList.EditProduct.productCodePlaceholder": `Unesite šifru proizvoda`,												
    "PriceList.EditProduct.VAT": `PDV`,												
    "PriceList.EditProduct.taxFreeCheck": `Ovaj proizvod/usluga je oslobođen plaćanja poreza`,												
    "PriceList.EditProduct.picture": `Slika`,												
    "PriceList.EditProduct.Message.successUpdate": `Proizvod uspješno spremljen.`,												
    "PriceList.EditProduct.Message.successUpdate:lawyer": `Usluga uspješno spremljena.`,												
    "PriceList.EditProduct.Message.errorUpdate": `Greška prilikom mijenjanja podataka o proizvodu.`,												
    "PriceList.EditProduct.Message.errorUpdate:lawyer": `Greška prilikom mijenjanja podataka o usluzi.`,												
    "PriceList.EditProduct.Message.successCreate": `Proizvod uspješno dodan.`,												
    "PriceList.EditProduct.Message.successCreate:lawyer": `Usluga uspješno dodana.`,												
    "PriceList.EditProduct.Message.errorCreate": `Greška prilikom spremanja proizvoda.`,												
    "PriceList.EditProduct.Message.errorCreate:lawyer": `Greška prilikom spremanja usluge.`,												
    "PriceList.DeleteGroup.pageTitle": `Izbriši grupu`,												
    "PriceList.DeleteGroup.questionYesNo": `Da li zaista želite izbrisati grupu {{groupName}} i sve njene podgrupe i proizvode?`,												
    "PriceList.DeleteGroup.successMessage": `Grupa {{groupName}} uspješno izbrisana.`,												
    "PriceList.DeleteGroup.errorMessage": `Grupa {{groupName}} nije uspješno izbrisana.`,												
    "PriceList.DeleteProduct.pageTitle": `Izbriši proizvod`,												
    "PriceList.DeleteProduct.questionYesNo": `Da li zaista želite izbrisati proizvod {{productName}}?`,												
    "PriceList.DeleteProduct.successMessage": `Proizvod {{productName}} uspješno izbrisan.`,												
    "PriceList.DeleteProduct.errorMessage": `Proizvod {{productName}} nije uspješno izbrisan.`,												
    "PriceList.Import.pageTitle": `Uvezi cjenik`,												
    "PriceList.Import.Message.infoTitle": `Upute za uvoz cjenika`,												
    "PriceList.Import.Message.infoText": `Odaberite XML datoteku iz koje ćete uvesti cjenik. Ovdje imate primjer <a class=\"link\" href=\"#\">XML datoteke</a>, a ovdje imate <a class=\"link\" href=\"#\"> Excel 2007 datoteku</a> iz koje smo dobili ovaj primjer odabirom 'Spremi kao -> XML Data'. Nije nužno da unosite sve elemente: kada unosite proizvod koji nije u grupi, dovoljno je da unesete njegov naziv, a kada unosite praznu grupu, dovoljno je da unesete njen naziv unutar GrupaUKojojSeNalaziProizvod.`,												
    "PriceList.Import.Message.warningTitle": `Upozorenje`,												
    "PriceList.Import.Message.warningText": `If you already have same products in the price list, their prices will be overwritten with prices from the XML file.`,												
    "PriceList.Import.Header.title": `Odaberi XML datoteku`,												
    "PriceList.Import.Table.fileTitle": `Datoteka`,												
    "PriceList.Import.Table.fileButton": `Odaberi datoteku`,												
    "PriceList.Import.Table.fileNoneMessage": `Dokument nije odabran`,												
    "PriceList.Import.Table.groupTitle": `Naziv grupe`,												
    "PriceList.Import.Table.groupPlaceholder": `Unesi naziv grupe`,												
    "PriceList.Delete.pageTitle": `Izbriši čitavi cjenik`,												
    "PriceList.Delete.questionYesNo": `Da li zaista želite izbrisati čitavi cjenik? Ova radnja se ne može poništiti!`,												
    "Registration.title": `Registracija`,												
    "Registration.personaldataTitle": `Za početak nam treba malo vaših osobnih podataka:`,												
    "Registration.personaldataName": `Ime`,												
    "Registration.personaldataNamePlaceholder": `Vaše ime`,												
    "Registration.personaldataSurname": `Prezime`,												
    "Registration.personaldataSurnamePlaceholder": `Vaše prezime`,												
    "Registration.personaldataEmail": `Email`,												
    "Registration.personaldataEmailPlaceholder": `primjer@gmail.com (email pomoću kojeg ćete ulaziti u program)`,												
    "Registration.accountdataTitle": `I još samo par podataka za podesiti vaš račun:`,												
    "Registration.accountdataName": `Naziv subjekta`,												
    "Registration.accountdataNamePlaceholder": `Naziv onoga tko izdaje račun (naziv tvrtke ili obrta ili vaše ime i prezime)`,												
    "Registration.accountdataTypeSelector": `Tip subjekta`,												
    "Registration.accountdataTypeSelectorPlaceholder": `Odaberite tip subjekta (tvrtke)`,												
    "Registration.accountdataPassword": `Lozinka`,												
    "Registration.accountdataPasswordPlaceholder": `Lozinka pomoću koje ćete ulaziti u program`,												
    "Registration.securityQuestion": `Sigurnosno pitanje`,												
    "Registration.securityQuestionPlaceholder": `Molimo unesite odgovor na sigurnosno pitanje`,												
    "Registration.securityQuestion1": `U kuću ulazimo kroz?`,												
    "Registration.securityQuestion1Answer1": `vrata`,												
    "Registration.securityQuestion1Answer2": `prozor`,												
    "Registration.securityQuestion2": `Dvanaest minus sedam je?`,												
    "Registration.securityQuestion2Answer1": `pet`,												
    "Registration.securityQuestion2Answer2": `5`,												
    "Registration.securityQuestion3": `Organ vida je?`,												
    "Registration.securityQuestion3Answer1": `oko`,												
    "Registration.securityQuestion3Answer2": `oči`,												
    "Registration.securityQuestion4": `Kad je hladno voda se pretvara u?`,												
    "Registration.securityQuestion4Answer1": `led`,												
    "Registration.securityQuestion5": `Kad sunce zađe, dođe?`,												
    "Registration.securityQuestion5Answer1": `noć`,												
    "Registration.securityQuestion5Answer2": `mrak`,												
    "Registration.registerButton": `Registriraj se`,												
    "Registration.promoText": `Isprobajte besplatno 15 dana`,												
    "Registration.testimonial1Name": `Mirko Budić, odvjetnik`,												
    "Registration.testimonial1Text": `Prvi program u životu u kojem ništa ne bi promijenio.`,												
    "Registration.testimonial2Name": `Ankica Šolić, odvjetnica`,												
    "Registration.testimonial2Text": `Meni je ovo savršeno.`,												
    "Registration.testimonial3Name": `Igor Čukac, Komet prijevoz`,												
    "Registration.testimonial3Text": `Koristimo program Izračunko za izradu ponuda i računa od rujna 2013 godine.<br/>Program nam je ubrzao proces izrade ponuda kako u desktop tako i u mobilnoj verziji. Prosječno trajanje izrade PDF-ponuda iznosi samo 3 MINUTE OD PRIMLJENOG UPITA.<br />Također želim napomenuti da na sva pitanja pružaju kvalitetnu korisničku podršku.`,												
    "Registration.testimonial4Name": `Robert Koščak, Svijet komunikacija`,												
    "Registration.testimonial4Text": `Ovaj vaš softver je fenomenalno nešto i to mi je toliko olakšalo rad da ja to ne mogu opisati.<br/>Svaka čast vašem timu, prezadovoljan sam.`,												
    "Registration.successMessageTitle": `Dobrodošli`,												
    "Registration.showSuccessMessage": `Hvala što ste se odlučili isprobati naš program, nadamo se da će vam se sviditi. Pristupna adresa poslana je na vašu e-mail adresu.<br/>Trenutno imate unesene samo osnovne podatke. Sve ostalo možete precizno postaviti prema vašim potrebama unutar postavki.`,												
    "Registration.MailToUser.subject": `Izračunko korisnički podaci`,												
    "Registration.MailToUser.text": `Poštovani,
    
    šaljemo Vam vaše korisničke podatke za pristup Izračunku.
    
    Ime i prezime: {{userFullName}}
    Adresa za pristup: {{adminLink}}
    Pristupni e-mail: {{userMail}}
    
    Ako zaboravite lozinku, na adresi za pristup imate mogućnost njenog mijenjanja.
    
    Lijep pozdrav,
    
    Izračunko tim`,												
    "Registration.MailToUs.subject": `Novi korisnik se upravo prijavio!`,												
    "Registration.MailToUs.text": `Jupiiiiii! Upravo smo dobili novog korisnika. Zove se {{userFullName}} ({{userMail}}), dolazi nam sa tvrtkom {{companytName}} i u poslovne vode upušta se kao '{{companyType}}'.`,												
    "ResetPassword.title": `Izmijeni lozinku`,												
    "ResetPassword.passwordPlaceholder": `Nova lozinka`,												
    "ResetPassword.passwordRepeatPlaceholder": `Ponovi novu lozinku`,												
    "ResetPassword.buttonText": `Izmijeni lozinku`,												
    "ResetPassword.showSuccessMessage": `Uspješno ste izmijenili lozinku`,												
    "ResetPassword.showErrorMessage": `Greška prilikom mijenjanja lozinke`,												
    "Reports.Header.pageTitle": `Izvještaji`,												
    "Reports.Header.pageTitleInfoTitle": `Vrste izvještaja`,												
    "Reports.Header.pageTitleInfo": `Izvještaj o prometu - koliko je naplaćeno kojom vrstom plaćanja (zbirno)<br />
    Promet po danima - koliko je naplaćeno kojom vrstom plaćanja (po danu)<br />
    Knjiga računa - računi po danima<br />
    Izvještaj po proizvodu - koliko se kojih proizvoda prodalo i pošto<br />
    Knjiga prometa - promet po danima i zbroj svega`,												
    "Reports.Type.title": `Vrsta izvještaja`,												
    "Reports.Type.salesReport": `Izvještaj o prometu`,												
    "Reports.Type.revenuePerDay": `Promet po danima`,												
    "Reports.Type.invoicesBook": `Knjiga računa`,												
    "Reports.Type.productReport": `Izvještaj po proizvodu`,												
    "Reports.Type.salesBook": `Knjiga prometa`,												
    "Reports.from": `Od`,												
    "Reports.until": `Do`,												
    "Reports.noInvoicesMessage": `Ne postoji niti jedan račun u odabranom periodu`,												
    "Reports.invoices": `Računi`,												
    "Reports.paymentType": `Način plaćanja`,												
    "Reports.noVAT": `Bez PDV-a`,												
    "Reports.VAT": `PDV`,												
    "Reports.withVAT": `S PDV-om`,												
    "Reports.total": `Ukupno`,												
    "Reports.date": `Datum`,												
    "Reports.invoiceNumber": `Broj računa`,												
    "Reports.baseAmount": `Osnovica`,												
    "Reports.product": `Proizvod`,												
    "Reports.quantity": `Količina`,												
    "Reports.amount": `Iznos`,												
    "Reports.description": `Opis`,												
    "Reports.cashCheck": `Gotovina/Ček`,												
    "Reports.notCash": `Bezgotovinski`,												
    "Settings.Header.pageTitle": `Postavke`,												
    "Settings.Side.title": `Dodatno`,												
    "Settings.Side.users": `Korisnici tvrtke`,												
    "Settings.Side.printerSettings": `Postavke ispisa`,												
    "Settings.Side.myPictures": `Moje slike proizvoda`,												
    "Settings.Side.fiscalisation": `Postavke fiskalizacije`,												
    "Settings.Side.salesPlaces": `Prodajna mjesta`,												
    "Settings.Side.eInvoice": `Postavke eRačuna`,												
    "Settings.Table.All.buttonShowPrint": `Prikaži u ispisu`,												
    "Settings.Table.BasicInformation.title": `Osnovne informacije`,												
    "Settings.Table.BasicInformation.name": `Naziv`,												
    "Settings.Table.BasicInformation.namePlaceholder": `Unesi naziv onoga tko izdaje račune (tvrtke ili obrta ili vaše ime i prezime)`,												
    "Settings.Table.BasicInformation.nameInfoTitle": `Naziv`,												
    "Settings.Table.BasicInformation.nameInfo": `Službeni naziv firme, obrta, udruge, ... ili vaše osobno ime i prezime ukoliko se bavite nekom slobodnom djelatnošću.`,												
    "Settings.Table.BasicInformation.identificationNumber": `Porezni broj (OIB)`,												
    "Settings.Table.BasicInformation.identificationNumberPlaceholder": `Unesi OIB`,												
    "Settings.Table.BasicInformation.identificationNumber.fiscalizationIsOnInfoTitle": `Trenutno nije promjenjiv`,												
    "Settings.Table.BasicInformation.identificationNumber.fiscalizationIsOnInfo": `Porezni broj (OIB) ne možete mijenjati dok je fiskalizacija uključena.`,												
    "Settings.Table.Address.title": `Adresa`,												
    "Settings.Table.Address.street": `Ulica`,												
    "Settings.Table.Address.streetPlaceholder": `npr. Vukovarska`,												
    "Settings.Table.Address.streetNumber": `Kućni broj`,												
    "Settings.Table.Address.streetNumberPlaceholder": `npr. 10`,												
    "Settings.Table.Address.city": `Mjesto`,												
    "Settings.Table.Address.cityPlaceholder": `npr. Split`,												
    "Settings.Table.Address.postNumber": `Poštanski broj`,												
    "Settings.Table.Address.postNumberPlaceholder": `npr. 21000`,												
    "Settings.Table.Address.county": `Općina`,												
    "Settings.Table.Address.countyPlaceholder": `Unesi naziv općine`,												
    "Settings.Table.Address.country": `Država`,												
    "Settings.Table.Contact.title": `Kontakt informacije`,												
    "Settings.Table.Contact.phone": `Telefon`,												
    "Settings.Table.Contact.mobile": `Mobitel`,												
    "Settings.Table.Contact.fax": `Fax`,												
    "Settings.Table.Contact.email": `Email adresa`,												
    "Settings.Table.Contact.emailInfoTitle": `Email adresa`,												
    "Settings.Table.Contact.emailInfo": `Putem ove Email adrese će se slati ponude i računi vašim klijentima.`,												
    "Settings.Table.Contact.emailInfo:lawyer": `Putem ove Email adrese će se slati troškovnici i računi vašim klijentima.`,												
    "Settings.Table.Contact.web": `Web`,												
    "Settings.Table.Contact.iban": `Broj bankovnog računa (IBAN)`,												
    "Settings.Table.TaxCurrency.title": `Porez i valuta`,												
    "Settings.Table.TaxCurrency.companyInVAT": `Izdavatelj računa je u sustavu PDV-a`,												
    "Settings.Table.TaxCurrency.companyInVATInfoTitle": `Porez na dodanu vrijednost (PDV)`,												
    "Settings.Table.TaxCurrency.companyInVATInfo": `Ako odaberete da je izdavatelj računa (vi ili vaša tvrtka) u sustavu PDV-a (poreza na dodanu vrijednost), tada će vam svaka stavka ponude/računa imati u sebi PDV kojeg odaberete niže. U suprotnom, nećete imati mogućnost stavljanja PDV-a u svoje ponude/račune.`,												
    "Settings.Table.TaxCurrency.companyInVATInfo:lawyer": `Ako odaberete da je izdavatelj računa (vi ili vaša tvrtka) u sustavu PDV-a (poreza na dodanu vrijednost), tada će vam svaka stavka troškovnika/računa imati u sebi PDV kojeg odaberete niže. U suprotnom, nećete imati mogućnost stavljanja PDV-a u svoje troškovnike/račune.`,												
    "Settings.Table.TaxCurrency.defaultVAT": `Porez na dodanu vrijednost (PDV)`,												
    "Settings.Table.TaxCurrency.defaultVATInfoTitle": `PDV`,												
    "Settings.Table.TaxCurrency.defaultVATInfo": `Ovaj PDV će automatski biti dodjeljen svakoj novoj stavci u ponudi ili računu.`,												
    "Settings.Table.TaxCurrency.defaultVATInfo:lawyer": `Ovaj PDV će automatski biti dodjeljen svakoj novoj stavci u troškovniku ili računu.`,												
    "Settings.Table.TaxCurrency.changeVATperProduct": `Dopusti mijenjanje PDV-a svake stavke`,												
    "Settings.Table.TaxCurrency.predefinedCurrency": `Valuta unosa`,												
    "Settings.Table.TaxCurrency.predefinedCurrencyInfoTitle": `Valuta unosa`,												
    "Settings.Table.TaxCurrency.predefinedCurrencyInfo": `Valuta u kojoj unosimo vrijednosti u cjenik te iz cjenika prebacujemo u ponudu ili račun.`,												
    "Settings.Table.TaxCurrency.predefinedCurrencyInfo:lawyer": `Valuta u kojoj unosimo vrijednosti u cjenik te iz cjenika prebacujemo u troškovnik ili račun.`,												
    "Settings.Table.TaxCurrency.useMoreCurrencies": `Želim odrediti početnu valutu ponuda i računa`,												
    "Settings.Table.TaxCurrency.predefinedOfferCurrency": `Valuta ponude`,												
    "Settings.Table.TaxCurrency.predefinedOfferCurrency:lawyer": `Valuta troškovnika`,												
    "Settings.Table.TaxCurrency.predefinedInvoiceCurrency": `Valuta računa`,												
    "Settings.Table.TaxCurrency.predefinedClientCurrency": `Valuta klijenta`,												
    "Settings.Table.Pricelist.title": `Cjenik`,												
    "Settings.Table.Pricelist.useTotalPrice": `Želim unositi Sveukupnu cijenu (uključuje PDV i popust)`,												
    "Settings.Table.Pricelist.useTotalPriceInfoTitle": `Sveukupna cijena`,												
    "Settings.Table.Pricelist.useTotalPriceInfo": `Sveukupna cijena je krajnja cijena za kupca. Program će sam obračunati pripadajući PDV i popust.`,												
    "Settings.Table.Pricelist.showProductsAsDimensions": `Proizvode prikaži kao dimenziju (širina x visina)`,												
    "Settings.Table.Pricelist.showProductsAsDimensionsInfoTitle": `Dimenzija (širina x visina)`,												
    "Settings.Table.Pricelist.showProductsAsDimensionsInfo": `Ukoliko je većina vaših proizvoda određena širinom i visinom tada uključite ovu opciju (npr. prozor). Za ostale proizvode možete je isključiti prilikom unosa u cjenik.`,												
    "Settings.Table.Pricelist.showPurchasePrice": `U svom cjeniku želim imati nabavnu cijenu i maržu (uz prodajnu cijenu)`,												
    "Settings.Table.Pricelist.showPurchasePriceInfoTitle": `Nabavna cijena i marža`,												
    "Settings.Table.Pricelist.showPurchasePriceInfo": `Uključivanjem ove opcije dobit ćete automatsko računanje svoje zarade.`,												
    "Settings.Table.Pricelist.showProductDimensions": `Želim unositi dimenzije proizvoda (širina, visina i dubina)`,												
    "Settings.Table.Pricelist.showProductDimensionsInfoTitle": `Dimenzije proizvoda`,												
    "Settings.Table.Pricelist.showProductDimensionsInfo": `Uključivanjem ove opcije dobijate mogućnost unosa širine, visine i dubine za svoje proizvode.`,												
    "Settings.Table.Pricelist.showGroupsInPrint": `U nazive svojih proizvoda želim dodati grupe iz cjenika`,												
    "Settings.Table.Pricelist.showGroupsInPrintInfoTitle": `Kreiranje naziva proizvoda`,												
    "Settings.Table.Pricelist.showGroupsInPrintInfo": `Naziv proizvoda na ispisu se slaže od naziva grupa, podgrupa i naziva proizvoda. Npr. grupa KEMIJSKA OLOVKA + podgrupa TOZ + proizvod PLAVA daje ukupni naziv stavke KEMIJSKA OLOVKA TOZ PLAVA.`,												
    "Settings.Table.Pricelist.showGroupsInPrintInfoWindowmakers": `Naziv proizvoda na ispisu se slaže od naziva grupa, podgrupa i naziva proizvoda. Kada je proizvod određen širinom i visinom preporučujemo uključivanje ove opcije. Npr. grupa PROZOR + podgrupa DVOKRILNI + proizvod 1200 x 1200 daje ukupni naziv stavke PROZOR DVOKRILNI 1200 x 1200. Cjenik koji ste dobili napravljen je pod pretpostavkom da je ova opcija uključena.`,												
    "Settings.Table.Pricelist.groupsSeparator": `Odvoji nazive grupa pomoću`,												
    "Settings.Table.Pricelist.groupsSeparatorDefaultText": ` -> `,												
    "Settings.Table.Pricelist.showSimilarProductPrices": `Prilikom unosa stavke želim da se prikazuju cijene sličnih proizvoda`,												
    "Settings.Table.Pricelist.showSimilarProductInfoTitle": `Cijene sličnih proizvoda`,												
    "Settings.Table.Pricelist.showSimilarProductPricesInfo": `Uključivanjem ove opcije cijene proizvoda sličnog naziva biti će vam prikazane prilikom unosa proizvoda. Na taj način lakše možete odrediti koliku cijenu želite postaviti.`,												
    "Settings.Table.Pricelist.predefinedMeasurementsUnits": `Predefinirane mjerne jedinice`,												
    "Settings.Table.PredefinedText.title": `Predefinirani tekstovi`,												
    "Settings.Table.PredefinedText.offerNote": `Predefinirana napomena na ponudi`,												
    "Settings.Table.PredefinedText.offerNote:lawyer": `Predefinirana napomena na troškovniku`,												
    "Settings.Table.PredefinedText.invoiceNote": `Predefinirana napomena na računu`,												
    "Settings.Table.PredefinedText.mailOffer": `Predefinirani mail uz ponudu`,												
    "Settings.Table.PredefinedText.mailOffer:lawyer": `Predefinirani mail uz troškovnik`,												
    "Settings.Table.PredefinedText.mailInvoice": `Predefinirani mail uz račun`,												
    "Settings.Table.Languages.title": `Jezici`,												
    "Settings.Table.Languages.interfaceLanguage": `Jezik sučelja`,												
    "Settings.Table.Languages.offerLanguage": `Jezik ponude`,												
    "Settings.Table.Languages.offerLanguage:lawyer": `Jezik troškovnika`,												
    "Settings.Save.Message.successUpdate": `Postavke uspješno spremljene.`,												
    "Settings.Save.Message.successUpdateNote": `Napomena: promjene neće biti vidljive na postojećim ponudama i računima dok ih ne preuzmete unutar postavki pojedine ponude/računa.`,												
    "Settings.Save.Message.errorUpdate": `Greška prilikom spremanja postavki.`,												
    "Settings.PrintSettings.Header.pageTitle": `Postavke ispisa`,												
    "Settings.PrintSettings.Message.successUpdate": `Postavke ispisa uspješno spremljene.`,												
    "Settings.PrintSettings.Message.errorUpdate": `Greška prilikom spremanja postavki ispisa.`,												
    "Settings.PrintSettings.Table.Pictures.title": `Zaglavlje, podnožje i potpis`,												
    "Settings.PrintSettings.Table.Pictures.header": `Zaglavlje`,												
    "Settings.PrintSettings.Table.Pictures.headerNone": `Zaglavlje ne postoji.`,												
    "Settings.PrintSettings.Table.Pictures.headerDeleteTitle": `Izbriši zaglavlje`,												
    "Settings.PrintSettings.Table.Pictures.headerDelete": `Da li zaista želite izbrisati zaglavlje?`,												
    "Settings.PrintSettings.Table.Pictures.footer": `Podnožje`,												
    "Settings.PrintSettings.Table.Pictures.footerFreeNote": `U besplatnoj verziji nije dozvoljeno mijenjati podnožje.`,												
    "Settings.PrintSettings.Table.Pictures.footerNone": `Podnožje ne postoji.`,												
    "Settings.PrintSettings.Table.Pictures.footerDeleteTitle": `Izbriši podnožje`,												
    "Settings.PrintSettings.Table.Pictures.footerDelete": `Da li zaista želite izbrisati podnožje?`,												
    "Settings.PrintSettings.Table.Pictures.signature": `Potpis`,												
    "Settings.PrintSettings.Table.Pictures.signatureNone": `Potpis ne postoji.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.pageTitleNew": `Dodaj zaglavlje`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.pageTitleEdit": `Zamijeni zaglavlje`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.infoNote": `Odaberite sliku za slanje sa svog računala (u obzir dolaze sljedeće vrste slika: .jpg, .png, .bmp, .gif). Maksimalna veličina datoteke je 200 KB. Slika će biti smanjena na 2.5 cm po visini i proporcionalno po širini.<br />Napomena: za optimalan ispis Vaših ponuda i računa preporučujemo sliku maksimalne širine 2008 px i maksimalne visine 295 px uz rezoluciju od barem 300 dpi).`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.infoNote:lawyer": `Odaberite sliku za slanje sa svog računala (u obzir dolaze sljedeće vrste slika: .jpg, .png, .bmp, .gif). Maksimalna veličina datoteke je 200 KB. Slika će biti smanjena na 2.5 cm po visini i proporcionalno po širini.<br />Napomena: za optimalan ispis Vaših troškovnika i računa preporučujemo sliku maksimalne širine 2008 px i maksimalne visine 295 px uz rezoluciju od barem 300 dpi).`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.selectButton": `Odaberi`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.headerNotSelectedNote": `Dokument nije odabran`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.warningChangeNote": `Slanje novog zaglavlja prebrisat će postojeće!<br />Zbog kvalitete ispisa vaših dokumenata, trebate imate sliku kvalitete barem 300 dpi (točaka po inču).`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.infoPictureRights": `Slanjem ove slike jamčite da imate pravo na njenu distribuciju.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.bigPictureNote": `Datoteka je prevelika. Maksimalna veličina je {{maxFileSize}} kb.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.notPictureNote": `Datoteka nije slika.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.headerAdded": `Zaglavlje je uspješno dodano.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.headerRemoved": `Zaglavlje izbrisano.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.messageBadQuality": `Zaglavlje nije dodano.<br />Zbog kvalitete ispisa vaših dokumenata, trebate imati sliku kvalitete od barem 300 dpi (točaka po inču).`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.pageTitleNew": `Dodaj podnožje`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.pageTitleEdit": `Zamijeni podnožje`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.footerTitle": `Podnožje`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.warningChangeNote": `Slanje novog podnožja prebrisat će postojeće!<br />Zbog kvalitete ispisa vaših dokumenata, trebate imati sliku kvalitete barem 300 dpi (točaka po inču).`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.footerAdded": `Podnožje je uspješno dodano.`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.footerDeleted": `Podnožje je izbrisano.`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.messageBadQuality": `Podnožje nije dodano.<br/>Zbog kvalitete ispisa vaših dokumenata, trebate imati sliku kvalitete od barem 300 dpi (točaka po inču).`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.pageTitleNew": `Dodaj potpis`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.pageTitleEdit": `Zamijeni potpis`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureTitle": `Potpis`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureInfo": `Odaberite sliku za slanje sa svog računala (u obzir dolaze sljedeće vrste slika: .jpg, .png, .bmp, .gif). Maksimalna veličina datoteke je 200 KB. Slika će biti smanjena na 7 cm širine ili 4 cm visine ako je veća od toga (proporcionalno prema originalu).`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureAdded": `Potpis dodan.`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemoved": `Potpis izbrisan.`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemoveQuestion": `Da li doista želite izbrisati trenutni potpis?`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemove": `Izbriši potpis`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureChange": `Zamijeni potpis`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureOverwrite": `Slanje novog potpisa prebrisat će postojeći!`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureViolation": `Potpis nije dodan. Maksimalna dozvoljena širina je 7cm, a visina 4cm.`,												
    "Settings.PrintSettings.Table.Pictures.New.extraInfoInInvoice": `Napomena: uz novu sliku, dokument će preuzeti i vaše aktualne podatke (kontakt, postavke ispisa).`,												
    "Settings.PrintSettings.Table.Invoice.title": `Postavke ispisa ponuda / računa`,												
    "Settings.PrintSettings.Table.Invoice.title:lawyer": `Postavke ispisa troškovnika / računa`,												
    "Settings.PrintSettings.Table.Invoice.printingMethod": `Način ispisa računa`,												
    "Settings.PrintSettings.Table.Invoice.showPaymentDate": `Prikaži datum dospijeća i kada nema odgode plaćanja`,												
    "Settings.PrintSettings.Table.Invoice.printConversionRate": `Ispiši tečaj ponude i računa`,												
    "Settings.PrintSettings.Table.Invoice.printPaymentCode": `Ispiši kod za plaćanje na ponudama i računima`,												
    "Settings.PrintSettings.Table.Invoice.PrintPaymentCode.infoTitle": `Kod za plaćanje`,												
    "Settings.PrintSettings.Table.Invoice.PrintPaymentCode.infoText": `Ovaj kod omogućuje vašem klijentu da odmah dobije popunjeno sve podatke za plaćanje ako ga skenira svojom aplikacijom za Internet bankarstvo. <br />2D bar kod za plaćanje je napravljen od strane Hrvatske udruge banaka te u drugim zemljama najvjerojatnije ne radi.`,												
    "Settings.PrintSettings.Table.Invoice.PrintPaymentCode.insertIBAN": `Kako bi mogli ispisati kod za plaćanje, unesite vaš IBAN`,												
    "Settings.PrintSettings.Table.Invoice.textAbovePaymentCode": `Tekst iznad koda za plaćanje`,												
    "Settings.PrintSettings.Table.Invoice.textAbovePaymentCodeDefaultText": `Skenirajte mobitelom za plaćanje:`,												
    "Settings.PrintSettings.Table.Invoice.textWithOfferNumber": `Tekst uz broj ponude`,												
    "Settings.PrintSettings.Table.Invoice.textWithOfferNumber:lawyer": `Tekst uz broj troškovnika`,												
    "Settings.PrintSettings.Table.Invoice.textWithOfferNumberDefaultText": `Ponuda broj`,												
    "Settings.PrintSettings.Table.Invoice.textWithOfferNumberDefaultText:lawyer": `Troškovnik broj`,												
    "Settings.PrintSettings.Table.Invoice.textWithInvoiceNumber": `Tekst uz broj računa`,												
    "Settings.PrintSettings.Table.Invoice.textWithInvoiceNumberDefaultText": `Račun broj`,												
    "Settings.PrintSettings.Table.Invoice.invoiceLabel": `Oznaka računa`,												
    "Settings.PrintSettings.Table.Invoice.textWithInvoiceDate": `Tekst uz datum računa`,												
    "Settings.PrintSettings.Table.Invoice.textWithInvoiceDateDefaultText": `Datum i vrijeme`,												
    "Settings.PrintSettings.Table.Invoice.textWithPaymentDate": `Tekst uz datum dospijeća`,												
    "Settings.PrintSettings.Table.Invoice.textWithPaymentDateDefaultText": `Datum dospijeća`,												
    "Settings.PrintSettings.Table.Invoice.textWithPersonInCharge": `Tekst uz ime odgovorne osobe`,												
    "Settings.PrintSettings.Table.Invoice.textWithPersonInChargeDefaultText": `Odgovorna osoba`,												
    "Settings.PrintSettings.Table.Invoice.namePersonInCharge": `Ime odgovorne osobe`,												
    "Settings.PrintSettings.Table.Invoice.NamePersonInCharge.infoTitle": `Ime odgovorne osobe`,												
    "Settings.PrintSettings.Table.Invoice.NamePersonInCharge.infoText": `Ono što ovdje napišete, uvijek će se ispisivati u potpisu ponuda i računa. A ukoliko ovdje ništa ne napišete ispisivati će se ime i prezime trenutnog operatera.`,												
    "Settings.PrintSettings.Table.Invoice.NamePersonInCharge.infoText:lawyer": `Ono što ovdje napišete, uvijek će se ispisivati u potpisu troškovnika i računa. A ukoliko ovdje ništa ne napišete ispisivati će se ime i prezime trenutnog operatera.`,												
    "Settings.PrintSettings.Table.Invoice.textWithOperator": `Tekst uz ime ili oznaku operatera`,												
    "Settings.PrintSettings.Table.Invoice.textWithOperatorDefaultText": `Operater:`,												
    "Settings.PrintSettings.Table.Invoice.PaymentTypes.title": `Tekstovi u načinima plaćanja`,												
    "Settings.PrintSettings.Table.Invoice.PaymentTypes.textWithWayOfPayment": `Tekst uz način plaćanja`,												
    "Settings.PrintSettings.Table.Invoice.PaymentTypes.textWithWayOfPaymentDefaultText": `Način plaćanja`,												
    "Settings.PrintSettings.Table.Invoice.PaymentType.cash": `Novčanice`,												
    "Settings.PrintSettings.Table.Invoice.PaymentType.bankAccount": `Transakcijski račun`,												
    "Settings.PrintSettings.Table.Invoice.PaymentType.card": `Kartica`,												
    "Settings.PrintSettings.Table.Invoice.PaymentType.check": `Ček`,												
    "Settings.PrintSettings.Table.Invoice.textInInvoiceFooter": `Tekst u podnožju računa`,												
    "Settings.PrintSettings.Table.Invoice.stornoInvoiceNote": `Napomena uz storno računa`,												
    "Settings.PrintSettings.Table.Invoice.stornoInvoiceNoteDefaultText": `Storno računa broj`,												
    "Settings.PrintSettings.Table.Invoice.StornoInvoiceNote.infoTitle": `Napomena uz storno računa`,												
    "Settings.PrintSettings.Table.Invoice.StornoInvoiceNote.infoText": `Ono što ovdje napišete, ispisati će se u napomeni računa koji se automatski napravi prilikom storniranja računa i dodati će se broj storniranog računa. Npr. ako stornirate račun broj 45, a ovdje napišite 'Storno računa broj', u novom računu napraviti će se napomena 'Storno računa broj 45'`,												
    "Settings.PrintSettings.Table.Invoice.Unfiscalized.infoTitle": `Napomena na računu koji je trebao biti fiskaliziran, a nije`,												
    "Settings.PrintSettings.Table.Invoice.Unfiscalized.infoText": `Ovaj račun nije fiskaliziran.`,												
    "Settings.PrintSettings.Table.Columns.title": `Nazivi stupaca u tablici ponude / računa`,												
    "Settings.PrintSettings.Table.Columns.title:lawyer": `Nazivi stupaca u tablici troškovnika / računa`,												
    "Settings.PrintSettings.Table.Columns.itemPosition": `Naziv stupca rednog broja stavke`,												
    "Settings.PrintSettings.Table.Columns.itemPositionDefaultText": `RB`,												
    "Settings.PrintSettings.Table.Columns.item": `Naziv stupca stavke`,												
    "Settings.PrintSettings.Table.Columns.itemDefaultText": `NAZIV`,												
    "Settings.PrintSettings.Table.Columns.itemQuantity": `Naziv stupca količine stavke`,												
    "Settings.PrintSettings.Table.Columns.itemQuantityDefaultText": `KOL`,												
    "Settings.PrintSettings.Table.Columns.measurementUnit": `Naziv stupca jedinice mjere`,												
    "Settings.PrintSettings.Table.Columns.measurementUnitDefaultText": `JM`,												
    "Settings.PrintSettings.Table.Columns.price": `Naziv stupca cijene stavke`,												
    "Settings.PrintSettings.Table.Columns.priceDefaultText": `CIJENA`,												
    "Settings.PrintSettings.Table.Columns.discount": `Naziv stupca popusta stavke`,												
    "Settings.PrintSettings.Table.Columns.discountDefaultText": `POPUST`,												
    "Settings.PrintSettings.Table.Columns.totalPrice": `Naziv stupca ukupne cijene stavke`,												
    "Settings.PrintSettings.Table.Columns.totalPriceDefaultText": `IZNOS`,												
    "Settings.PrintSettings.Table.Totals.title": `Tekstovi unutar stupca totala u tablici ponude / računa`,												
    "Settings.PrintSettings.Table.Totals.title:lawyer": `Tekstovi unutar stupca totala u tablici troškovnika / računa`,												
    "Settings.PrintSettings.TableTotalName.totalPrice": `Naziv ukupne cijene`,												
    "Settings.PrintSettings.TableTotalName.totalPriceDefaultText": `UKUPNA CIJENA`,												
    "Settings.PrintSettings.TableTotalName.tax": `Naziv poreza`,												
    "Settings.PrintSettings.TableTotalName.taxDefaultText": `PDV`,												
    "Settings.PrintSettings.TableTotalName.calculatedOn": `Tekst uz osnovicu poreza`,												
    "Settings.PrintSettings.TableTotalName.calculatedOnDefaultText": `obračunato na`,												
    "Settings.PrintSettings.TableTotalName.calculatedOnInfoTitle": `Tekst između iznosa poreza i ukupnog iznosa stavki po tom porezu`,												
    "Settings.PrintSettings.TableTotalName.calculatedOnInfo": `Ovaj tekst se pojavljuje uz iznos poreza koji je 'obračunat na' ukupni iznos stavki po tom porezu.`,												
    "Settings.PrintSettings.TableTotalName.nonTaxable": `Naziv za neoporezivo`,												
    "Settings.PrintSettings.TableTotalName.nonTaxableDefaultText": `Neoporezivo`,												
    "Settings.PrintSettings.TableTotalName.totalPriceWithVAT": `Naziv ukupne cijene s porezom`,												
    "Settings.PrintSettings.TableTotalName.totalPriceWithVATDefaultText": `SVEUKUPNA CIJENA`,												
    "Settings.PrintSettings.Table.Contacts.title": `Tekstovi uz kontakt podatke`,												
    "Settings.PrintSettings.TextContact.VAT": `Tekst uz porezni broj`,												
    "Settings.PrintSettings.TextContact.VATDefaultText": `OIB:`,												
    "Settings.PrintSettings.TextContact.phone": `Tekst uz telefon`,												
    "Settings.PrintSettings.TextContact.phoneDefaultText": `Tel:`,												
    "Settings.PrintSettings.TextContact.fax": `Tekst uz fax`,												
    "Settings.PrintSettings.TextContact.faxDefaultText": `Fax:`,												
    "Settings.PrintSettings.TextContact.telFax": `Tekst uz tel/fax`,												
    "Settings.PrintSettings.TextContact.telFaxDefaultText": `Tel/Fax:`,												
    "Settings.PrintSettings.TextContact.telFaxInfoTitle": `Tekst uz tel/fax`,												
    "Settings.PrintSettings.TextContact.telFaxInfo": `Ovaj tekst će se pojaviti ako unesete isti broj telefona i faxa.`,												
    "Settings.PrintSettings.TextContact.mobile": `Tekst uz mobitel`,												
    "Settings.PrintSettings.TextContact.mobileDefaultText": `Mob:`,												
    "Settings.PrintSettings.TextContact.mail": `Tekst uz e-mail`,												
    "Settings.PrintSettings.TextContact.web": `Tekst uz web adresu`,												
    "Settings.PrintSettings.TextContact.IBAN": `Tekst uz broj bankovnog računa`,												
    "Settings.PrintSettings.TextContact.IBANDefaultText": `IBAN:`,												
    "Settings.MyAccount.Header.pageTitle": `Moj račun`,												
    "Settings.MyAccount.Table.Usage.title": `Upotreba`,												
    "Settings.MyAccount.Usage.invoices": `Računi`,												
    "Settings.MyAccount.Usage.offers": `Ponude`,												
    "Settings.MyAccount.Usage.users": `Korisnici`,												
    "Settings.MyAccount.Usage.salesPlaces": `Prodajna mjesta`,												
    "Settings.MyAccount.Table.Plan.title": `Paket`,												
    "Settings.MyAccount.Plan.expires": `Paket ističe {{packageExpiryDate}}.`,												
    "Settings.MyAccount.Plan.free": `Imate besplatni paket.`,												
    "Settings.MyAccount.Table.API.title": `API`,												
    "Settings.MyAccount.API.documentation": `Dokumentacija za API se nalazi <u><a href=\"http://www.izracunko.com/api\">ovdje (kliknite)</a></u>.`,												
    "Settings.MyAccount.API.key": `Imate besplatni paket.`,												
    "Settings.MyAccount.API.users": `Korisnici`,												
    "Settings.MyAccount.API.salesPlaces": `Prodajna mjesta`,												
    "Settings.MyPictures.Header.pageTitle": `Moje slike proizvoda`,												
    "Settings.MyPictures.Table.PredefinedPictures.title": `Izračunko set slika`,												
    "Settings.MyPictures.Table.PredefinedPictures.buttonBack": `Natrag`,												
    "Settings.MyPictures.Table.MyPictures.title": `Moje slike`,												
    "Settings.MyPictures.Table.MyPictures.buttonDelete": `Izbriši`,												
    "Settings.MyPictures.Table.UploadPicture.title": `Dodaj sliku proizvoda`,												
    "Settings.MyPictures.Table.UploadPicture.addPicturePlaceholder": `Slika nije odabrana`,												
    "Settings.MyPictures.Table.UploadPicture.addPictureButton": `Dodaj`,												
    "Settings.MyPictures.Table.UploadPicture.maxPictureSize": `Maksimalna dopuštena veličina slike je 100 KB.`,												
    "Settings.MyPictures.Table.UploadPicture.saveButton": `Dodaj sliku`,												
    "Settings.MyPictures.Delete.pageTitle": `Izbriši sliku`,												
    "Settings.MyPictures.Delete.questionYesNo": `Da li zaista želite odabranu sliku?`,												
    "Settings.MyPictures.Messages.successDelete": `Slika uspješno izbrisana.`,												
    "Settings.MyPictures.Messages.errorDelete": `Slika nije uspješno izbrisana.`,												
    "Settings.MyPictures.Messages.successUpload": `Slika uspješno dodana.`,												
    "Settings.MyPictures.Messages.errorUploadType": `Nedozvoljeni tip slike. Dozvoljeni tipovi su: png, jpg i gif.`,												
    "Settings.MyPictures.Messages.errorUploadSize": `Slika je prevelika. Maksimalna dopuštena veličina slike je 100 KB.`,												
    "Settings.MyPictures.Messages.errorUploadUnknown": `Slika nije uspješno dodana.`,												
    "Settings.MyPictures.Messages.warningTooManySelected": `Možete odabrati samo jednu sliku za dodavanje.`,												
    "Settings.EInvoice.Header.pageTitle": `Postavke eRačuna`,												
    "Settings.EInvoice.Table.ObligatoryData.title": `Obavezni podaci`,												
    "Settings.EInvoice.Table.obligatoryInvoiceData": `Obavezni podaci na računu (temeljni kapital, član uprave, …)`,												
    "Settings.EInvoice.Table.ObligatoryInvoiceData.infoTitle": `Obavezni podaci na računu `,												
    "Settings.EInvoice.Table.ObligatoryInvoiceData.infoText": `Iako sve obavezne podatke najvjerojatnije imate na računu, trebaju nam i na jednom mjestu za slanje eRačuna. Koji su to, možete saznati na našem <u><a href=\"http://www.izracunko.com/blog/entry/sto-treba-biti-na-racunu-kojeg-izdaje-hrvatski-poduzetnik\">blogu (kliknite)</a></u> u odjeljku Obavezni elementi na računima za d.o.o. ili Obrt.`,												
    "Settings.EInvoice.Table.AdditionalData.title": `Dodatni podaci`,												
    "Settings.EInvoice.Table.AdditionalIBAN": `Drugi IBAN`,												
    "Settings.EInvoice.Table.AdditionalIBAN.infoTitle": `Još jedan bankovni račun (IBAN)`,												
    "Settings.EInvoice.Table.AdditionalIBAN.infoText": `Ukoliko osim bankovnog računa iz postavki želite klijentima ponuditi mogućnost plaćanja i na vaš drugi bankovni račun, unesite ga ovdje i biti će dio vaših eRačuna.`,												
    "Settings.EInvoice.Table.MyEInvoice.title": `Postavke servisa Moj eRačun`,												
    "Settings.EInvoice.Table.MyEInvoice.Title.infoTitle": `Moj eRačun servis`,												
    "Settings.EInvoice.Table.MyEInvoice.infoText": `Moj eRačun je vanjski servis kojim se služimo kako bi automatizirali slanje eRačuna iz našeg sustava.<br />Ove podatke dobijate nakon registracije na servis 'Moj eRačun'. Ukoliko želite to napraviti, javite nam se na info@izracunko.com kako bi vam poslali upute.<br />Ukoliko želite slati eRačune putem nekog drugog servisa, to možete napraviti tako da preuzmete eRačun kada ga naš sustav napravi i prenesete u drugi servis.`,												
    "Settings.EInvoice.Table.userID": `ID Korisnika`,												
    "Settings.EInvoice.Table.password": `Lozinka`,												
    "Settings.EInvoice.Messages.successSave": `Uspješno spremljeni podaci za eRačun.`,												
    "Settings.EInvoice.Messages.errorSave": `Neispravna kombinacija korisničkog imena i lozinke za Moj eRačun servis.`,												
    "Settings.EInvoice.Messages.warningMissingCompanyData": `Kako bi mogli poslati eRačun, morate unijeti OIB, kontakt email te kompletnu adresu (ulica, broj, grad, poštanski broj i država).`,												
    "Settings.Fiscalisation.Header.pageTitle": `Postavke fiskalizacije`,												
    "Settings.Fiscalisation.Table.CurrentSettings.title": `Fiskalizacija`,												
    "Settings.Fiscalisation.Table.CurrentSettings.active": `Fiskalizacija uključena`,												
    "Settings.Fiscalisation.Table.CurrentSettings.certificateInfo": `Trenutačni certifikat`,												
    "Settings.Fiscalisation.Table.CurrentSettings.certificateValidUntil": `Vrijedi do`,												
    "Settings.Fiscalisation.Table.CurrentSettings.certificateInfoDefaultText": `Nema certifikata`,												
    "Settings.Fiscalisation.Table.UploadCertificate.title": `Dodaj certifikat`,												
    "Settings.Fiscalisation.Table.UploadCertificate.titleInfoTitle": `Kako dodati certifikat?`,												
    "Settings.Fiscalisation.Table.UploadCertificate.titleInfoText": `Da bi dodali certifikat, prvo ga odaberite sa svog računala i unesite njegovu lozinku.<br />Ukoliko nemate certifikat, tada ga na FINA-i možete zatražiti (kratke upute što vam treba imate u pitanjima i odgovorima <a href=\"http://www.izracunko.com/cijene\" target=\"_blank\">ovdje (kliknite)</a>.`,												
    "Settings.Fiscalisation.Table.UploadCertificate.selectCertificate": `Odaberi certifikat`,												
    "Settings.Fiscalisation.Table.UploadCertificate.selectCertificateDefaultText": `Certifikat nije odabran`,												
    "Settings.Fiscalisation.Table.UploadCertificate.selectCertificateButton": `Odaberi`,												
    "Settings.Fiscalisation.Table.UploadCertificate.password": `Lozinka`,												
    "Settings.Fiscalisation.Table.UploadCertificate.passwordDefaultText": `Unesi lozinku`,												
    "Settings.Fiscalisation.Table.UploadCertificate.saveButton": `Dodaj certifikat`,												
    "Settings.Fiscalisation.Messages.successActivation": `Fiskalizacija uspješno aktivirana.`,												
    "Settings.Fiscalisation.Messages.successDeactivation": `Fiskalizacija deaktivirana.`,												
    "Settings.Fiscalisation.Messages.errorActivationNoCertificate": `Fiskalizaciju nije moguće aktivirati bez certifikata.`,												
    "Settings.Fiscalisation.Messages.errorActivationCertificateExpired": `Fiskalizaciju nije moguće aktivirati jer je certifikat istekao.`,												
    "Settings.Fiscalisation.Messages.errorActivationOIBsDontMatch": `Fiskalizaciju nije moguće aktivirati jer OIB u postavkama ne odgovara OIB-u u certifikatu.`,												
    "Settings.Fiscalisation.Messages.errorActivationIntroMessage": `Fiskalizaciju nije moguće aktivirati dok u postavkama ne unesete`,												
    "Settings.Fiscalisation.Messages.errorActivationMissingCompanyAddress": `adresu tvrtke`,												
    "Settings.Fiscalisation.Messages.errorActivationMissingCompanyCity": `grad tvrtke`,												
    "Settings.Fiscalisation.Messages.errorActivationMissingCompanyTaxID": `OIB tvrtke`,												
    "Settings.Fiscalisation.Messages.errorActivationMissingPersonalIDs": `OIB-ove svih operatera (korisnika programa)`,												
    "Settings.Fiscalisation.Messages.errorActivationZipCodNotNumeric": `poštanski broj koji ima samo brojke.`,												
    "Settings.Fiscalisation.Messages.successUpload": `Certifikat uspješno dodan.`,												
    "Settings.Fiscalisation.Messages.errorUpload": `Certifikat nije uspješno dodan.`,												
    "Settings.Fiscalisation.Messages.errorUploadWrongPassword": `Niste unijeli ispravnu lozinku certifikata`,												
    "Settings.SalesPlaces.Header.pageTitle": `Prodajna mjesta`,												
    "Settings.SalesPlaces.Header.PageTitle.infoText": `Dodavanjem prodajnih mjesta možete imati za svako prodajno mjesto svoje brojeve računa. Kao administrator možete odrediti koji operater radi na kojem prodajnom mjestu.`,												
    "Settings.SalesPlaces.Header.newSalesPlace": `Dodaj prodajno mjesto`,												
    "Settings.SalesPlaces.Header.NewSalesPlace.warningMessage": `Dosegli ste dozvoljeni broj prodajnih mjesta u vašem paketu. Uvjete prelaska na veći paket možete pogledati <a href=\"http://www.izracunko.com/cijene#nadoplata\" target=\"_blank\">ovdje (kliknite)</a>.`,												
    "Settings.SalesPlaces.Side.activeSalesPlace": `Aktivno mjesto`,												
    "Settings.SalesPlaces.Side.activeSalesPlace.infoMessage": `Aktivno prodajno mjesto određuje koje račune, ponude i predloške vidite, te možete raditi izvještaje za to prodajno mjesto.`,												
    "Settings.SalesPlaces.Side.activeSalesPlace.infoMessage:lawyer": `Aktivno prodajno mjesto određuje koje račune, troškovnike i predloške vidite, te možete raditi izvještaje za to prodajno mjesto.`,												
    "Settings.SalesPlaces.Side.searchTitle": `Pretraživanje`,												
    "Settings.SalesPlaces.Side.searchPlaceholder": `Dio naziva`,												
    "Settings.SalesPlaces.Side.searchButton": `Traži`,												
    "Settings.SalesPlaces.Table.Header.title": `Popis prodajnih mjesta`,												
    "Settings.SalesPlaces.emptySearchMessage": `Prodajno mjesto kakvo ste tražili nije pronađeno.<br /><br />Možete ili promijeniti pretragu ili napraviti novo prodajno mjesto.`,												
    "Settings.SalesPlaces.Table.Header.numberOfSalesPlaces": `Broj prodajnih mjesta`,												
    "Settings.SalesPlaces.Table.Header.perPage": `po stranici`,												
    "Settings.SalesPlaces.Table.Header.name": `Naziv prodajnog mjesta`,												
    "Settings.SalesPlaces.Table.Header.city": `Mjesto`,												
    "Settings.SalesPlaces.Table.ItemMenu.edit": `Uredi`,												
    "Settings.SalesPlaces.Table.ItemMenu.delete": `Izbriši`,												
    "Settings.SalesPlaces.Edit.Header.pageTitleEdit": `Uredi prodajno mjesto`,												
    "Settings.SalesPlaces.Edit.Header.pageTitleNew": `Dodaj prodajno mjesto`,												
    "Settings.SalesPlaces.Edit.infoText": `Ukoliko promijenite oznaku prodajnog mjesta, oznaku naplatnog uređaja ili separator računa, promijeniti će vam se i izgled broja računa.<br />Ukoliko radite u Hrvatskoj, ove promjene trebate zapisati i u vašem Internom aktu, za više detalja <a href=\"https://www.izracunko.com/blog/fiskalizacija/primjeri-dokumenata-koje-po-zakonu-o-fiskalizaciji-morate-imati/\" target=\"_blank\">ovdje (kliknite)</a>.`,												
    "Settings.SalesPlaces.Edit.Table.name": `Naziv prodajnog mjesta`,												
    "Settings.SalesPlaces.Edit.Table.namePlaceholder": `Unesi naziv prodajnog mjesta`,												
    "Settings.SalesPlaces.Edit.Table.salesPlaceLabel": `Oznaka prodajnog mjesta`,												
    "Settings.SalesPlaces.Edit.Table.salesPlaceLabelPlaceholder": `Unesi oznaku prodajnog mjesta`,												
    "Settings.SalesPlaces.Edit.Table.SalesPlaceLabelTitle": `Oznake i broj računa`,												
    "Settings.SalesPlaces.Edit.Table.SalesPlaceLabelInfo": `Oznaka prodajnog mjesta, naplatnog uređaja i separator se dodaju na broj računa na sljedeći način: ako je oznaka prodajnog mjesta 'HQ', oznaka naplatnog uređaja '1', a separator '-', sve ovo nadodano na broj računa 23 bi bilo '23-HQ-1'.`,												
    "Settings.SalesPlaces.Edit.Table.billingDeviceLabel": `Oznaka naplatnog uređaja`,												
    "Settings.SalesPlaces.Edit.Table.billingDeviceLabelPlaceholder": `Unesi oznaku naplatnog uređaja`,												
    "Settings.SalesPlaces.Edit.Table.invoiceSeparator": `Separator računa`,												
    "Settings.SalesPlaces.Edit.Table.invoiceSeparatorPlaceholder": `Separator između broja, prodajnog mjesta i naplatnog uređaja`,												
    "Settings.SalesPlaces.Edit.Table.invoiceSufix": `Sufiks računa`,												
    "Settings.SalesPlaces.Edit.Table.invoiceSufixPlaceholder": `Izgled sufiksa (nastavka) računa za ovo prodajno mjesto`,												
    "Settings.SalesPlaces.Edit.Table.workingTime": `Radno vrijeme`,												
    "Settings.SalesPlaces.Edit.Table.workingTimePlaceholder": `Unesi radno vrijeme`,												
    "Settings.SalesPlaces.Edit.Table.salesPlaceHaveAddressYesNo": `Prodajno mjesto ima adresu`,												
    "Settings.SalesPlaces.Edit.Table.street": `Ulica`,												
    "Settings.SalesPlaces.Edit.Table.streetPlaceholder": `Unesi naziv ulice`,												
    "Settings.SalesPlaces.Edit.Table.streetNumber": `Broj ulice`,												
    "Settings.SalesPlaces.Edit.Table.streetNumberPlaceholder": `Unesi broj ulice`,												
    "Settings.SalesPlaces.Edit.Table.city": `Mjesto`,												
    "Settings.SalesPlaces.Edit.Table.cityPlaceholder": `Unesi mjesto`,												
    "Settings.SalesPlaces.Edit.Table.zipCode": `Poštanski broj`,												
    "Settings.SalesPlaces.Edit.Table.zipCodePlaceholder": `Unesi poštanski broj`,												
    "Settings.SalesPlaces.Edit.Table.county": `Općina`,												
    "Settings.SalesPlaces.Edit.Table.countyPlaceholder": `Unesi naziv općine`,												
    "Settings.SalesPlaces.Edit.Table.description": `Opis`,												
    "Settings.SalesPlaces.Edit.Table.descriptionPlaceholder": `Ovaj opis ispisati će se ispod prodajnog mjesta na vašim dokumentima`,												
    "Settings.SalesPlaces.Edit.Message.SuccessCreate": `Prodajno mjesto je uspješno dodano.`,												
    "Settings.SalesPlaces.Edit.Message.ErrorCreate": `Prodajno mjesto nije uspješno dodano.`,												
    "Settings.SalesPlaces.Edit.Message.SuccessUpdate": `Podaci o prodajnom mjestu su uspješno spremljeni.`,												
    "Settings.SalesPlaces.Delete.pageTitle": `Izbriši prodajno mjesto`,												
    "Settings.SalesPlaces.Delete.questionYesNo": `Da li zaista želite izbrisati prodajno mjesto {{salesPlaceNameAddress}}?<br /><br />Nakon što ga izbrišete, više vam neće biti dostupni računi ni ponude koje su u ovom prodajnom mjestu napravljeni pa preporučujemo da napravite njihovu sigusnosnu kopiju prije brisanja.`,												
    "Settings.SalesPlaces.Delete.successDelete": `Uspješno ste izbrisali prodajno mjesto {{salesPlaceNameAddress}}.`,												
    "Settings.SalesPlaces.Delete.infoMessage": `Niste izbrisali prodajno mjesto {{salesPlaceNameAddress}}.`,												
    "Settings.Users.Header.pageTitle": `Korisnici`,												
    "Settings.Users.Header.PageTitle.infoMessage": `Korisnici su osobe ovlaštene za pristup vašim podacima. Unutar Izračunka razlikujemo dvije vrste korisnika: administratore i obične korisnike. Obični korisnik ne može mijenjati cjenik, postavke niti raditi račune (iako mu možete dopustiti da radi račune ako to želite). Također nema uvid u nabavne cijene i marže, ako ih koristite prilikom formiranja cijene. Administrator može sve raditi.`,												
    "Settings.Users.Header.newUser": `Dodaj korisnika`,												
    "Settings.Users.Header.NewUser.warningMessage": `Dosegli ste dozvoljeni broj korisnika u vašem paketu. Uvjete prelaska na veći paket možete pogledati <a href=\"http://www.izracunko.com/cijene#nadoplata\" target=\"_blank\">ovdje (kliknite)</a>.`,												
    "Settings.Users.Side.searchTitle": `Pretraživanje`,												
    "Settings.Users.Side.searchPlaceholder": `Dio imena korisnika`,												
    "Settings.Users.Side.searchButton": `Traži`,												
    "Settings.Users.Table.Header.title": `Popis korisnika`,												
    "Settings.Users.emptySearchMessage": `Korisnik kakvog ste tražili nije pronađen.<br /><br />Možete ili promijeniti pretragu ili napraviti novog korisnika.`,												
    "Settings.Users.Table.Header.numberOfUsers": `Broj korisnika`,												
    "Settings.Users.Table.Header.perPage": `po stranici`,												
    "Settings.Users.Table.Header.user": `Korisnik`,												
    "Settings.Users.Table.Header.mail": `Login E-Mail`,												
    "Settings.Users.Table.Header.admin": `Administrator`,												
    "Settings.Users.Table.ItemMenu.edit": `Uredi`,												
    "Settings.Users.Table.ItemMenu.delete": `Izbriši`,												
    "Settings.Users.Edit.pageTitleEdit": `Uredi korisnika`,												
    "Settings.Users.Edit.pageTitleNew": `Novi korisnik`,												
    "Settings.Users.Edit.name": `Ime`,												
    "Settings.Users.Edit.namePlaceholder": `Mate`,												
    "Settings.Users.Edit.surname": `Prezime`,												
    "Settings.Users.Edit.surnamePlaceholder": `Matić`,												
    "Settings.Users.Edit.identificationNumber": `OIB`,												
    "Settings.Users.Edit.identificationNumberPlaceholder": `12345678901`,												
    "Settings.Users.Edit.sellingPlace": `Prodajno mjesto`,												
    "Settings.Users.Edit.personTitle": `Titula`,												
    "Settings.Users.Edit.personTitlePlaceholder": `direktor`,												
    "Settings.Users.Edit.loginMail": `Login e-mail`,												
    "Settings.Users.Edit.loginMailPlaceholder": `primjer@gmail.com (email pomoću kojeg će ulaziti u program)`,												
    "Settings.Users.Edit.password": `Lozinka`,												
    "Settings.Users.Edit.passwordPlaceholder": `NitkoDrugiNeZna1#`,												
    "Settings.Users.Edit.userLabel": `Oznaka korisnika`,												
    "Settings.Users.Edit.userLabelPlaceholder": `npr. 01`,												
    "Settings.Users.Edit.userIsAdmin": `Korisnik je administrator`,												
    "Settings.Users.Edit.userIsAdminInfo": `Ukoliko želite da korisnik može sve raditi što i Vi, odaberite opciju '(Da) Korisnik je administrator'. Ukoliko smatrate kako je bolje da korisnik ne može mijenjati cjenik, postavke, raditi račune te da nema uvid u nabavne cijene i marže, nemojte je odabrati (Ne). I ako odaberete 'Ne', moći ćete omogućiti ovom korisniku da radi račune ako to želite.`,												
    "Settings.Users.Edit.salesPlacesChangesAllowed": `Korisnik može mijenjati prodajna mjesta`,												
    "Settings.Users.Edit.invoicesAllowed": `Korisnik može raditi račune`,												
    "Settings.Users.Edit.saveAndSend": `Pošalji korisniku podatke na mail`,												
    "Settings.Users.Edit.Message.successCreate": `Korisnik je uspješno dodan.`,												
    "Settings.Users.Edit.Message.successUpdate": `Podaci o korisniku su uspješno spremljeni.`,												
    "Settings.Users.Edit.Message.errorUpdate": `Podaci o korisniku nisu uspješno spremljeni.`,												
    "Settings.Users.Edit.Message.errorUpdateMailExists": `Korisnik s tim emailom već postoji.`,												
    "Settings.Users.Delete.pageTitle": `Izbriši korisnika`,												
    "Settings.Users.Delete.questionYesNo": `Da li želite izbrisati korisnika {{userName}}?`,												
    "Settings.Users.Delete.successDelete": `Uspješno ste izbrisali korisnika {{userName}}.`,												
    "Settings.Users.Delete.errorDelete": `Korisnik {{userName}} nije izbrisan.`												
}
