import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { OfferionErrorMessage } from '../../shared/forms/validation/OfferionErrorMessage';
import { Currency, ProductModel } from '../../shared/models';
import { UnitItemModel, UnitsApi } from '../../services/UnitsApi';
import { ProductApi } from '../../services/ProductApi';
import { useGridActions } from '../../shared/grid/GridContextProvider';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup'
import { toastSuccess } from '../../shared/toastr';
import { CurrencyCode } from '../../shared/ui/currency';
import { CompanySettingsApi } from '../../services/CompanySettingsApi';
import { Overlay } from '../../shared/elements/Overlay';
import round from '../../shared/utils/round';
import { CurrencyField } from '../../shared/forms/CurrencyField';
import { NumberField } from '../../shared/forms/NumberField';
import { ImageWithAuthorization } from '../../shared/elements/ImageWithAuthorization';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';

export const ProductForm = (
    {
        initialValues,
        closeForm,
        groupId,
        currency
    }:
        {
            initialValues: ProductModel | null,
            closeForm: () => void,
            groupId?: number,
            currency: Currency | null
        }) => {


    const { t } = useOfferionTranslation();
    const [showAditionalInformation, setShowAditionalInformation] = useState(false);
    const [units, setUnits] = useState<UnitItemModel[] | null>();
    const gridActions = useGridActions();
    const [defaultVat, setDefaultVat] = useState<number | null>(null);
    const [isAccountingVat, setIsAccountingVat] = useState<boolean | null>(null);
    const [canEditPurchasePrice, setCanEditPurchasePrice] = useState<boolean>(false);
    const [showProductDimensionsInfo, setShowProductDimensionsInfo] = useState<boolean | null>(null);
    const history = useHistory();

    useEffect(() => {

        UnitsApi.get().then(setUnits);
    }, [])

    useEffect(() => {
        CompanySettingsApi.getSimpleCompanySettings().then(result => {
            setDefaultVat(result.vat);
            setCanEditPurchasePrice(result.canEditPurchasePrice);
            setShowProductDimensionsInfo(result.showProductDimensionsInfo)
            setIsAccountingVat(result.isAccountingVat);
        });
    }, [])

    if (!units || defaultVat == null || showProductDimensionsInfo == null)
        return null;

    const onSubmit = (product: ProductModel) => {
        return ProductApi.upsert(product);
    }

    const validationSchema = Yup.object()
        .shape({
            name: Yup.string()
                .required(t("Allaround.Validation.required", { fieldName: t("PriceList.EditProduct.product") })),
            salePrice: Yup.string()
                .nullable()
                .required(t("Allaround.Validation.required", { fieldName: t("PriceList.EditProduct.salesPrice") })),
            vatRate: Yup.string()
                .nullable()
                .required(t("Allaround.Validation.required", { fieldName: t("PriceList.EditProduct.VAT") })),
        });

    return <Formik validationSchema={validationSchema} initialValues={initialValues ? {
        ...initialValues,
        priceDifference: initialValues.salePrice && initialValues.purchasePrice
            ? round(((initialValues.salePrice - initialValues.purchasePrice) / initialValues.purchasePrice) * 100)
            : undefined,
    } : {
        id: 0,
        name: "",
        taxFree: false,
        salePrice: null,
        vatRate: defaultVat,
        code: "",
        unit: "",
        groupId: groupId,
        imagePath: ""
    }} onSubmit={onSubmit}>
        {formikProps => {

            const salePriceChange = (newValue: number | "") => {

                const product = formikProps.values;
                const salePrice = newValue != "" ? newValue : null;

                formikProps.setFieldValue("salePrice", salePrice);

                if (!!salePrice && (!!product.purchasePrice || !!product.priceDifference)) {
                    if (product.purchasePrice == null && product.priceDifference && product.priceDifference != -100) {
                        formikProps.setFieldValue("purchasePrice", round(salePrice / (100 + product.priceDifference) * 10000) / 100);
                    }
                    else {
                        if (product.purchasePrice == 0) {
                            formikProps.setFieldValue("priceDifference", 0);

                        }
                        else if (product.purchasePrice) {
                            const priceWithRebate = salePrice;
                            const priceDifference = ((priceWithRebate - product.purchasePrice) / product.purchasePrice) * 100;

                            formikProps.setFieldValue("priceDifference", round(priceDifference));
                        }
                    }
                }
            }

            const priceDifferenceChange = (newValue: number | "") => {
                const product = formikProps.values;
                const priceDifference = newValue != "" ? newValue : null;

                formikProps.setFieldValue("priceDifference", priceDifference);

                if (!!priceDifference && (!!product.salePrice || !!product.purchasePrice)) {
                    if (product.purchasePrice == null && product.salePrice && product.priceDifference != -100) {
                        formikProps.setFieldValue("purchasePrice", round(product.salePrice / (100 + priceDifference) * 10000) / 100);
                    }
                    else {
                        if (product.purchasePrice == 0) {
                            formikProps.setFieldValue("priceDifference", 0);
                        }
                        else if (product.purchasePrice) {
                            const priceWithRebate = product.purchasePrice * (1 + priceDifference / 100);

                            formikProps.setFieldValue("salePrice", round(priceWithRebate));
                        }
                    }
                }
            }

            const purchasePriceChange = (newValue: number | "") => {
                const product = formikProps.values;
                const purchasePrice = newValue != "" ? newValue : null;

                formikProps.setFieldValue("purchasePrice", purchasePrice);

                if (!!purchasePrice && (!!product.salePrice || !!product.priceDifference)) {
                    if (product.salePrice == null && product.priceDifference) {
                        formikProps.setFieldValue("salePrice", round(purchasePrice * (100 + product.priceDifference)) / 100);
                    }
                    else {
                        if (purchasePrice == 0) {
                            formikProps.setFieldValue("priceDifference", 0);
                        }
                        else if (product.salePrice) {
                            const priceWithRebate = product.salePrice;
                            product.priceDifference = ((priceWithRebate - purchasePrice) / purchasePrice) * 100;

                            formikProps.setFieldValue("priceDifference", round(product.priceDifference));
                        }
                    }
                }
            }

            return <Overlay>
                <Form>
                    <div>
                        <h1 className="overlay__title">{t(initialValues ? 'PriceList.EditProduct.pageTitleEdit' : 'PriceList.EditProduct.pageTitleNew')}</h1>
                        <button className="button overlay__close" onClick={closeForm}>
                            <span className="icono-cross"></span>
                        </button>

                        <div className="info__container">
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="name">{t('PriceList.EditProduct.product')}</label>
                                <div className="info__row-content">
                                    <Field as="textarea" id="name" name="name" rows={2} placeholder={t('PriceList.EditProduct.productPlaceholder')} />
                                    <OfferionErrorMessage name="name" />
                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="salePrice">{t('PriceList.EditProduct.salesPrice')}</label>
                                <div className="info__row__inner-half">
                                    <div>
                                        <Field
                                            component={CurrencyField}
                                            name="salePrice"
                                            placeholder={t('PriceList.EditProduct.salesPrice')}
                                            onChange={salePriceChange}
                                        />
                                        <span className="text-color mod-margin-left-4"><CurrencyCode currency={currency} /></span>
                                    </div>
                                    <OfferionErrorMessage name="salePrice" />
                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="purchasePrice">{t('PriceList.EditProduct.purchasePrice')}</label>
                                <div className="info__row__inner-half">
                                    <div>
                                        <Field id="purchasePrice"
                                            component={CurrencyField}
                                            name="purchasePrice"
                                            placeholder={t('PriceList.EditProduct.purchasePrice')}
                                            onChange={purchasePriceChange}
                                        />
                                        <span className="text-color mod-margin-left-4"><CurrencyCode currency={currency} /></span>
                                    </div>
                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="unit">{t('PriceList.EditProduct.measurementUnit')}</label>
                                <div className="info__row__inner-half">
                                    <div>
                                        <div className="select-container">
                                            <Field as="select" name="unit" className="select">
                                                <option></option>
                                                {units.map(u => <option value={u.name}>{u.name}</option>)}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="info__row clickable" onClick={() => setShowAditionalInformation(!showAditionalInformation)}>
                                <h1 className="table__cell__highlighted-text"><span className="icon icon--clickable icon__info"></span>{t('PriceList.EditProduct.additionalInformation')}</h1>

                                <span className="button button--gray right">
                                    <span className={showAditionalInformation ? "icon icon__up" : "icon icon__down-blue"}></span>
                                </span>
                            </div>

                            {showAditionalInformation && <div>

                                {canEditPurchasePrice && <><div className="info__row">
                                    <label className="info__row-name" htmlFor="priceDifference">{t('PriceList.EditProduct.priceDifference')}</label>
                                    <div className="info__row__inner-half">
                                        <div>
                                            <Field id="priceDifference"
                                                component={NumberField}
                                                placeholder={t('PriceList.EditProduct.priceDifference')}
                                                name="priceDifference"
                                                onChange={priceDifferenceChange}

                                            />
                                            <span className="text-color mod-margin-left-4">%</span>
                                        </div>
                                    </div>
                                </div>
                                </>}

                                <div className="info__row">
                                    <label className="info__row-name" htmlFor="code">{t('PriceList.EditProduct.productCode')}</label>
                                    <div className="info__row-content">
                                        <Field id="productCode" type="text" placeholder={t('PriceList.EditProduct.productCodePlaceholder')} name="code" />
                                    </div>
                                </div>

                                {isAccountingVat && <><div className="info__row">
                                    <label className="info__row-name" htmlFor="vatRate">{t('PriceList.EditProduct.VAT')}</label>
                                    <div className="info__row__inner-half">
                                        <div>
                                            <Field id="pdv" component={NumberField} placeholder={t('PriceList.EditProduct.VAT')} name="vatRate" />
                                            <span className="text-color mod-margin-left-4">%</span>
                                        </div>
                                        <OfferionErrorMessage name="vatRate" />
                                    </div>
                                </div>

                                    <div className="info__row" style={{ display: "flex", alignItems: "center" }}>
                                        <label className="info__row-content" style={{ flexGrow: 1 }} htmlFor="taxFree">{t('PriceList.EditProduct.taxFreeCheck')}</label>
                                        <div className="info__row-small">
                                            <div>
                                                <Field id="tax-free" type="checkbox" className="checkbox" name="taxFree" />
                                                <label htmlFor="tax-free" className="button button--gray checkbox-button right mod-margin-left-4">
                                                    <span className="checkbox-button__icon"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </>}

                                {showProductDimensionsInfo && <>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="width">{t('PriceList.EditProduct.width')}</label>
                                        <div className="info__row__inner-half">
                                            <div>
                                                <Field id="width"
                                                    component={NumberField}
                                                    placeholder={t('PriceList.EditProduct.width')}
                                                    name="width"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="height">{t('PriceList.EditProduct.height')}</label>
                                        <div className="info__row__inner-half">
                                            <div>
                                                <Field id="height"
                                                    component={NumberField}
                                                    placeholder={t('PriceList.EditProduct.height')}
                                                    name="height"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="depth">{t('PriceList.EditProduct.depth')}</label>
                                        <div className="info__row__inner-half">
                                            <div>
                                                <Field id="depth"
                                                    component={NumberField}
                                                    placeholder={t('PriceList.EditProduct.depth')}
                                                    name="depth"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </>}

                                <div className="info__row">

                                    {initialValues != null && formikProps.values.imagePath ? <>
                                        <label className="info__row-name" htmlFor="name">{t('PriceList.EditProduct.picture')}</label>
                                        <label className="button button--gray button--padded right" onClick={() => {
                                            ProductApi.setImage(initialValues.id, null, null).then(() => {
                                                formikProps.setFieldValue("imagePath", null);
                                            })
                                        }} >
                                            <span className="icon icon--small icon__upload-delete"></span>
                                            <span className="button-text">{t('Allaround.Picture.removePicture')}</span>
                                        </label>
                                        <ImageWithAuthorization className="mod-margin-top-8" src={initialValues.imagePath} /> </>
                                        :
                                        <>
                                            <label className="info__row-name" htmlFor="name">{t('PriceList.EditProduct.picture')}</label>
                                            <label className="button button--gray button--padded right" onClick={async () => {

                                                const errors = await formikProps.validateForm();

                                                if (Object.keys(errors).length)
                                                    return;


                                                formikProps.submitForm().then(({ id }: any) => {

                                                    history.push("/image-selection", {
                                                        productId: id
                                                    })
                                                })
                                            }} >
                                                <span className="icon icon--small icon__upload"></span>
                                                <span className="button-text">{t('Allaround.Picture.addPicture')}</span>
                                            </label>
                                        </>
                                    }
                                </div>

                            </div>}
                        </div>

                        <div className="button-group bottom-controls">
                            <button className="button button--gray button--padded" type="button" onClick={async () => {

                                const errors = await formikProps.validateForm();

                                if (Object.keys(errors).length)
                                    return;


                                formikProps.submitForm().then(() => {
                                    if (initialValues)
                                        toastSuccess('PriceList.EditProduct.Message.successUpdate')
                                    else
                                        toastSuccess('PriceList.EditProduct.Message.successCreate')

                                })
                                    .then(gridActions.refresh)
                                    .then(closeForm)
                            }}>
                                <span className="icon icon__check-green"></span>
                                <span className="button-text button-text--always-show">{t('Allaround.Button.save')}</span>
                            </button>
                            <button className="button button--gray button--padded" type="button" onClick={closeForm}>
                                <span className="icon icon--small icon__cross-red"></span>
                                <span className="button-text button-text--always-show">{t('Allaround.Button.cancel')}</span>
                            </button>
                        </div>

                        <br />
                    </div>
                </Form>
            </Overlay>
        }}
    </Formik >
}

export const AddProductButton = ({ currency, currentGroup }: { currency: Currency | null, currentGroup: number | null }) => {
    const { t } = useOfferionTranslation();

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);


    if (isOverlayVisible) {
        return (
            <div className="button-container-with-text float-left">
                <div className="button-container small-hide overlay__item overlay__item--bottom-left overlay__item--active mr0">
                    <button className="button button--gray button--padded overlay__button overlay__button--active">
                        <span className="icon icon__item"></span>
                        <span className="icon icon__plus"></span>
                    </button>

                    <ProductForm currency={currency} initialValues={null} closeForm={() => setIsOverlayVisible(false)} groupId={currentGroup || undefined} />
                </div>
                <span className="button-text mod-margin-left-4">{t('PriceList.Header.addProduct')}1</span>
            </div>
        );
    } else {
        return (
            <div className="button-container-with-text float-left">
                <div className="button-container mr0" onClick={() => setIsOverlayVisible(true)}>
                    <button className="button button--white button--padded">
                        <span className="icon icon icon__item"></span>
                        <span className="icon icon__plus"></span>
                    </button>
                </div>
                <span className="button-text mod-margin-left-4">{t('PriceList.Header.addProduct')}2</span>
            </div>
        );
    }
}
