import React from 'react'
import { Formik, Form, Field } from "formik"
import { CurrencyCode } from '../../../shared/ui/currency';
import { Currency } from '../../../shared/models';
import { ClientDiscountRow } from '../../../services/ClientDiscountsApi';
import * as Yup from 'yup';
import { OfferionErrorMessage } from '../../../shared/forms/validation/OfferionErrorMessage';
import { Overlay } from '../../../shared/elements/Overlay';
import { CurrencyField } from '../../../shared/forms/CurrencyField';
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation';
import { NumberField } from '../../../shared/forms/NumberField';

interface Props {
    selectedItem: ClientDiscountRow
    closeOverlay: () => void;
    onSubmit: (newItem: ClientDiscountRow) => void;
    currency: Currency;
}

export const EditItemOverlay = ({ selectedItem, closeOverlay, onSubmit, currency }: Props) => {

    const { t } = useOfferionTranslation();


    const schema = Yup.object().shape({
        discountPercent: Yup.number()
            .nullable()
            .notOneOf([0])
            .min(0)
            .max(100)
            .when([], (schema: Yup.NumberSchema) => {

                if (selectedItem.groupId)
                    return schema.required(t("Allaround.Validation.required", { fieldName: t("Clients.AgreedPrices.AddProductOrGroup.discount") }));

                return schema.test("T2", t('Clients.AgreedPrices.AddProductOrGroup.priceValidation', { discount: t("Clients.AgreedPrices.AddProductOrGroup.discount"), agreedSellPrice: t("Clients.AgreedPrices.AddProductOrGroup.agreedSellPrice") }), function (discountPecent) {
                    const salePrice = this.parent.salePrice;

                    return (discountPecent == undefined && salePrice != undefined) || (discountPecent != undefined && salePrice == undefined);
                })

            }),
        salePrice: Yup.number()
            .notOneOf([0])
            .nullable()
            .when([], (schema: Yup.NumberSchema) => {

                if (selectedItem.groupId)
                    return schema;

                return schema.test("T2", t('Clients.AgreedPrices.AddProductOrGroup.priceValidation', { discount: t("Clients.AgreedPrices.AddProductOrGroup.discount"), agreedSellPrice: t("Clients.AgreedPrices.AddProductOrGroup.agreedSellPrice") }), function (salePrice) {
                    const discountPecent = this.parent.discountPercent;

                    return (discountPecent == undefined && salePrice != undefined) || (discountPecent != undefined && salePrice == undefined);
                })
            })
    });

    return <span className="overlay__item overlay__item--right-middle">
        <Overlay>
            <h1 className="overlay__title">
                {t('Clients.AgreedPrices.Edit.pageTitle')}
            </h1>

            <button className="button overlay__close" onClick={closeOverlay}>
                <span className="icono-cross"></span>
            </button>

            <Formik validationSchema={schema} initialValues={selectedItem} onSubmit={onSubmit}>
                <Form noValidate={true}>
                    <div className="info__container">
                        <div className="info__row">
                            <label className="info__row-name">{selectedItem.productId ? t('Clients.AgreedPrices.Edit.product') : t('Clients.AgreedPrices.Edit.group')}</label>
                            <div className="info__row-content--extended">
                                <span className="info__row-name">{selectedItem.name}</span>
                            </div>
                        </div>
                        <div className="info__row">
                            <label className="info__row-name" htmlFor="updateProductDiscount">{t('Clients.AgreedPrices.Edit.discount')}</label>
                            <div className="info__row__inner-half">
                                <Field id="updateProductDiscount"
                                    component={NumberField}
                                    name="discountPercent"
                                    placeholder={t('Clients.AgreedPrices.Edit.discount')} />
                                <span className="text-color">%</span>
                            </div>
                            <div className="info__row-content">
                                <OfferionErrorMessage name="discountPercent" />
                            </div>
                        </div>
                        {selectedItem.productId ? <div className="info__row">
                            <label className="info__row-name" htmlFor="name">{t('Clients.AgreedPrices.Edit.agreedSellPrice')}</label>
                            <div className="info__row__inner-half">
                                <Field id="salesPrice"
                                    name="salePrice"
                                    component={CurrencyField}
                                    placeholder={t('Clients.AgreedPrices.Edit.agreedSellPricePlaceholder')}
                                />
                                <span className="text-color mod-margin-left-4"><CurrencyCode currency={currency} /></span>
                            </div>
                            <div className="info__row-content">
                                <OfferionErrorMessage name="salePrice" />
                            </div>
                        </div> : null}
                    </div>
                    <div className="button-group bottom-controls">
                        <button className="button button--gray button--padded" type="submit">
                            <span className="icon icon__check-green"></span>
                            <span className="button-text button-text--always-show">{t('Allaround.Button.save')}</span>
                        </button>
                        <button className="button button--gray button--padded" type="button" onClick={closeOverlay}>
                            <span className="icon icon--small icon__cross-red"></span>
                            <span className="button-text button-text--always-show">{t('Allaround.Button.cancel')}</span>
                        </button>
                    </div>
                </Form>
            </Formik>
        </Overlay>
    </span>
}