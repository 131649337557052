import React from 'react'
import { Formik, Field } from 'formik'

export const InlineCheckboxEdit = ({ currentValue, id, onSave, }:
    { id: string, currentValue?: boolean, onSave: ({ newValue }: { newValue: boolean }) => void }) => {

    return <Formik enableReinitialize={true} initialValues={{ newValue: currentValue || false }} onSubmit={(values) => {
        onSave(values);
    }}>
        {
            props => {
                return <>
                    <Field id={id} type="checkbox" className="checkbox"
                        name="newValue"
                        onChange={(e: any) => {
                            props.setFieldValue("newValue", e.target.checked);

                            props.submitForm();
                        }} />

                    <label htmlFor={id}
                        className="button button--gray checkbox-button">
                        <span className="checkbox-button__icon"></span>
                    </label>

                </>
            }
        }

    </Formik>
}