import React, { useEffect, useState, ReactNode } from 'react'
import { Formik, Form, Field } from 'formik'
import { OfferionErrorMessage } from '../../../shared/forms/validation/OfferionErrorMessage';
import { UserModel } from '../../../shared/models';
import { ListingApi, CompanySalePlaceRow } from "../../../services/ListingApi"
import * as Yup from 'yup';
import { UsersApi } from '../../../services/UsersApi';
import { validateOib } from '../../../shared/utils/validators'
import { FiscalizationApi } from '../../../services/FiscalizationApi';
import { Overlay } from '../../../shared/elements/Overlay';
import { OfferionTooltip } from '../../../shared/tooltip';
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation';

interface Props {
    closeOverlay: () => void;
    user?: UserModel | null;
    onSubmit: (user: UserModel & { newPassword: string, sendEmail: boolean }) => void;
}

export const UserForm = (props: Props) => {

    const { t } = useOfferionTranslation();

    const [salePlaceOptions, setCompanySalePlaceOptions] = useState<JSX.Element[] | null>(null)
    const [isFiscalizationActive, setIsFiscalizationActive] = useState<boolean | null>(null);
    const [newUserSalePlaceId, setNewUserSalePlaceId] = useState<number | null>(null);

    useEffect(() => {
        ListingApi.getList<CompanySalePlaceRow>("CompanySalePlacesModel", 1, 100, []).then(result => {
            const options = result.data.map(o => <option key={o.id} value={o.id}>{o.name}</option>);

            setCompanySalePlaceOptions(options);
            setNewUserSalePlaceId(result.data.filter(x => x.isPrimarySalePlace)[0].id)
        });
    }, []);

    useEffect(() => {
        FiscalizationApi.getSettings().then(r => {
            setIsFiscalizationActive(r.isFiscalizationActive);
        });
    }, [])

    if (!salePlaceOptions || isFiscalizationActive == null || !newUserSalePlaceId)
        return null;

    let initialEmail = props.user ? props.user.email : ""

    const validationSchema = Yup.object<{ newValue: string }>()
        .shape({
            firstName: Yup.string()
                .required(t("Allaround.Validation.required", { fieldName: t("Settings.Users.Edit.name") })),
            lastName: Yup.string()
                .required(t("Allaround.Validation.required", { fieldName: t("Settings.Users.Edit.surname") })),
            email: Yup.string()
                .email()
                .required(t("Allaround.Validation.required", { fieldName: t("Settings.Users.Edit.loginMail") }))
                .test('Check duplicate email', t("Allaround.Validation.mailExists"), async  (val) => {
                    if (val == null || val == undefined)
                        return false;

                    if (initialEmail == val)
                        return true;

                    const res = await UsersApi.isEmailUnique(val);

                    return res;
                }),
            oib: Yup.string()
                .nullable()
                .test("ValidateOib", "Neispravan Oib", (oib) => {

                    if (!isFiscalizationActive)
                        return true;

                    if (!oib)
                        return false;

                    return validateOib(oib)
                }),
            newPassword: Yup.string()
                .when([], (schema: Yup.StringSchema) => {

                    if (props.user)
                        return schema.nullable().min(6, t("Allaround.Validation.minLength", { fieldName: t("Login.password"), minLength: 6 }))

                    return schema
                        .required(t("Allaround.Validation.required", { fieldName: t("Settings.Users.Edit.password") }))
                        .min(6, t("Allaround.Validation.minLength", { fieldName: t("Settings.Users.Edit.password"), minLength: 6 }));
                })
        });


    return <Overlay>
        <h1 className="overlay__title">
            {t(props.user ? 'Settings.Users.Edit.pageTitleEdit' : 'Settings.Users.Edit.pageTitleNew')}
        </h1>

        <button className="button overlay__close" onClick={props.closeOverlay}>
            <span className="icono-cross"></span>
        </button>

        <Formik initialValues={props.user ? {
            ...props.user,

            newPassword: "",
            sendEmail: false
        } : {
            canCreateReceipts: true,
            canChangeSalePlace: salePlaceOptions.length > 1,
            code: "",
            companySalePlaceId: newUserSalePlaceId,
            email: "",
            firstName: "",
            id: 0,
            isAdministrator: false,
            lastName: "",
            oib: "",
            title: "",
            newPassword: "",
            sendEmail: false
        }}
            validationSchema={validationSchema}
            onSubmit={props.onSubmit}>

            {formikProps => <Form noValidate={true} autoComplete="off">
                <div>
                    <div className="info__container">
                        <div className="info__row">
                            <label className="info__row-name" htmlFor="updateFirstName">{t('Settings.Users.Edit.name')}</label>
                            <div className="info__row-content">
                                <Field id="updateFirstName" type="text"
                                    placeholder={t('Settings.Users.Edit.namePlaceholder')}
                                    name="firstName" />
                                <OfferionErrorMessage name="firstName" />
                            </div>
                        </div>

                        <div className="info__row">
                            <label className="info__row-name" htmlFor="updateLastName">{t('Settings.Users.Edit.surname')}</label>
                            <div className="info__row-content">
                                <Field id="updateLastName" type="text"
                                    placeholder={t('Settings.Users.Edit.surnamePlaceholder')}
                                    name="lastName" />
                                <OfferionErrorMessage name="lastName" />
                            </div>
                        </div>

                        <div className="info__row">
                            <label className="info__row-name" htmlFor="updateUserOIB">{t('Settings.Users.Edit.identificationNumber')}</label>
                            <div className="info__row-content">
                                <Field id="updateUserOIB" type="text"
                                    placeholder={t('Settings.Users.Edit.identificationNumberPlaceholder')}
                                    name="oib" />
                                <OfferionErrorMessage name="oib" />
                            </div>
                        </div>

                        <div className="info__row">
                            <label className="info__row-name" htmlFor="updateSellingPlaces">{t('Settings.Users.Edit.sellingPlace')}
                            </label>
                            <div className="info__row-content">
                                <div className="info__select-container select-container">
                                    <Field id="updateSellingPlaces"
                                        as="select"
                                        className="select"
                                        name="companySalePlaceId">
                                        {salePlaceOptions}
                                    </Field>

                                </div>
                            </div>
                        </div>

                        <div className="info__row">
                            <label className="info__row-name" htmlFor="userTitle">{t('Settings.Users.Edit.personTitle')}</label>
                            <div className="info__row-content">
                                <Field id="userTitle" type="text"
                                    placeholder={t('Settings.Users.Edit.personTitlePlaceholder')}
                                    name="title" />
                            </div>
                        </div>

                        <div className="info__row">
                            <label className="info__row-name" htmlFor="updateUserLoginEmail">{t('Settings.Users.Edit.loginMail')}</label>
                            <div className="info__row-content">
                                <Field id="updateUserLoginEmail" type="email"
                                    placeholder={t('Settings.Users.Edit.loginMailPlaceholder')}
                                    name="email"
                                    required />

                                <OfferionErrorMessage name="email" />
                            </div>
                        </div>

                        <div className="info__row">
                            <label className="info__row-name" htmlFor="updateUserPassword">{t('Settings.Users.Edit.password')}</label>
                            <div className="info__row-content">
                                <Field id="updateUserPassword" type="password"
                                    name="newPassword"
                                />
                                <OfferionErrorMessage name="newPassword" />
                            </div>
                        </div>

                        <div className="info__row">
                            <label className="info__row-name" htmlFor="userType">{t('Settings.Users.Edit.userLabel')}</label>
                            <div className="info__row-content">
                                <Field id="userType" type="text"
                                    placeholder={t('Settings.Users.Edit.userLabelPlaceholder')}
                                    name="code" />
                            </div>
                        </div>

                        <div className="info__row" style={{ display: "flex", alignItems: "center" }}>
                            <label className="info__row-content" style={{ flexGrow: 1 }} htmlFor="updateUserIsAdministrator">{t('Settings.Users.Edit.userIsAdmin')}
                                <OfferionTooltip placement="right" titleKey='Settings.Users.Edit.userIsAdmin' bodyKey="Settings.Users.Edit.userIsAdminInfo" />
                            </label>
                            <div className="info__row-small" style={{ marginRight: "16px" }}>
                                <Field id="updateUserIsAdministrator" type="checkbox"
                                    className="checkbox-button--no-label"
                                    onChange={(e: any) => {
                                        const selected: boolean = e.target.checked;

                                        const canChangeSalePlace = selected && salePlaceOptions.length == 1
                                            ? true
                                            : selected;

                                        formikProps.setValues({
                                            ...formikProps.values,
                                            isAdministrator: selected,
                                            canChangeSalePlace
                                        })
                                    }}
                                    name="isAdministrator" />
                                <label htmlFor="updateUserIsAdministrator"
                                    className="button button--gray checkbox-button right">
                                    <span className="checkbox-button__icon"></span>
                                </label>
                            </div>
                        </div>

                        <div className="info__row" style={{ display: "flex", alignItems: "center" }}>
                            <label className="info__row-content checkbox-button--no-label" style={{ flexGrow: 1 }}
                                htmlFor="canChangeSalePlace">{t('Settings.Users.Edit.salesPlacesChangesAllowed')}</label>
                            <div className="info__row-small" style={{ marginRight: "16px" }}>
                                <Field id="canChangeSalePlace" type="checkbox"
                                    className="checkbox-button--no-label"
                                    name="canChangeSalePlace" />
                                <label htmlFor="canChangeSalePlace"
                                    className="button button--gray checkbox-button right">
                                    <span className="checkbox-button__icon"></span>
                                </label>
                            </div>
                        </div>

                        {!formikProps.values.isAdministrator && <div className="info__row" style={{ display: "flex", alignItems: "center" }}>
                            <label className="info__row-content checkbox-button--no-label" style={{ flexGrow: 1 }}
                                htmlFor="isInvoiceAllowed">{t('Settings.Users.Edit.invoicesAllowed')}</label>
                            <div className="info__row-small" style={{ marginRight: "16px" }}>
                                <Field id="isInvoiceAllowed" type="checkbox"
                                    className="checkbox-button--no-label"
                                    name="canCreateReceipts" />
                                <label htmlFor="isInvoiceAllowed"
                                    className="button button--gray checkbox-button right">
                                    <span className="checkbox-button__icon"></span>
                                </label>
                            </div>
                        </div>}

                        <div className="info__row" style={{ display: "flex", alignItems: "center" }}>
                            <label className="info__row-content" style={{ flexGrow: 1 }} htmlFor="updateIsSaveAndSend">{t('Settings.Users.Edit.saveAndSend')}</label>
                            <div className="info__row-small" style={{ marginRight: "16px" }}>
                                <Field id="updateIsSaveAndSend" type="checkbox"
                                    className="checkbox-button--no-label"
                                    name="sendEmail" />
                                <label htmlFor="updateIsSaveAndSend"
                                    className="button button--gray checkbox-button right">
                                    <span className="checkbox-button__icon"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="button-group bottom-controls">
                        <button className="button button--gray button--padded" type="submit">
                            <span className="icon icon__check-green"></span>
                            <span className="button-text button-text--always-show">{t('Allaround.Button.save')}</span>
                        </button>
                        <button className="button button--gray button--padded" type="button" onClick={props.closeOverlay}>
                            <span className="icon icon--small icon__cross-red"></span>
                            <span className="button-text button-text--always-show">{t('Allaround.Button.cancel')}</span>
                        </button>
                    </div>

                    <br />
                </div>
            </Form>}
        </Formik>

    </Overlay>

}