export const english = {												
    "Allaround.Button.save": `Save`,												
    "Allaround.Button.accept": `Accept`,												
    "Allaround.Button.select": `Select`,												
    "Allaround.Button.cancel": `Cancel`,												
    "Allaround.Button.modify": `Edit`,												
    "Allaround.Button.delete": `Delete`,												
    "Allaround.Button.yes": `Yes`,												
    "Allaround.Button.no": `No`,												
    "Allaround.Button.send": `Send`,												
    "Allaround.Button.print": `Print`,												
    "Allaround.Button.download": `Download`,												
    "Allaround.Button.search": `Search`,												
    "Allaround.selectFilePlaceHolder": `File is not selected`,												
    "Allaround.Popup.warningTitle": `Warning`,												
    "Allaround.Popup.deleteTitle": `Are you sure?`,												
    "Allaround.Question.delete": `Do you really want to delete`,												
    "Allaround.Message.successDelete": `You have successfully deleted`,												
    "Allaround.Message.packageLimitReached": `You have reached the allowed number of {{offersOrInvoicesGenitiv}} in this month. Package upgrade terms you can check <a href=\"http://www.izracunko.com/cijene#nadoplata\" target=\"_blank\">here (click)</a>.`,												
    "Allaround.Message.offersGenitiv": `offers`,												
    "Allaround.Message.offersGenitiv:lawyer": `cost lists`,												
    "Allaround.Message.invoicesGenitiv": `invoices`,												
    "Allaround.Message.offersAndInvoicesGenitiv": `offers and invoices`,												
    "Allaround.Message.offersAndInvoicesGenitiv:lawyer": `cost lists and invoices`,												
    "Allaround.Message.packageThresholdReached": `You can still create <b>{{numOffersOrInvoicesLeft}} {{offerOrInvoiceSingular}}</b> in this month. You have created <b>{{numOffersOrInvoices}}</b> out of <b>{{numOffersOrInvoicesInPackage}}</b> in your package. Package upgrade terms you can check <a href=\"http://www.izracunko.com/cijene#nadoplata\" target=\"_blank\">here (click)</a>.`,												
    "Allaround.Message.packageThresholdReachedTwofold": `You can still create <b>{{numOffersLeft}} {{offerSingular}}</b> and <b>{{numInvoicesLeft}} {{invoiceSingular}}</b> in this month. You have created <b>{{numOffers}}</b> out of <b>{{numOffersInPackage}}</b> {{offerPlural}} and <b>{{numInvoces}}</b> out of <b>{{numInvoicesInPackage}}</b> {{invoicePlural}} in your package. Package upgrade terms you can check <a href=\"http://www.izracunko.com/cijene#nadoplata\" target=\"_blank\">here (click)</a>.`,												
    "Allaround.Message.offerAkuzativ": `offer`,												
    "Allaround.Message.offerAkuzativ:lawyer": `cost list`,												
    "Allaround.Message.offerPlural1": `offers`,												
    "Allaround.Message.offerPlural1:lawyer": `cost lists`,												
    "Allaround.Message.offerPlural2": `offers`,												
    "Allaround.Message.offerPlural2:lawyer": `cost lists`,												
    "Allaround.Message.invoiceAkuzativ": `invoice`,												
    "Allaround.Message.invoicePlural": `invoices`,												
    "Allaround.Message.subscriptionExpiresSoon": `Until the end of your subscription period you have <b>{{numDays}} {{days}}</b> left. To continue using our services as before, please renew your subscription. To check the terms <a href=\"http://www.izracunko.com/cijene\" target=\"_blank\">click here</a>.`,												
    "Allaround.Message.day": `day`,												
    "Allaround.Message.dayPlural": `days`,												
    "Allaround.Message.subscriptionExpired": `Your subscription expired on <b>{{packageExpirationDate}}</b>. You have been automatically transferred to our free package, in which you can create 2 invoices and 3 offers in a month, and in which you can't fiscalize invoices. Package upgrade terms you can check <a href=\"http://www.izracunko.com/cijene#nadoplata\" target=\"_blank\">here (click)</a>.`,												
    "Allaround.Message.invoicesNotFiscalized": `You have unfiscalized invoiced and they should be 2 days after their creation date. If you want to fiscalize them, select print. <a href=\"{{link}}\">Click here</a> to find all such invoices.`,												
    "Allaround.Message.unknownError": `Error has occured and task requested has failed.`,												
    "Allaround.Message.AddressAndOIBNotChanged": `In order to finish program setup, you need to enter your tax number and address. You can do it in <a href=\"{{link}}\">settings</a>.`,												
    "Allaround.Message.OIBNotChanged": `In order to finish program setup, you need to enter your tax number. You can do it in <a href=\"{{link}}\">settings</a>.`,												
    "Allaround.Message.AddressNotChanged": `In order to finish program setup, you need to enter your address. You can do it in <a href=\"{{link}}\">settings</a>.`,												
    "Allaround.CompanyType.basic": `Basic`,												
    "Allaround.CompanyType.lawyer": `Attorney`,												
    "Allaround.CompanyType.windowMaker": `Windows and Doors Seller/Manufacturer`,												
    "Allaround.CompanyType.renter": `Renter`,												
    "Allaround.CompanyType.other": `Other`,												
    "Allaround.Currency.EUR.title": `Euro`,												
    "Allaround.Currency.EUR.shortcode": `€`,												
    "Allaround.Currency.HRK.title": `Croatian kuna`,												
    "Allaround.Currency.HRK.shortcode": `kn`,												
    "Allaround.Currency.USD.title": `American dolar`,												
    "Allaround.Currency.USD.shortcode": `$`,												
    "Allaround.Currency.GBP.title": `British pound`,												
    "Allaround.Currency.GBP.shortcode": `£`,												
    "Allaround.Currency.BAM.title": `Convertible mark BiH`,												
    "Allaround.Currency.BAM.shortcode": `KM`,												
    "Allaround.Currency.CHF.title": `Swiss franc`,												
    "Allaround.Currency.CHF.shortcode": `Fr`,												
    "Allaround.Currency.RSD.title": `Serbian dinar`,												
    "Allaround.Currency.RSD.shortcode": `din`,												
    "Allaround.Language.InvoiceOrOffer.printSettings": `From print settings`,												
    "Allaround.Language.InvoiceOrOffer.cs-cz": `Czech`,												
    "Allaround.Language.InvoiceOrOffer.de-de": `German`,												
    "Allaround.Language.InvoiceOrOffer.en-gb": `English`,												
    "Allaround.Language.InvoiceOrOffer.es-es": `Spanish`,												
    "Allaround.Language.InvoiceOrOffer.fr-fr": `French`,												
    "Allaround.Language.InvoiceOrOffer.hr-hr": `Croatian`,												
    "Allaround.Language.InvoiceOrOffer.hu-hu": `Hungarian`,												
    "Allaround.Language.InvoiceOrOffer.it-it": `Italian`,												
    "Allaround.Language.InvoiceOrOffer.pl-pl": `Polish`,												
    "Allaround.Language.InvoiceOrOffer.sr-Latn": `Serbian`,												
    "Allaround.Language.Interface.hr-hr": `Hrvatski`,												
    "Allaround.Language.Interface.en-en": `English`,												
    "Allaround.PaymentType.bankAccount.title": `Bank account`,												
    "Allaround.PaymentType.bankAccount.shortcode": `T`,												
    "Allaround.PaymentType.cash.title": `Cash`,												
    "Allaround.PaymentType.cash.shortcode": `G`,												
    "Allaround.PaymentType.check.title": `Check`,												
    "Allaround.PaymentType.check.shortcode": `C`,												
    "Allaround.PaymentType.creditCard.title": `Card`,												
    "Allaround.PaymentType.creditCard.shortcode": `K`,												
    "Allaround.PaymentType.other.title": `Other`,												
    "Allaround.PaymentType.other.shortcode": `O`,												
    "Allaround.Picture.title": `Picture`,												
    "Allaround.Picture.addPicture": `Add picture`,												
    "Allaround.Picture.removePicture": `Remove picture`,												
    "Allaround.Status.draft": `Draft`,												
    "Allaround.Status.offerSent": `Sent`,												
    "Allaround.Status.invoiceSent": `Sent`,												
    "Allaround.Status.offerAccepted": `Approved`,												
    "Allaround.Status.offerDeclined": `Declined`,												
    "Allaround.Status.invoicePastDue": `Past Due`,												
    "Allaround.Status.invoicePaid": `Paid`,												
    "Allaround.Status.invoiceCancelled": `Cancelled`,												
    "Allaround.Validation.required": `{{fieldName}} is required.`,												
    "Allaround.Validation.requiredSelect": `{{fieldName}} must be selected.`,												
    "Allaround.Validation.maxValue": `{{fieldName}} can't be greater than {{maxValue}}.`,												
    "Allaround.Validation.maxLength": `{{fieldName}} can't have more than {{maxLength}} characters.`,												
    "Allaround.Validation.minLength": `{{fieldName}} must have at least {{minLength}} characters.`,												
    "Allaround.Validation.exactLength": `{{fieldName}} must have {{exactLength}} characters.`,												
    "Allaround.Validation.number": `{{fieldName}} must be a number.`,												
    "Allaround.Validation.positiveNumber": `{{fieldName}} must be a positive number`,												
    "Allaround.Validation.alphanumeric": `{{fieldName}} must have only letters of english alphabet or numbers.`,												
    "Allaround.Validation.paymentDateInFuture": `{{fieldName}} must be in future.`,												
    "Allaround.Validation.incorrectEntry": `{{fieldName}} is not correct.`,												
    "Allaround.Validation.incorrectVAT": `VAT must be between 0 and 30%.`,												
    "Allaround.Validation.discountNegative": `Discount must be positive.`,												
    "Allaround.Validation.discountMoreThan100": `Discount can't be larger than 100%.`,												
    "Allaround.Validation.discountCantBe100": `Discount can't be 100% when working with overall price. If you want to enter 100% discount, you need to change the way price is calculated in settings.`,												
    "Allaround.Validation.invalidPriceWithoutDiscount": `Price without discount must be larger than overall price (and discount must be positive).`,												
    "Allaround.Validation.date": `Date entered is not correct. Date must be in a form 31.01.2020. 14:60`,												
    "Allaround.Validation.mail": `Entered value is not an e-mail address.`,												
    "Allaround.Validation.mailExists": `That e-mail address already exists.`,												
    "Allaround.Validation.password": `Password must have at least 6 characters, of which one character must be small letter, one capital letter and one number.`,												
    "Allaround.Validation.passwordsDontMatch": `Entered passwords are not the same.`,												
    "Allaround.Validation.maxFileSize": `File is too big. Maximum size is {{maxFileSize}} kb.`,												
    "Allaround.Validation.incorrectFileType": `This document type is not allowed.Allowed document types are: {{allowedFileTypes}}.`,												
    "Allaround.Validation.invalidAnswer": `The answer is not correct.`,												
    "Allaround.Validation.countryNotCroatia": `Since your address is not in Croatia, the invoice can't be fiscalized. In order to do so, please change address to 'Hrvatska' inside settings.`,												
    "Allaround.Validation.currencyNotHRK": `Since your address is in Croatia, all invoice items must be in HRK. In order to do so, please change invoice currency inside invoice settings.`,												
    "Allaround.Validation.Fiscalization.generalIntro": `Invoice can't be fiscalized`,												
    "Allaround.Validation.Fiscalization.certificateExpired": `because the certificate has expired.`,												
    "Allaround.Validation.Fiscalization.OIBsDontMatch": `because Tax ID doesn't match the Tax ID inside certificate.`,												
    "Allaround.Validation.Fiscalization.tooLate": `because invoice date is older than obligatory date for sending to the tax authorities (2 days). Contact your accountant for advice.`,												
    "Allaround.Validation.Fiscalization.noRights": `because your user rights don't allow you to. This invoice needs to be fiscalized before printing or mail, so please contact your company administrator in order to do so.`,												
    "Allaround.Validation.Fiscalization.settingsIntroMessage": `Invoice can't be fiscalized until you enter inside settings`,												
    "Allaround.Validation.Fiscalization.missingCompanyAddress": `company address`,												
    "Allaround.Validation.Fiscalization.missingCompanyCity": `company city`,												
    "Allaround.Validation.Fiscalization.missingCompanyTaxID": `company Tax ID`,												
    "Allaround.Validation.Fiscalization.missingPersonalIDs": `all operators' personal IDs`,												
    "Allaround.Validation.Fiscalization.zipCodeNotNumeric": `zip code with only numbers`,												
    "Allaround.DropDown.Other": `Other`,												
    "Allaround.DropDown.Select": `Select`,												
    "Clients.Header.pageTitle": `Clients`,												
    "Clients.Header.newClient": `New Client`,												
    "Clients.Side.searchTitle": `Search`,												
    "Clients.Side.searchPlaceholder": `Part of the name`,												
    "Clients.Side.searchButton": `Search`,												
    "Clients.Table.Header.title": `Client List`,												
    "Clients.Table.emptyMessage": `You don't have any clients yet.<br /><br />You can add a client by pressing the button 'New Client'.`,												
    "Clients.Table.emptySearchMessage": `No such client was found.<br /><br />You may either change the search options or create a new client.`,												
    "Clients.Table.Header.numberOfClients": `Number of Clients`,												
    "Clients.Table.Header.perPage": `per page`,												
    "Clients.Table.Header.name": `Name`,												
    "Clients.Table.Header.city": `Place`,												
    "Clients.Table.Header.mail": `Mail`,												
    "Clients.Table.ItemMenu.edit": `Edit`,												
    "Clients.Table.ItemMenu.delete": `Delete`,												
    "Clients.Table.ItemMenu.agreedPrices": `Agreed Terms`,												
    "Clients.Edit.pageTitleEdit": `Edit Client`,												
    "Clients.Edit.pageTitleNew": `New Client`,												
    "Clients.Edit.name": `Name`,												
    "Clients.Edit.namePlaceholder": `Enter client name`,												
    "Clients.Edit.address": `Street and number`,												
    "Clients.Edit.addressPlaceholder": `Enter address`,												
    "Clients.Edit.city": `Place`,												
    "Clients.Edit.cityPlaceholder": `Enter place`,												
    "Clients.Edit.zipCode": `Zip code`,												
    "Clients.Edit.zipCodePlaceholder": `Enter zip code`,												
    "Clients.Edit.companyNumber": `Tax number`,												
    "Clients.Edit.companyNumberPlaceholder": `Enter client tax number`,												
    "Clients.Edit.email": `Mail`,												
    "Clients.Edit.emailPlaceholder": `Enter mail address`,												
    "Clients.Edit.additionalInformation": `Additional Info`,												
    "Clients.Edit.country": `Country`,												
    "Clients.Edit.countryPlaceholder": `Enter country`,												
    "Clients.Edit.phone": `Phone`,												
    "Clients.Edit.phonePlaceholder": `Enter phone number`,												
    "Clients.Edit.cellphone": `Cell Phone`,												
    "Clients.Edit.cellphonePlaceholder": `Enter cell phone number`,												
    "Clients.Edit.fax": `Fax`,												
    "Clients.Edit.faxPlaceholder": `Enter fax number`,												
    "Clients.Edit.clientRemark": `Client Note (internal)`,												
    "Clients.Edit.clientRemarkPlaceholder": `Enter internal client note`,												
    "Clients.Edit.printRemark": `Print Note`,												
    "Clients.Edit.printRemarkPlaceholder": `If you enter something here, it will be printed next to the client on offer or invoice.`,												
    "Clients.Edit.printRemarkPlaceholder:lawyer": `If you enter something here, it will be printed next to the client on cost list or invoice.`,												
    "Clients.Edit.Message.successUpdate": `Client data is successfully saved.`,												
    "Clients.Edit.Message.errorUpdate": `Error in updating client data.`,												
    "Clients.Edit.Message.successCreate": `Client successfully created.`,												
    "Clients.Edit.Message.errorCreate": `Error in creating client.`,												
    "Clients.Delete.pageTitle": `Delete Client`,												
    "Clients.Delete.questionYesNo": `Do you really want to delete client {{clientName}}?`,												
    "Clients.Delete.Message.successDelete": `You have successfully deleted client {{clientName}}.`,												
    "Clients.Delete.Message.cancelDelete": `You have decided not to delete a client.`,												
    "Clients.Delete.Message.errorDelete": `Client is not successfully deleted.`,												
    "Clients.AgreedPrices.Header.pageTitle": `Agreed Terms`,												
    "Clients.AgreedPrices.Table.emptyMessage": `You don't have any agreed prices for this client yet.<br /><br />You can add agreed price by pressing the button 'Select from price list'.`,												
    "Clients.AgreedPrices.Header.selectFromPricelist": `Select from price list`,												
    "Clients.AgreedPrices.Header.noProductsMessage": `You can't select product from price list since you don't have any products entered.`,												
    "Clients.AgreedPrices.Side.conditionsTitle": `General terms`,												
    "Clients.AgreedPrices.Side.paymentDelay": `Payment delay in days`,												
    "Clients.AgreedPrices.Side.generalDiscount": `General discount`,												
    "Clients.AgreedPrices.Side.deleteAllPrices": `Delete all agreed prices`,												
    "Clients.AgreedPrices.Table.Header.title": `Terms for {{clientName}}`,												
    "Clients.AgreedPrices.Table.Header.perPage": `per page`,												
    "Clients.AgreedPrices.Table.Header.name": `Product/Group title`,												
    "Clients.AgreedPrices.Table.Header.discount": `Discount`,												
    "Clients.AgreedPrices.Table.Header.price": `Price`,												
    "Clients.AgreedPrices.Table.ItemMenu.edit": `Edit`,												
    "Clients.AgreedPrices.Table.ItemMenu.delete": `Delete`,												
    "Clients.AgreedPrices.Select.pageTitle": `Select`,												
    "Clients.AgreedPrices.Select.title": `Title`,												
    "Clients.AgreedPrices.Select.price": `Price`,												
    "Clients.AgreedPrices.Select.select": `Select`,												
    "Clients.AgreedPrices.Edit.pageTitle": `Product/Group Terms`,												
    "Clients.AgreedPrices.Edit.product": `Product`,												
    "Clients.AgreedPrices.Edit.group": `Group`,												
    "Clients.AgreedPrices.Edit.discount": `Discount`,												
    "Clients.AgreedPrices.Edit.discountPlaceHolder": `Enter agreed discount`,												
    "Clients.AgreedPrices.Edit.agreedSellPrice": `Agreed sell price`,												
    "Clients.AgreedPrices.Edit.agreedSellPricePlaceholder": `Enter agreed price`,												
    "Clients.AgreedPrices.Edit.priceFromPriceList": `Sell price from the price list is {{priceFromPriceList}}`,												
    "Clients.AgreedPrices.Edit.Product.Message.successSave": `Terms for product {{productName}} are successfully saved.`,												
    "Clients.AgreedPrices.Edit.Product.Message.errorSave": `Terms for product {{productName}} are not successfully saved.`,												
    "Clients.AgreedPrices.Edit.Group.Message.successSave": `Terms for group {{groupName}} are successfully saved.`,												
    "Clients.AgreedPrices.Edit.Group.Message.errorSave": `Terms for group {{groupName}} are not successfully saved.`,												
    "Clients.AgreedPrices.Delete.pageTitle": `Delete Agreed Price`,												
    "Clients.AgreedPrices.Delete.Product.questionYesNo": `Do you really want to delete agreed price for product '{{productName}}'?`,												
    "Clients.AgreedPrices.Delete.Product.successMessage": `Terms for product {{productName}} are successfully deleted.`,												
    "Clients.AgreedPrices.Delete.Product.errorMessage": `Terms for product {{productName}} are not successfully deleted.`,												
    "Clients.AgreedPrices.Delete.Group.questionYesNo": `Do you really want to delete agreed terms for group '{{groupName}}' and everything in it?`,												
    "Clients.AgreedPrices.Delete.Group.successMessage": `Agreed terms for group {{groupName}} and everything in it are successfully deleted.`,												
    "Clients.AgreedPrices.Delete.Group.errorMessage": `Agreed terms for group {{groupName}} and everything in it are not successfully deleted.`,												
    "Clients.AgreedPrices.AddProductOrGroup.pageTitle": `Select from price list`,												
    "Clients.AgreedPrices.AddProductOrGroup.search": `Search`,												
    "Clients.AgreedPrices.AddProductOrGroup.pagePlaceholder": `Part of the product/group name`,												
    "Clients.AgreedPrices.AddProductOrGroup.discount": `Discount`,												
    "Clients.AgreedPrices.AddProductOrGroup.discountPlaceholder": `Agreed discount`,												
    "Clients.AgreedPrices.AddProductOrGroup.agreedSellPrice": `Agreed sell price`,												
    "Clients.AgreedPrices.AddProductOrGroup.agreedSellPricePlaceholder": `Enter agreed price`,												
    "Clients.AgreedPrices.AddProductOrGroup.priceValidation": `{{discount}} or {{agreedSellPrice}} must be entered. `,												
    "Clients.AgreedPrices.AddProductOrGroup.Message.successAdd": `New terms are successfully saved.`,												
    "Clients.AgreedPrices.AddProductOrGroup.Message.errorAdd": `New terms are not successfully saved.`,												
    "Clients.AgreedPrices.Side.GeneralTerms.Message.successSave": `Agreed terms are successfully saved.`,												
    "Clients.AgreedPrices.Side.GeneralTerms.Message.errorSave": `Agreed terms are not successfully saved.`,												
    "Clients.AgreedPrices.Side.Delete.pageTitle": `Delete Agreed Terms`,												
    "Clients.AgreedPrices.Side.Delete.questionYesNo": `Do you really want to delete agreed terms for this client?`,												
    "Clients.AgreedPrices.Side.Delete.Message.successDelete": `You have successfully deleted all agreed terms.`,												
    "Clients.AgreedPrices.Side.Delete.Message.errorDelete": `Agreed terms are not successfully deleted.`,												
    "Dashboard.pageTitle": `Dashboard`,												
    "Dashboard.amount": `Amount`,												
    "Dashboard.client": `Client`,												
    "Dashboard.date": `Date`,												
    "Dashboard.invoiceNumber": `Invoice number`,												
    "Dashboard.offerNumber": `Offer number`,												
    "Dashboard.offerNumber:lawyer": `Cost list number`,												
    "Dashboard.lastFiveInvoices": `Last 5 Invoices`,												
    "Dashboard.lastFiveOffers": `Last 5 Offers`,												
    "Dashboard.lastFiveOffers:lawyer": `Last 5 Cost Lists`,												
    "Dashboard.newInvoiceButton": `New Invoice`,												
    "Dashboard.newOfferButton": `New Offer`,												
    "Dashboard.newOfferButton:lawyer": `New Cost List`,												
    "Dashboard.searchButton": `Search`,												
    "Dashboard.searchPlaceholder": `Enter what you search for`,												
    "Dashboard.viewAllButton": `View All`,												
    "Dashboard.Chart.TopProducts.title": `5 most sold products`,												
    "Dashboard.Chart.TopProducts.chartTitle": `Products by Sales No`,												
    "Dashboard.Chart.TopProductsPerIncome.title": `5 most successful products`,												
    "Dashboard.Chart.TopProductsPerIncome.chartTitle": `Products by Revenue`,												
    "Dashboard.Chart.TopOfferedProducts.title": `5 most sought for products`,												
    "Dashboard.Chart.TopOfferedProducts.chartTitle": `Products by Search`,												
    "Dashboard.Chart.IncomePerMonth.title": `Monthly Sales`,												
    "Dashboard.Chart.SalesPerMonth.title": `Monthly Revenue`,												
    "Dashboard.Chart.Month": `Month`,												
    "Dashboard.Chart.Income": `Revenue`,												
    "Dashboard.Chart.Month.january": `January`,												
    "Dashboard.Chart.Month.february": `February`,												
    "Dashboard.Chart.Month.march": `March`,												
    "Dashboard.Chart.Month.april": `April`,												
    "Dashboard.Chart.Month.may": `May`,												
    "Dashboard.Chart.Month.june": `June`,												
    "Dashboard.Chart.Month.july": `July`,												
    "Dashboard.Chart.Month.august": `August`,												
    "Dashboard.Chart.Month.september": `September`,												
    "Dashboard.Chart.Month.october": `October`,												
    "Dashboard.Chart.Month.november": `November`,												
    "Dashboard.Chart.Month.december": `December`,												
    "Footer.copyright": `© 2010 - {{currentYear}} Izračunko`,												
    "Footer.privacyPolicy": `<a href=\"https://www.izracunko.com/politika-privatnosti/politika-privatnosti/\">Privacy Policy</a>`,												
    "Footer.help": `<a href=\"https://izracunko.com/help\">Help</a>`,												
    "Footer.termsOfService": `<a href=\"https://www.izracunko.com/uvjeti-koristenja/\">Terms and Conditions</a>`,												
    "ForgotPassword.pageTitle": `Forgot password?`,												
    "ForgotPassword.popupErrorWrongMail": `User with that email has not been found.`,												
    "ForgotPassword.resetPasswordButton": `Send instructions`,												
    "ForgotPassword.Message.InstructionsSent": `If you have entered email address which we have in the system, password change instructions have been sent to {{email}}. You may change the password during next hour.`,												
    "ForgotPassword.showSuccessMessage": `You have successfully sent the request`,												
    "ForgotPassword.showErrorMessage": `Error trying to send mail. Wrong email address.`,												
    "Header.settings": `Settings`,												
    "Header.search": `Search`,												
    "Header.clients": `Clients`,												
    "Header.invoices": `Invoices`,												
    "Header.offers": `Offers`,												
    "Header.offers:lawyer": `Cost Lists`,												
    "Header.priceList": `Price List`,												
    "Header.reports": `Reports`,												
    "Header.signOut": `Log Off`,												
    "Header.templates": `Templates`,												
    "Invoice.Header.pageTitleNewInvoice": `New Invoice`,												
    "Invoice.Header.pageTitleEditInvoice": `Edit Invoice`,												
    "Invoice.Header.pageTitleNewOffer": `New Offer`,												
    "Invoice.Header.pageTitleNewOffer:lawyer": `New Cost List`,												
    "Invoice.Header.pageTitleEditOffer": `Edit Offer`,												
    "Invoice.Header.pageTitleEditOffer:lawyer": `Edit Cost List`,												
    "Invoice.Header.pageTitleNewTemplate": `New Template`,												
    "Invoice.Header.pageTitleEditTemplate": `Edit Template`,												
    "Invoice.Header.CancelledInvoice.warningMessage": `This invoice has been cancelled and can't be changed nor deleted.`,												
    "Invoice.Header.FiscalizedInvoice.warningMessage": `This invoice has been fiscalized and can't be changed nor deleted.`,												
    "Invoice.Header.VATIsNowOff.warningMessage": `At the time this document (or the document it was created from) was made, you were in VAT system and therefore all document items are with VAT.`,												
    "Invoice.Header.VATIsNowOn.warningMessage": `At the time this document (or the document it was created from) was made, you were out of VAT system and therefore you can't enter VAT fot its items.`,												
    "Invoice.Header.TotalPriceIsNowOn.warningMessage": `At the time this document (or the document it was created from) was made, entering Overall Price was on and therefore all document items still work in this way.`,												
    "Invoice.Header.TotalPriceIsNowOff.warningMessage": `At the time this document (or the document it was created from) was made, entering Overall Price was off and therefore all document items still work in this way.`,												
    "Invoice.Header.pageHeaderChange": `Add or replace invoices and offers header`,												
    "Invoice.Header.pageHeaderChange:lawyer": `Add or replace invoices and cost lists header`,												
    "Invoice.Header.pageFooterChange": `Add or replace invoices and offers footer`,												
    "Invoice.Header.pageFooterChange:lawyer": `Add or replace invoices and cost lists footer`,												
    "Invoice.Header.paymentCodeActivation": `Activate or deactivate payment code print`,												
    "Invoice.Header.Menu.print": `Print`,												
    "Invoice.Header.Menu.sendMail": `Send Mail`,												
    "Invoice.Header.Menu.sendEInvoice": `Send eInvoice`,												
    "Invoice.Header.Menu.changePrices": `Change All Prices`,												
    "Invoice.Header.Menu.makeTemplate": `Create Template`,												
    "Invoice.Header.Menu.delete": `Delete`,												
    "Invoice.Header.Menu.cancelInvoice": `Cancel Invoice`,												
    "Invoice.Header.Menu.invoiceSettings": `Invoice Settings`,												
    "Invoice.Header.Menu.log": `Document History`,												
    "Invoice.Header.Menu.offerSettings": `Offer Settings`,												
    "Invoice.Header.Menu.offerSettings:lawyer": `Cost List Settings`,												
    "Invoice.Header.Menu.templateSettings": `Template Settings`,												
    "Invoice.Header.Menu.createOffer": `Create Offer`,												
    "Invoice.Header.Menu.createOffer:lawyer": `Create Cost List`,												
    "Invoice.Header.Menu.createInvoice": `Create Invoice`,												
    "Invoice.Header.Menu.Message.warningNoItems": `You need to add at least one item first.`,												
    "Invoice.Header.Menu.Message.requiresSaveForNewDocuments": `You need to save the document first.`,												
    "Invoice.Side.productsTitle": `Products`,												
    "Invoice.Side.productsDescription": `Most frequent products. You may add them by clicking `,												
    "Invoice.Side.actionsTitle": `Quick Actions`,												
    "Invoice.Side.actionsDescription": `Most frequent actions. The rest are available by clicking at `,												
    "Invoice.Side.print": `Print`,												
    "Invoice.Side.sendMail": `Send Mail`,												
    "Invoice.Side.changePrices": `Change All Prices`,												
    "Invoice.Side.makeTemplate": `Create Template`,												
    "Invoice.Side.delete": `Delete`,												
    "Invoice.Side.cancelInvoice": `Cancel Invoice`,												
    "Invoice.Side.changeStatus": `Change Status`,												
    "Invoice.Side.createOffer": `Create Offer`,												
    "Invoice.Side.createOffer:lawyer": `Create Cost List`,												
    "Invoice.Side.createInvoice": `Create Invoice`,												
    "Invoice.Table.headerNone": `Add your header here`,												
    "Invoice.Table.footerNone": `Add your footer here`,												
    "Invoice.Table.Client.title": `Client`,												
    "Invoice.Table.Client.buttonNew": `New Client`,												
    "Invoice.Table.Client.noClientPlaceholder": `Find using part of the client name`,												
    "Invoice.Table.Client.createClient": `Create Client`,												
    "Invoice.Table.Client.Menu.edit": `Edit Client`,												
    "Invoice.Table.Client.Menu.select": `Select Client`,												
    "Invoice.Table.Client.Menu.new": `New Client`,												
    "Invoice.Table.Client.Menu.remove": `Remove Client`,												
    "Invoice.Table.Date.title": `Date and Time`,												
    "Invoice.Table.DateDue.title": `Due Date`,												
    "Invoice.Table.Payment.title": `Payment Method`,												
    "Invoice.Table.Currency.title": `Client Currency`,												
    "Invoice.Table.Operator.title": `Operator`,												
    "Invoice.Table.DocumentLanguage.title": `Operator`,												
    "Invoice.Table.OfferNumber.infoTitle": `Offer Numbers`,												
    "Invoice.Table.OfferNumber.infoTitle:lawyer": `Cost List Numbers`,												
    "Invoice.Table.OfferNumber.infoText": `Offer numbers are automatically generated starting from 00001. You may change how offer number is generated by removing leading zeroes and adding anything afterwards. Keep in mind that only left part will be automatically generated. For example if you set the offer number to 23-01-2023, next offer number will automatically be 24-01-2023.`,												
    "Invoice.Table.OfferNumber.infoText:lawyer": `Cost List numbers are automatically generated starting from 00001. You may change how offer number is generated by removing leading zeroes and adding anything afterwards. Keep in mind that only left part will be automatically generated. For example if you set the cost list number to 23-01-2023, next cost list number will automatically be 24-01-2023.`,												
    "Invoice.Table.InvoiceNumber.infoTitle": `Invoice Numbers`,												
    "Invoice.Table.InvoiceNumber.infoText": `Invoice numbers are automatically generated starting from 00001. You may change how invoice number is generated by removing leading zeroes and adding anything afterwards. Keep in mind that only left part will be automatically generated. For example if you set the invoice number to 23-01-2023, next invoice number will automatically be 24-01-2023.`,												
    "Invoice.Table.InvoiceNumber.offerNumberPlaceholder": `Enter offer number`,												
    "Invoice.Table.InvoiceNumber.offerNumberPlaceholder:lawyer": `Enter cost list number`,												
    "Invoice.Table.InvoiceNumber.invoiceNumberPlaceholder": `Enter invoice number`,												
    "Invoice.Table.InvoiceNumber.duplicateSuffix": `-(! same numberj)`,												
    "Invoice.Table.InvoiceNumber.DuplicateSuffix.warningTitle": `Same invoice number`,												
    "Invoice.Table.InvoiceNumber.DuplicateSuffix.warningText": `You already have this invoice number because invoices before last one have larger numbers (this can happen for instance if you have set the time of some invoice before the last one).<br />If you work in Croatia then the law requires your invoices to have ascending numbers by time, so try to correct their order (first delete this invoice and find the invoice with this number), if you can't, contact us for help.<br />If you don't work in Croatia, check if your country has similar law and if not, just delete this '-(! same number)' from invoice number.`,												
    "Invoice.Table.TemplateName.title": `Template Name`,												
    "Invoice.Table.TemplateName.noTitlePlaceholder": `Template for product ABC`,												
    "Invoice.Table.TemplateItems.title": `Template items`,												
    "Invoice.Table.Header.rowNumber": `#`,												
    "Invoice.Table.Header.name": `Name`,												
    "Invoice.Table.Header.amount": `Quantity`,												
    "Invoice.Table.Header.price": `Price`,												
    "Invoice.Table.Header.discount": `Discount`,												
    "Invoice.Table.Header.total": `Total`,												
    "Invoice.Table.Item.titlePlaceholder": `Enter your product/service name.`,												
    "Invoice.Table.Item.descriptionPlaceholder": `Here you can enter your product/service description.`,												
    "Invoice.Table.ItemMenu.edit": `Edit Item`,												
    "Invoice.Table.ItemMenu.delete": `Delete Item`,												
    "Invoice.Table.buttonNew": `New Item`,												
    "Invoice.Table.buttonNewFromPricelist": `Add item from Price list`,												
    "Invoice.Table.buttonChangeAllPrices": `Change All Prices`,												
    "Invoice.Table.Totals.totalWithoutVAT": `Total Price`,												
    "Invoice.Table.Totals.vat": `VAT {{vatPercentage}}`,												
    "Invoice.Table.Totals.taxFree": `Tax free`,												
    "Invoice.Table.Totals.total": `Grand Total`,												
    "Invoice.Table.Footer.remark": `Remark`,												
    "Invoice.Table.Footer.remarkOffer": `Remark on Offer`,												
    "Invoice.Table.Footer.remarkOffer:lawyer": `Remark on Cost List`,												
    "Invoice.Table.Footer.remarkInvoice": `Remark on Invoice`,												
    "Invoice.Table.Footer.remarkPlaceholder": `Here you can enter a note which will be visible on invoice. For example terms & conditions, advance payment, …  or a something you are obliged to put by law.`,												
    "Invoice.Table.Footer.remarkPlaceholderOffer": `Here you can enter a note which will be visible on offer. For example terms & conditions, advance payment, …  or a something you are obliged to put by law.`,												
    "Invoice.Table.Footer.remarkPlaceholderOffer:lawyer": `Here you can enter a note which will be visible on cost list. For example terms & conditions, advance payment, …  or a something you are obliged to put by law.`,												
    "Invoice.Table.Footer.remarkText": `Advance payment 30% before beginning of work. The rest of payment is upon delivery. Delivery is due 15 days upon advance payment. Offer is valid for 15 days.`,												
    "Invoice.Table.Footer.remarkText:lawyer": `Our fees are based on approved legal fees.`,												
    "Invoice.Table.Footer.personResponsibleTitle": `Person responsible`,												
    "Invoice.Table.Footer.personResponsibleSignatureChange": `Add or replace signature`,												
    "Invoice.Settings.pageTitleInvoice": `Invoice Settings`,												
    "Invoice.Settings.pageTitleOffer": `Offer Settings`,												
    "Invoice.Settings.pageTitleOffer:lawyer": `Cost List Settings`,												
    "Invoice.Settings.pageTitleTemplate": `Template Settings`,												
    "Invoice.Settings.currencyTitle": `Currency Settings`,												
    "Invoice.Settings.invoiceCurrency": `Invoice Currency`,												
    "Invoice.Settings.offerCurrency": `Offer Currency`,												
    "Invoice.Settings.offerCurrency:lawyer": `Cost List Currency`,												
    "Invoice.Settings.templateCurrency": `Template Currency`,												
    "Invoice.Settings.recalculateItems": `Recalculate all items into new currency?`,												
    "Invoice.Settings.clientCurrency": `Client Currency`,												
    "Invoice.Settings.fixedHrkEurRate": `1 EUR = 7,53450 kn (fixed conversion rate)`,												
    "Invoice.Settings.clientCurrencyUnavailable": `You will be able to change client currency once you are finished with changing main currency.`,												
    "Invoice.Settings.updatingDocument": `Updating document with current data`,												
    "Invoice.Settings.UpdatingDocument.infoTitle": `Why the document hasn't got current data?`,												
    "Invoice.Settings.UpdatingDocument.infoText": `Each document is saved with the data at the moment of its creation. That is necessary if for example your address changes, all invoices before the change must have the old address and after the change must have the new address.<br />Exactly the same way is how the changes to document looks are treated, which can be made within Print Settings.<br />There are cases when you want to see the changes immediately. For example if you have changed the address and forgotten to update it in the system. In such cases, pushing the button below will insert these changs into the document. If you have already sent the document with the old data to the client, you should send the new document as well.<br /><br />Fiscalized or cancelled invoices can't be changed.`,												
    "Invoice.Settings.updateDocumentActive": `Update document with current data (contact, print settings)`,												
    "Invoice.Settings.updateDocumentNotActive": `Document has current settings`,												
    "Invoice.Settings.updateDocumentNotActiveDisallowed": `Document update is not allowed`,												
    "Invoice.Settings.languageTitle": `Language`,												
    "Invoice.Settings.documentLanguage": `Document Language`,												
    "Invoice.Settings.Message.successUpdate": `{{pageTitle}} saved.`,												
    "Invoice.Settings.Message.successUpdateWithData": `{{pageTitle}} saved and document has current settings data.`,												
    "Invoice.Settings.Message.errorUpdate": `Error trying to update item.`,												
    "Invoice.Edit.pageTitle": `Edit Item`,												
    "Invoice.Edit.itemName": `Item Name`,												
    "Invoice.Edit.itemNamePlaceholder": `Enter item name`,												
    "Invoice.Edit.itemCode": `Item Code`,												
    "Invoice.Edit.itemCodePlaceholder": `Product/Service code`,												
    "Invoice.Edit.totalPrice": `Overall Price`,												
    "Invoice.Edit.totalPricePlaceholder": `Price paid by the customer (all inclusive)`,												
    "Invoice.Edit.rememberTotalPrice": `Remember current overall price and calculate discount from it.`,												
    "Invoice.Edit.basicPrice": `Basic Price`,												
    "Invoice.Edit.basicPricePlaceholder": `Enter basic price`,												
    "Invoice.Edit.salesPrice": `Sales Price`,												
    "Invoice.Edit.quantity": `Quantity`,												
    "Invoice.Edit.pricePerUnit": `Unit price:`,												
    "Invoice.Edit.additionalInformation": `Additional Info`,												
    "Invoice.Edit.price": `Price`,												
    "Invoice.Edit.measurementUnit": `Measurement Unit`,												
    "Invoice.Edit.width": `Width`,												
    "Invoice.Edit.widthPlaceholder": `Product width`,												
    "Invoice.Edit.height": `Height`,												
    "Invoice.Edit.heightPlaceholder": `Product height`,												
    "Invoice.Edit.depth": `Depth`,												
    "Invoice.Edit.depthPlaceholder": `Product depth`,												
    "Invoice.Edit.description": `Description`,												
    "Invoice.Edit.descriptionPlaceholder": `Edit description`,												
    "Invoice.Edit.discount": `Discount {{discountPercentage}}`,												
    "Invoice.Edit.discountPrice": `Price with discount:`,												
    "Invoice.Edit.priceWithoutDiscount": `Price without discount`,												
    "Invoice.Edit.vat": `VAT {{vatPercentage}}`,												
    "Invoice.Edit.priceWithVAT": `Price with VAT:`,												
    "Invoice.Edit.itemTaxFree": `This product/service is tax free.`,												
    "Invoice.Edit.purchasePrice": `Purchase Price`,												
    "Invoice.Edit.priceDifference": `Price Difference`,												
    "Invoice.Edit.income": `Profit:`,												
    "Invoice.Edit.totalWithoutVAT": `Total:`,												
    "Invoice.Edit.totalVAT": `VAT {{vatPercentage}}`,												
    "Invoice.Edit.totalWithVAT": `Grand Total`,												
    "Invoice.Edit.picture": `Product Picture`,												
    "Invoice.Edit.addPicture": `Add picture`,												
    "Invoice.Edit.removePicture": `Remove picture`,												
    "Invoice.Edit.buttonSave": `Save`,												
    "Invoice.Edit.buttonCancel": `Cancel`,												
    "Invoice.Edit.Message.SuccessUpdate": `Item saved.`,												
    "Invoice.Edit.Message.ErrorUpdate": `Error trying to update item.`,												
    "Invoice.Edit.Message.SuccessCreate": `Item successfully created.`,												
    "Invoice.Edit.Message.ErrorCreate": `Error trying to save item.`,												
    "Invoice.Delete.pageTitle": `Delete Item`,												
    "Invoice.Delete.questionYesNo": `Do you really want to delete {{itemName}}?`,												
    "Invoice.Delete.Message.SuccessDelete": `You have successfully deleted {{itemName}}.`,												
    "Invoice.Delete.Message.CancelDelete": `You have cancelled item deletion.`,												
    "Invoice.Delete.Message.ErrorDelete": `Item was not successfully deleted.`,												
    "Invoice.Save.Message.successUpdate": `Invoice successfully saved.`,												
    "Invoice.Save.Message.errorUpdate": `Error trying to save invoice.`,												
    "Invoice.Save.Message.successCreate": `Invoice successfully created.`,												
    "Invoice.Save.Message.errorCreate": `Error trying to create invoice.`,												
    "Invoice.SaveOffer.Message.successUpdate": `Offer successfully saved.`,												
    "Invoice.SaveOffer.Message.successUpdate:lawyer": `Cost list successfully saved.`,												
    "Invoice.SaveOffer.Message.errorUpdate": `Error trying to save offer.`,												
    "Invoice.SaveOffer.Message.errorUpdate:lawyer": `Error trying to save cost list.`,												
    "Invoice.SaveOffer.Message.successCreate": `Offer successfully created.`,												
    "Invoice.SaveOffer.Message.successCreate:lawyer": `Cost list successfully created.`,												
    "Invoice.SaveOffer.Message.errorCreate": `Error trying to create offer.`,												
    "Invoice.SaveOffer.Message.errorCreate:lawyer": `Error trying to create cost list.`,												
    "Invoice.SaveTemplate.Message.successUpdate": `Template successfully saved.`,												
    "Invoice.SaveTemplate.Message.errorUpdate": `Error trying to save template.`,												
    "Invoice.SaveTemplate.Message.successCreate": `Template successfully created.`,												
    "Invoice.SaveTemplate.Message.errorCreate": `Error trying to create template.`,												
    "Invoice.Cancel.Message.success": `Invoice changes are not saved.`,												
    "Invoice.AddProduct.pageTitle": `Add item from price list`,												
    "Invoice.AddProduct.messageProductExists": `This product/service already exists in the invoice and you can't add it again. You can change the quantity of the product/service which is already in the invoice.`,												
    "Invoice.AddProduct.messageProductExistsOffer": `This product/service already exists in the offer and you can't add it again. You can change the quantity of the product/service which is already in the offer.`,												
    "Invoice.AddProduct.messageProductExistsOffer:lawyer": `This service already exists in the cost list and you can't add it again. You can change the quantity of the service which is already in the offer.`,												
    "Invoice.AddProduct.Message.AgreedPrice.infoTitle": `Agreed price`,												
    "Invoice.AddProduct.Message.AgreedPrice.infoText": `The amount is different from the price in price list, because you have agreed to be so for this client.`,												
    "Invoice.CancelInvoice.pageTitle": `Cancel Invoice`,												
    "Invoice.CancelInvoice.invoiceToBeCancelled": `Invoice to be cancelled`,												
    "Invoice.CancelInvoice.questionYesNo": `Do you really want to cancel invoice {{invoiceNumber}}?`,												
    "Invoice.CancelInvoice.questionInfoTitle": `Invoice Cancellation`,												
    "Invoice.CancelInvoice.questionInfo": `Once you cancel invoice, you won't be able to modify it. New invoice will be created with all items as the invoice you are cancelling, but with negative quantities. Therefore these two invoices have zero sum and invoice cancellation is fulfilled.`,												
    "Invoice.Cancelnvoice.Message.SuccessDelete": `You have successfully cancelled invoice.`,												
    "Invoice.CancelInvoice.Message.CancelDelete": `You have cancelled invoice cancellation.`,												
    "Invoice.ChangeAllPrices.pageTitle": `Change All Prices`,												
    "Invoice.ChangeAllPrices.new": `New`,												
    "Invoice.ChangeAllPrices.current": `Current`,												
    "Invoice.ChangeAllPrices.profit": `Profit:`,												
    "Invoice.ChangeAllPrices.total": `Total:`,												
    "Invoice.ChangeAllPrices.enableVATChange": `Enable VAT change`,												
    "Invoice.ChangeAllPrices.changeVAT": `Change VAT for all items (0-30%)`,												
    "Invoice.ChangeStatus.pageTitle": `Change Status`,												
    "Invoice.ChangeStatus.status": `Status`,												
    "Invoice.DeleteInvoice.pageTitle": `Delete Invoice`,												
    "Invoice.DeleteInvoice.questionYesNo": `Do you really want to delete invoice {{invoiceNumber}}?`,												
    "Invoice.DeleteInvoice.Message.SuccessDelete": `You have successfully deleted invoice.`,												
    "Invoice.DeleteInvoice.Message.CancelDelete": `You have cancelled invoice deletion.`,												
    "Invoice.DeleteInvoice.Message.ErrorDelete": `Invoice was not successfully deleted.`,												
    "Invoice.DeleteOffer.pageTitle": `Delete Offer`,												
    "Invoice.DeleteOffer.pageTitle:lawyer": `Delete Cost List`,												
    "Invoice.DeleteOffer.questionYesNo": `Do you really want to delete offer {{offerNumber}}?`,												
    "Invoice.DeleteOffer.questionYesNo:lawyer": `Do you really want to delete cost list {{offerNumber}}?`,												
    "Invoice.DeleteOffer.Message.successDelete": `You have successfully deleted offer.`,												
    "Invoice.DeleteOffer.Message.successDelete:lawyer": `You have successfully deleted cost list.`,												
    "Invoice.DeleteOffer.Message.cancelDelete": `You have cancelled offer deletion.`,												
    "Invoice.DeleteOffer.Message.cancelDelete:lawyer": `You have cancelled cost list deletion.`,												
    "Invoice.DeleteOffer.Message.errorDelete": `Offer was not successfully deleted.`,												
    "Invoice.DeleteOffer.Message.errorDelete:lawyer": `Cost list was not successfully deleted.`,												
    "Invoice.DeleteTemplate.pageTitle": `Delete Template`,												
    "Invoice.DeleteTemplate.questionYesNo": `Do you really want to delete template {{templateName}}?`,												
    "Invoice.DeleteTemplate.Message.successDelete": `You have successfully deleted template.`,												
    "Invoice.DeleteTemplate.Message.cancelDelete": `You have cancelled template deletion.`,												
    "Invoice.DeleteTemplate.Message.errorDelete": `Template was not successfully deleted.`,												
    "Invoice.Log.pageTitle": `Document History`,												
    "Invoice.Log.documentCreated": `Created`,												
    "Invoice.Log.documentSent": `Sent to {{mailAddress}}`,												
    "Invoice.Log.documentSentAsEInvoice": `Sent as eInvoice to {{mailAddress}}`,												
    "Invoice.Log.StatusChanged": `Status changed to '{{status}}'`,												
    "Invoice.Log.invoiceCreated": `Invoice was created from the offer {{offerNumber}}`,												
    "Invoice.Log.fiscalized": `Fiscalized`,												
    "Invoice.Log.System": `System`,												
    "Invoice.Print.invoice": `Invoice`,												
    "Invoice.Print.offer": `Offer`,												
    "Invoice.Print.offer:lawyer": `Cost List`,												
    "Invoice.Print.Fiscalisation.pageTitle": `Print Invoice (fiscalization)`,												
    "Invoice.Print.Fiscalisation.questionYesNo": `Invoice must be fiscalized before printing or sending. After successful fiscalization, invoice can't be changed or deleted. Do you want to fiscalize this invoice?`,												
    "Invoice.Print.Fiscalisation.printDontFiscalize": `Print without fiscalization`,												
    "Invoice.Print.Fiscalisation.messageTooLate": `Invoice date is older than obligatory date for sending to the tax authorities (2 days). This invoice can't be fiscalized. Contact your accountant for advice.`,												
    "Invoice.Print.Fiscalisation.messageNoRights": `This invoice needs to be fiscalized before printing or mail. Your user rights don't allow you to do so, please contact your company administrator.`,												
    "Invoice.Print.Fiscalisation.messageSuccess": `Invoice successfully fiscalized.`,												
    "Invoice.Send.pageTitleInvoice": `Send Invoice`,												
    "Invoice.Send.pageTitleOffer": `Send Offer`,												
    "Invoice.Send.pageTitleOffer:lawyer": `Send Cost List`,												
    "Invoice.Send.invoiceAttached": `Invoice attached`,												
    "Invoice.Send.offerAttached": `Offer attached`,												
    "Invoice.Send.offerAttached:lawyer": `Cost List attached`,												
    "Invoice.Send.email": `Email`,												
    "Invoice.Send.emailPlaceholder": `example@gmail.com`,												
    "Invoice.Send.additionalRecipients": `Additional recipients mail(s) (cc)`,												
    "Invoice.Send.additionalRecipientsInfoTitle": `Additional recipients`,												
    "Invoice.Send.additionalRecipientsInfo": `If you want to send mail to multiple email addresses, you need to separate them with comma.`,												
    "Invoice.Send.additionalRecipientsPlaceholder": `example@gmail.com, example2@gmail.com`,												
    "Invoice.Send.messageTitle": `Invoice`,												
    "Invoice.Send.messageTitleOffer": `Offer`,												
    "Invoice.Send.messageTitleOffer:lawyer": `Cost List`,												
    "Invoice.Send.message": `Email message`,												
    "Invoice.Send.messagePlaceholder": `Dear Sir or Madam, \r\n\r\ninvoice for deliveries made is in the attachment. \r\n\r\nRegards, \r\n\r\n Izračunko`,												
    "Invoice.Send.messagePlaceholderOffer": `Dear Sir or Madam, \r\n\r\nour offer is in the attachment. \r\n\r\nRegards, \r\n\r\n Izračunko`,												
    "Invoice.Send.messagePlaceholderOffer:lawyer": `Dear Sir or Madam, \r\n\r\nour cost list is in the attachment. \r\n\r\nRegards, \r\n\r\n Izračunko`,												
    "Invoice.Send.sendCopyToYourselfYesNo": `Send copy to yourself at {{userMail}}`,												
    "Invoice.Send.fiscalizeYesNo": `Fiscalize invoice before sending`,												
    "Invoice.Send.messageSuccess": `Mail successfully sent.`,												
    "Invoice.Send.messageFail": `Mail wasn't successfully sent.`,												
    "Invoice.SendEInvoice.pageTitleSend": `Send eInvoice`,												
    "Invoice.SendEInvoice.pageTitleDownload": `Download eInvoice`,												
    "Invoice.SendEInvoice.Message.noCompanyData": `In order to send eInvoices, within settings you must enter tax ID, contact email and full address (street, street number, city, zip code and country). `,												
    "Invoice.SendEInvoice.Message.noClientData": `In order to send eInvoices, you must select client and enter its tax ID, contact email and full address (street, street number, city, zip code and country). `,												
    "Invoice.SendEInvoice.pageTitleDownload.infoTitle": `How to send eInvoice?`,												
    "Invoice.SendEInvoice.pageTitleDownload.infoText": `If you want to send eInvoices directly from our system, you need to register with external service that we use - 'Moj eRačun' and enter their login data inside eInvoice settings. Please contact us at info@izracunko.com, so we can send you instructions how to register.<br />If you want to send eInvoices using another service, you can do it by downloading eInvoice below and later transfer it into another service.`,												
    "Invoice.SendEInvoice.deliveryDate": `Delivery Date`,												
    "Invoice.SendEInvoice.invoiceProcess": `Invoice Process Type`,												
    "Invoice.SendEInvoice.InvoiceProcess.p1": `P1 - Invoicing of deliveries of goods and services against purchase orders, based on a contract`,												
    "Invoice.SendEInvoice.InvoiceProcess.p2": `P2 - Invoicing deliveries of goods and services based on a contract`,												
    "Invoice.SendEInvoice.InvoiceProcess.p3": `P3 - Invoicing the delivery of an incidental purchase order`,												
    "Invoice.SendEInvoice.InvoiceProcess.p4": `P4 - Pre-payment`,												
    "Invoice.SendEInvoice.InvoiceProcess.p5": `P5 - Spot payment`,												
    "Invoice.SendEInvoice.InvoiceProcess.p6": `P6 - Payment in advance of delivery`,												
    "Invoice.SendEInvoice.InvoiceProcess.p7": `P7 - Invoices with references to a despatch advice`,												
    "Invoice.SendEInvoice.InvoiceProcess.p8": `P8 - Invoices with references to a despatch advice and a receiving advice`,												
    "Invoice.SendEInvoice.InvoiceProcess.p9": `P9 - Credit notes or invoices with negative amounts, issued for a variety of reasons including the return of empty packaging`,												
    "Invoice.SendEInvoice.InvoiceProcess.p10": `P10 - Corrective invoicing`,												
    "Invoice.SendEInvoice.InvoiceProcess.p11": `P11 - Partial and final invoicing`,												
    "Invoice.SendEInvoice.InvoiceProcess.p12": `P12 - Self billing`,												
    "Invoice.SendEInvoice.purchaseOrderNum": `Purchase Order Number`,												
    "Invoice.SendEInvoice.globalExemptionReason": `Global Tax Exemption Reason`,												
    "Invoice.SendEInvoice.isTaxExemptionReasonGlobal": `Tax exemption reason is the same for all items`,												
    "Invoice.SendEInvoice.exemptionReasonFor": `{{itemName}} razlog oslobođenja od poreza `,												
    "Invoice.SendEInvoice.itemExemptionReason": `Item Tax Exemption Reason`,												
    "Invoice.SendEInvoice.downloadAfterSendYesNo": `Download eInvoice as XML after send`,												
    "Invoice.SendEInvoice.fiscalizeYesNo": `Fiscalize invoice before sending/downloading`,												
    "Invoice.CreateTemplate.pageTitle": `Enter template name`,												
    "Invoice.CreateTemplate.templateTitle": `Name`,												
    "Invoice.CreateTemplate.templateTitlePlaceholder": `Invoice/offer for product ABC`,												
    "Invoice.CreateTemplate.templateTitlePlaceholder:lawyer": `Invoice/cost list for service ABC`,												
    "Invoice.CreateTemplate.saveClientYesNo": `Save client into template`,												
    "Invoice.CreateTemplate.Message.successCreate": `Template successfully created.`,												
    "Invoice.CreateTemplate.Message.errorCreate": `Template wasn't successfully created.`,												
    "Invoices.Header.pageTitle": `Invoices`,												
    "Invoices.Header.newInvoice": `New Invoice`,												
    "Invoices.Header.reports": `Reports`,												
    "Invoices.Side.searchTitle": `Search`,												
    "Invoices.Side.clientPlaceholder": `Client (enter part of the name)`,												
    "Invoices.Side.invoiceNumberPlaceholder": `Invoice number (part of the number)`,												
    "Invoices.Side.day": `Day (all)`,												
    "Invoices.Side.month": `Month (all)`,												
    "Invoices.Side.year": `Year (all)`,												
    "Invoices.Side.statusAll": `Status (all)`,												
    "Invoices.Side.paymentAndFiscalization": `Payment and Fiscalization (all)`,												
    "Invoices.Side.paymentOnly": `Payment (all)`,												
    "Invoices.Side.fiscalizationNotDone": `Not fiscalized`,												
    "Invoices.Side.buttonSearch": `Search`,												
    "Invoices.Table.Header.title": `Invoice List`,												
    "Invoices.Table.emptyMessage": `You have no invoices yet. <br /><br />Invoices are usually issued upon work finish, but may be issued before that for advance payments.`,												
    "Invoices.Table.emptySearchMessage": `No such invoice was found.<br /><br />You may either change the search options or create a new invoice.`,												
    "Invoices.Table.Header.perPage": `per page`,												
    "Invoices.Table.Header.invoiceNumber": `Invoice number`,												
    "Invoices.Table.Header.date": `Date`,												
    "Invoices.Table.Header.client": `Client`,												
    "Invoices.Table.Header.amount": `Amount`,												
    "Invoices.Table.ItemMenu.edit": `Edit`,												
    "Invoices.Table.ItemMenu.delete": `Delete`,												
    "Invoices.Table.ItemMenu.print": `Print`,												
    "Invoices.Table.ItemMenu.sendMail": `Send in Mail`,												
    "Invoices.Table.ItemMenu.cancel": `Cancel`,												
    "Invoices.Table.Mobile.created": `Created`,												
    "Invoices.Table.Mobile.date": `Date`,												
    "Invoices.Table.Mobile.client": `Client`,												
    "Invoices.Table.Mobile.amount": `Amount`,												
    "Offers.Header.pageTitle": `Offers`,												
    "Offers.Header.pageTitle:lawyer": `Cost Lists`,												
    "Offers.Header.newOffer": `New Offer`,												
    "Offers.Header.newOffer:lawyer": `New Cost List`,												
    "Offers.Header.templates": `Templates`,												
    "Offers.Side.searchTitle": `Search`,												
    "Offers.Side.clientPlaceholder": `Client (enter part of the name)`,												
    "Offers.Side.offerNumberPlaceholder": `Offer number (part of the number)`,												
    "Offers.Side.offerNumberPlaceholder:lawyer": `Cost list number (part of the number)`,												
    "Offers.Side.day": `Day (all)`,												
    "Offers.Side.month": `Month (all)`,												
    "Offers.Side.year": `Year (all)`,												
    "Offers.Side.buttonSearch": `Search`,												
    "Offers.Table.Header.title": `Offer List`,												
    "Offers.Table.Header.title:lawyer": `Cost Lists`,												
    "Offers.Table.emptyMessage": `You have no offers yet.<br /><br />When created (+ 'New Offer' near top), you can send it to the client with ease. When a client confirms the offer, you can create an invoice from it and send it to the client.`,												
    "Offers.Table.emptyMessage:lawyer": `Inside cost list you enter what you do for each client.<br /><br />When created (+ 'New Cost List' near top), you can send it to the client with ease. When a client confirms the cost list, you can create an invoice from it and send it to the client.`,												
    "Offers.Table.emptySearchMessage": `No such offer was found.<br /><br />You may either change the search options or create a new template.`,												
    "Offers.Table.emptySearchMessage:lawyer": `No such cost list was found.<br /><br />You may either change the search options or create a new template.`,												
    "Offers.Table.Header.perPage": `per page`,												
    "Offers.Table.Header.tenderNr": `Offer number`,												
    "Offers.Table.Header.tenderNr:lawyer": `Cost list number`,												
    "Offers.Table.Header.date": `Date`,												
    "Offers.Table.Header.client": `Client`,												
    "Offers.Table.Header.amount": `Amount`,												
    "Offers.Table.ItemMenu.edit": `Edit`,												
    "Offers.Table.ItemMenu.delete": `Delete`,												
    "Offers.Table.ItemMenu.print": `Print`,												
    "Offers.Table.ItemMenu.sendMail": `Send in Mail`,												
    "Offers.Table.ItemMenu.cancel": `Cancel`,												
    "Templates.Header.pageTitle": `Templates`,												
    "Templates.Header.newTemplate": `New Template`,												
    "Templates.Side.searchTitle": `Search`,												
    "Templates.Side.searchPlaceholder": `Template (part of the name)`,												
    "Templates.Side.buttonSearch": `Search`,												
    "Templates.Table.Header.title": `Template List`,												
    "Templates.Table.emptyMessage": `You have no templates yet.<br /><br />Templates are used to created similar offers or invoices. You can create a template either from existing offer or invoice or here by clicking 'New template'.`,												
    "Templates.Table.emptyMessage:lawyer": `You have no templates yet.<br /><br />Templates are used to created similar cost lists or invoices. You can create a template either from existing cost list or invoice or here by clicking 'New template'.`,												
    "Templates.Table.emptySearchMessage": `No such template was found.<br /><br />You may either change the search options or create a new one.`,												
    "Templates.Table.Header.perPage": `per page`,												
    "Templates.Table.Header.templateName": `Name`,												
    "Templates.Table.Header.creator": `Created`,												
    "Templates.Table.ItemMenu.edit": `Edit`,												
    "Templates.Table.ItemMenu.delete": `Delete`,												
    "Templates.Table.ItemMenu.createOffer": `Create Offer`,												
    "Templates.Table.ItemMenu.createOffer:lawyer": `Create Cost List`,												
    "Templates.Table.ItemMenu.createInvoice": `Create Invoice`,												
    "Login.email": `Email`,												
    "Login.forgotPassword": `Forgot password?`,												
    "Login.loginButton": `Login`,												
    "Login.pageTitle": `Login`,												
    "Login.password": `Password`,												
    "Login.popupErrorWrongUser": `We haven't found this user/password combination. Check the entered data and try to login again or select “Forgot password?” to change your password.`,												
    "Login.popupErrorCantConnect": `We can't connect to the database, please check your Internet connection.`,												
    "Login.popupSuccess": `You have successfully logged in.`,												
    "Popup.errorTitle": `Error`,												
    "Popup.infoTitle": `Info`,												
    "Popup.deleteTitle": `Do you want to delete?`,												
    "Popup.no": `No`,												
    "Popup.successTitle": `Success`,												
    "Popup.warningTitle": `Warning`,												
    "Popup.close": `Close`,												
    "Popup.yes": `Yes`,												
    "PriceList.Header.pageTitle": `Price List`,												
    "PriceList.Header.pageTitle:lawyer": `Price List`,												
    "PriceList.Header.pageTitleInfoTitle": `Price List Import`,												
    "PriceList.Header.pageTitleInfo": `In case you want to prepare your price list in e.g. Excel and import it into Izračunko, please download this document as an example of CSV file Izračunko can read. Based on it, you can prepare your price list.<br /><a href=\"https://www.izracunko.com/wp-content/uploads/Izracunko_import.csv\">Primjer CSV datoteke za uvoz</a>`,												
    "PriceList.Header.addProduct": `Add Product`,												
    "PriceList.Header.addProduct:lawyer": `Add Service`,												
    "PriceList.Header.addGroup": `Add Group`,												
    "PriceList.Side.searchTitle": `Search`,												
    "PriceList.Side.searchPlaceholder": `Name or Code part`,												
    "PriceList.Side.searchButton": `Search`,												
    "PriceList.Side.importPriceList": `Import Price List`,												
    "PriceList.Side.exportPriceList": `Export Price List`,												
    "PriceList.Side.deletePriceList": `Delete Price List`,												
    "PriceList.Table.Header.title": `Product List`,												
    "PriceList.Table.emptyMessage": `You don't have any products yet in price list.<br /><br />Once you enter the product, you can easily select it when you create an offer or invoice. If you have a larger amount of similar products, you can organize them within groups.`,												
    "PriceList.Table.emptyMessage:lawyer": `You don't have any services yet in the price list.<br /><br />Once you enter the service, you can easily select it when you create a cost list or invoice. If you have a larger amount of similar services, you can organize them within groups.`,												
    "PriceList.Table.emptyGroupMessage": `This group has no products or subgroups.<br /><br />You may return to the parent group by clicking the arrow 'Back' to select a product from another group or you may create a new item.`,												
    "PriceList.Table.emptyGroupMessage:lawyer": `This group has no services or subgroups.<br /><br />You may return to the parent group by clicking the arrow 'Back' to select a service from another group or you may create a new item.`,												
    "PriceList.Table.emptySearchMessage": `Search term was not found in products nor groups.<br /><br />Are you sure you have such product or group?`,												
    "PriceList.Table.emptySearchMessage:lawyer": `Search term was not found in services nor groups.<br /><br />Are you sure you have such service or group?`,												
    "PriceList.Table.Header.perPage": `per page`,												
    "PriceList.Table.Header.name": `Name [code]`,												
    "PriceList.Table.Header.price": `Price`,												
    "PriceList.Table.Item.back": `… (back)`,												
    "PriceList.Table.ItemMenu.edit": `Edit`,												
    "PriceList.Table.ItemMenu.delete": `Delete`,												
    "PriceList.EditGroup.pageTitleEdit": `Edit Group`,												
    "PriceList.EditGroup.pageTitleNew": `Create Group`,												
    "PriceList.EditGroup.name": `Group name`,												
    "PriceList.EditGroup.namePlaceholder": `Enter group name`,												
    "PriceList.EditGroup.picture": `Picture`,												
    "PriceList.EditGroup.selectedPicture": `Selected picture`,												
    "PriceList.EditGroup.Message.successUpdate": `Group successfully saved.`,												
    "PriceList.EditGroup.Message.errorUpdate": `Error trying to save group.`,												
    "PriceList.EditGroup.Message.successCreate": `Group successfully created.`,												
    "PriceList.EditGroup.Message.errorCreate": `Error trying to save group.`,												
    "PriceList.EditProduct.pageTitleEdit": `Edit Product`,												
    "PriceList.EditProduct.pageTitleEdit:lawyer": `Edit Service`,												
    "PriceList.EditProduct.pageTitleNew": `Create Product`,												
    "PriceList.EditProduct.pageTitleNew:lawyer": `Create Service`,												
    "PriceList.EditProduct.product": `Product`,												
    "PriceList.EditProduct.product:lawyer": `Service`,												
    "PriceList.EditProduct.productPlaceholder": `Enter product name`,												
    "PriceList.EditProduct.productPlaceholder:lawyer": `Enter service name`,												
    "PriceList.EditProduct.salesPrice": `Sales Price`,												
    "PriceList.EditProduct.salesPrice:lawyer": `Price`,												
    "PriceList.EditProduct.additionalInformation": `Additional Info`,												
    "PriceList.EditProduct.priceDifference": `Price Difference`,												
    "PriceList.EditProduct.purchasePrice": `Purchase Price`,												
    "PriceList.EditProduct.measurementUnit": `Measurement Unit`,												
    "PriceList.EditProduct.width": `Width`,												
    "PriceList.EditProduct.widthPlaceholder": `Enter product width`,												
    "PriceList.EditProduct.height": `Height`,												
    "PriceList.EditProduct.heightPlaceholder": `Enter product height`,												
    "PriceList.EditProduct.depth": `Depth`,												
    "PriceList.EditProduct.depthPlaceholder": `Enter product depth`,												
    "PriceList.EditProduct.productCode": `Product Code`,												
    "PriceList.EditProduct.productCodePlaceholder": `Enter product code`,												
    "PriceList.EditProduct.VAT": `VAT`,												
    "PriceList.EditProduct.taxFreeCheck": `This product/service is tax free.`,												
    "PriceList.EditProduct.picture": `Picture`,												
    "PriceList.EditProduct.Message.successUpdate": `Product successfully saved.`,												
    "PriceList.EditProduct.Message.successUpdate:lawyer": `Service successfully saved.`,												
    "PriceList.EditProduct.Message.errorUpdate": `Error trying to save product.`,												
    "PriceList.EditProduct.Message.errorUpdate:lawyer": `Error trying to save service.`,												
    "PriceList.EditProduct.Message.successCreate": `Product successfully created.`,												
    "PriceList.EditProduct.Message.successCreate:lawyer": `Service successfully created.`,												
    "PriceList.EditProduct.Message.errorCreate": `Error trying to save product.`,												
    "PriceList.EditProduct.Message.errorCreate:lawyer": `Error trying to save service.`,												
    "PriceList.DeleteGroup.pageTitle": `Delete group`,												
    "PriceList.DeleteGroup.questionYesNo": `Do you really want to delete group {{groupName}} with all of its subgroups and products?`,												
    "PriceList.DeleteGroup.successMessage": `Group {{groupName}} successfully deleted.`,												
    "PriceList.DeleteGroup.errorMessage": `Group {{groupName}} wasn't successfully deleted.`,												
    "PriceList.DeleteProduct.pageTitle": `Delete product`,												
    "PriceList.DeleteProduct.questionYesNo": `Do you really want to delete product {{productName}}?`,												
    "PriceList.DeleteProduct.successMessage": `Product {{productName}} successfully deleted.`,												
    "PriceList.DeleteProduct.errorMessage": `Product {{productName}} wasn't successfully deleted.`,												
    "PriceList.Import.pageTitle": `Import Price List`,												
    "PriceList.Import.Message.infoTitle": `Instructions for price list import`,												
    "PriceList.Import.Message.infoText": `Select XML file from which you will import price list. Here you have an example of <a class=\"link\" href=\"#\">XML file</a>, and here you have <a class=\"link\" href=\"#\"> Excel 2007 from which</a> we have created the XML file using 'Save As -> XML Data'. It is not necessary to enter all the elements: if a product is not inside a group, just enter the product name and if the group is empty, just enter group name in column GrupaUKojojSeNalaziProizvod.`,												
    "PriceList.Import.Message.warningTitle": `Warning`,												
    "PriceList.Import.Message.warningText": `Ukoliko već imate iste proizvode u cjeniku, njihove cijene će biti prepisane sa cijenama iz XML datoteke.`,												
    "PriceList.Import.Header.title": `Select XML File`,												
    "PriceList.Import.Table.fileTitle": `File`,												
    "PriceList.Import.Table.fileButton": `Select File`,												
    "PriceList.Import.Table.fileNoneMessage": `Document is not selected`,												
    "PriceList.Import.Table.groupTitle": `Group name`,												
    "PriceList.Import.Table.groupPlaceholder": `Enter group name`,												
    "PriceList.Delete.pageTitle": `Delete Whole Price List`,												
    "PriceList.Delete.questionYesNo": `Do you really want to delete whole price list? This action can't be undone.`,												
    "Registration.title": `Registration`,												
    "Registration.personaldataTitle": `For start we need a bit of your personal data:`,												
    "Registration.personaldataName": `Name`,												
    "Registration.personaldataNamePlaceholder": `Your name`,												
    "Registration.personaldataSurname": `Last name`,												
    "Registration.personaldataSurnamePlaceholder": `Your last name`,												
    "Registration.personaldataEmail": `Email`,												
    "Registration.personaldataEmailPlaceholder": `example@gmail.com (email you will use for login)`,												
    "Registration.accountdataTitle": `And a bit other data to setup your account:`,												
    "Registration.accountdataName": `Invoice issuer name`,												
    "Registration.accountdataNamePlaceholder": `Name of the invoice issuer (company name or your own name)`,												
    "Registration.accountdataTypeSelector": `Invoice issuer type`,												
    "Registration.accountdataTypeSelectorPlaceholder": `Select type most similar to what you do`,												
    "Registration.accountdataPassword": `Password`,												
    "Registration.accountdataPasswordPlaceholder": `Password you will use for login`,												
    "Registration.securityQuestion": `Security question`,												
    "Registration.securityQuestionPlaceholder": `Please answer security question`,												
    "Registration.securityQuestion1": `We enter into the house through?`,												
    "Registration.securityQuestion1Answer1": `door`,												
    "Registration.securityQuestion1Answer2": `doors`,												
    "Registration.securityQuestion2": `Twelve minus seven equals?`,												
    "Registration.securityQuestion2Answer1": `five`,												
    "Registration.securityQuestion2Answer2": `5`,												
    "Registration.securityQuestion3": `We see with our?`,												
    "Registration.securityQuestion3Answer1": `eye`,												
    "Registration.securityQuestion3Answer2": `eyes`,												
    "Registration.securityQuestion4": `When it's cold, water turns into?`,												
    "Registration.securityQuestion4Answer1": `ice`,												
    "Registration.securityQuestion5": `When sun sets, what comes?`,												
    "Registration.securityQuestion5Answer1": `night`,												
    "Registration.securityQuestion5Answer2": `dark`,												
    "Registration.registerButton": `Register`,												
    "Registration.promoText": `Try it out for 15 days without obligations`,												
    "Registration.testimonial1Name": `Mirko Budić, attorney`,												
    "Registration.testimonial1Text": `First software in my life in which I wouldn't change anything.`,												
    "Registration.testimonial2Name": `Ankica Šolić, attorney`,												
    "Registration.testimonial2Text": `This is perfect to me.`,												
    "Registration.testimonial3Name": `Igor Čukac, Komet prijevoz`,												
    "Registration.testimonial3Text": `We are using 'Izračunko' to create offers and invoices since september 2013.<br/>The program accelerated the process of making offers in both desktop and mobile versions. The average time for us to create PDF offer is only 3 MINUTES FROM THE INQUIRY RECEIVED.<br />I would like to add that they provide excellent user support to all the questions.`,												
    "Registration.testimonial4Name": `Robert Koščak, Svijet komunikacija`,												
    "Registration.testimonial4Text": `This software of yours is a phenomenal thing and it made my work so much easier that I can't describe it.<br/>Kudos to your team, I am more than satisfied.`,												
    "Registration.successMessageTitle": `Welcome`,												
    "Registration.showSuccessMessage": `Thank you for deciding to try out our program, we hope that you will like it. Login link was sent to your e-mail address.<br/>Currently you have only basic information entered. Everything else can be precisely set according to your needs within the settings.`,												
    "Registration.MailToUser.subject": `Izracunko user data`,												
    "Registration.MailToUser.text": `Hello,
    
    we send you your user data.
    
    Name: {{userFullName}}
    Access link: {{adminLink}}
    Access e-mail: {{userMail}}
    
    If you forget your password, you can change it via access link.
    
    Best regards,
    
    Izračunko team`,												
    "Registration.MailToUs.subject": `New user has registered!`,												
    "Registration.MailToUs.text": `Yay! We've just got new user. User's name is {{userFullName}} ({{userMail}}), comes with company {{companytName}} and starts business as '{{companyType}}'.`,												
    "ResetPassword.title": `Change Password`,												
    "ResetPassword.passwordPlaceholder": `New password`,												
    "ResetPassword.passwordRepeatPlaceholder": `Repeat new password`,												
    "ResetPassword.buttonText": `Change Password`,												
    "ResetPassword.showSuccessMessage": `Password successfully changed`,												
    "ResetPassword.showErrorMessage": `Error trying to change password`,												
    "Reports.Header.pageTitle": `Reports`,												
    "Reports.Header.pageTitleInfoTitle": `Report Types`,												
    "Reports.Header.pageTitleInfo": `Sales report - how much has been made by each payment type (total)<br />
    Revenue per day - how much has been made by each payment type (per day)<br />
    Book of Invoices - invoices per day<br />
    Product report - which products/services have been sold and at what price<br />
    Book of Sales - daily sales and total`,												
    "Reports.Type.title": `Report type`,												
    "Reports.Type.salesReport": `Sales report`,												
    "Reports.Type.revenuePerDay": `Revenue per day`,												
    "Reports.Type.invoicesBook": `Book of Invoices`,												
    "Reports.Type.productReport": `Product report`,												
    "Reports.Type.salesBook": `Book of Sales`,												
    "Reports.from": `From`,												
    "Reports.until": `Until`,												
    "Reports.noInvoicesMessage": `There are no invoices in this time period`,												
    "Reports.invoices": `Invoices`,												
    "Reports.paymentType": `PaymentType`,												
    "Reports.noVAT": `Without VAT`,												
    "Reports.VAT": `VAT`,												
    "Reports.withVAT": `With VAT`,												
    "Reports.total": `Total`,												
    "Reports.date": `Date`,												
    "Reports.invoiceNumber": `Invoice Number`,												
    "Reports.baseAmount": `Without VAT`,												
    "Reports.product": `Product`,												
    "Reports.quantity": `Quantity`,												
    "Reports.amount": `Amount`,												
    "Reports.description": `Description`,												
    "Reports.cashCheck": `Cash/Check`,												
    "Reports.notCash": `Not Cash`,												
    "Settings.Header.pageTitle": `Settings`,												
    "Settings.Side.title": `More`,												
    "Settings.Side.users": `Users`,												
    "Settings.Side.printerSettings": `Print Settings`,												
    "Settings.Side.myPictures": `My Product Pictures`,												
    "Settings.Side.fiscalisation": `Fiscalization Settings`,												
    "Settings.Side.salesPlaces": `Sales Places`,												
    "Settings.Side.eInvoice": `eInvoice Settings`,												
    "Settings.Table.All.buttonShowPrint": `Show in Print`,												
    "Settings.Table.BasicInformation.title": `Basic Information`,												
    "Settings.Table.BasicInformation.name": `Name`,												
    "Settings.Table.BasicInformation.namePlaceholder": `Enter the name of invoice issuer (company name or your own name)`,												
    "Settings.Table.BasicInformation.nameInfoTitle": `Name`,												
    "Settings.Table.BasicInformation.nameInfo": `Official company name or your own name if you issue invoices in your name.`,												
    "Settings.Table.BasicInformation.identificationNumber": `Tax ID`,												
    "Settings.Table.BasicInformation.identificationNumberPlaceholder": `Enter tax ID`,												
    "Settings.Table.BasicInformation.identificationNumber.fiscalizationIsOnInfoTitle": `Not Changeable at the Moment`,												
    "Settings.Table.BasicInformation.identificationNumber.fiscalizationIsOnInfo": `You can't change tax ID while fiscalization is on.`,												
    "Settings.Table.Address.title": `Address`,												
    "Settings.Table.Address.street": `Street`,												
    "Settings.Table.Address.streetPlaceholder": `e.g. George Washington`,												
    "Settings.Table.Address.streetNumber": `Street number`,												
    "Settings.Table.Address.streetNumberPlaceholder": `e.g. 10`,												
    "Settings.Table.Address.city": `City`,												
    "Settings.Table.Address.cityPlaceholder": `e.g. New York`,												
    "Settings.Table.Address.postNumber": `Post Office`,												
    "Settings.Table.Address.postNumberPlaceholder": `e.g. 10000`,												
    "Settings.Table.Address.county": `County`,												
    "Settings.Table.Address.countyPlaceholder": `Enter county name`,												
    "Settings.Table.Address.country": `Country`,												
    "Settings.Table.Contact.title": `Contact information`,												
    "Settings.Table.Contact.phone": `Phone`,												
    "Settings.Table.Contact.mobile": `Mobile`,												
    "Settings.Table.Contact.fax": `Fax`,												
    "Settings.Table.Contact.email": `Email address`,												
    "Settings.Table.Contact.emailInfoTitle": `Email Address`,												
    "Settings.Table.Contact.emailInfo": `This Email address will be used to send offers and invoices to your clients.`,												
    "Settings.Table.Contact.emailInfo:lawyer": `This Email address will be used to send cost lists and invoices to your clients.`,												
    "Settings.Table.Contact.web": `Web`,												
    "Settings.Table.Contact.iban": `Bank Account Number (IBAN/SWIFT/…)`,												
    "Settings.Table.TaxCurrency.title": `Tax and Currency`,												
    "Settings.Table.TaxCurrency.companyInVAT": `Invoice issuer is in VAT system`,												
    "Settings.Table.TaxCurrency.companyInVATInfoTitle": `Invoice issuer is in VAT system`,												
    "Settings.Table.TaxCurrency.companyInVATInfo": `If you select that invoice issuer (your company or you personally) is in VAT (Value Added Tax) system, then each item in offer/invoice will have VAT as entered below. Otherwise, you won't be able to apply VAT to your offers/invoices.`,												
    "Settings.Table.TaxCurrency.companyInVATInfo:lawyer": `If you select that invoice issuer (your company or you personally) is in VAT (Value Added Tax) system, then each item in cost list/invoice will have VAT as entered below. Otherwise, you won't be able to apply VAT to your cost lists/invoices.`,												
    "Settings.Table.TaxCurrency.defaultVAT": `Value Added Tax (VAT)`,												
    "Settings.Table.TaxCurrency.defaultVATInfoTitle": `VAT`,												
    "Settings.Table.TaxCurrency.defaultVATInfo": `This VAT will be automatically applied to each new item in offers and invoices.`,												
    "Settings.Table.TaxCurrency.defaultVATInfo:lawyer": `This VAT will be automatically applied to each new item in cost lists and invoices.`,												
    "Settings.Table.TaxCurrency.changeVATperProduct": `Allow VAT change for each item`,												
    "Settings.Table.TaxCurrency.predefinedCurrency": `Input Currency`,												
    "Settings.Table.TaxCurrency.predefinedCurrencyInfoTitle": `Predefined input currency`,												
    "Settings.Table.TaxCurrency.predefinedCurrencyInfo": `Currency in which we input prices into the price list and from the price list into the offer or invoice.`,												
    "Settings.Table.TaxCurrency.predefinedCurrencyInfo:lawyer": `Currency in which we input prices into the price list and from the price list into the cost list or invoice.`,												
    "Settings.Table.TaxCurrency.useMoreCurrencies": `I want to determine default offers and invoices currency`,												
    "Settings.Table.TaxCurrency.predefinedOfferCurrency": `Offer Currency`,												
    "Settings.Table.TaxCurrency.predefinedOfferCurrency:lawyer": `Cost List Currency`,												
    "Settings.Table.TaxCurrency.predefinedInvoiceCurrency": `Invoice Currency`,												
    "Settings.Table.TaxCurrency.predefinedClientCurrency": `Client Currency`,												
    "Settings.Table.Pricelist.title": `Price List`,												
    "Settings.Table.Pricelist.useTotalPrice": `I want to enter Overall price (includes VAT and discount)`,												
    "Settings.Table.Pricelist.useTotalPriceInfoTitle": `Overall Price`,												
    "Settings.Table.Pricelist.useTotalPriceInfo": `The overall price is final price paid by the customer. The program will calculate the associated VAT and discount.`,												
    "Settings.Table.Pricelist.showProductsAsDimensions": `Show products as dimension (width x height)`,												
    "Settings.Table.Pricelist.showProductsAsDimensionsInfoTitle": `Dimension (width x height)`,												
    "Settings.Table.Pricelist.showProductsAsDimensionsInfo": `If most of your products is measured in width x height (e.g. windows) then switch this option on. For other products you can switch it off individually while entering them into the price list.`,												
    "Settings.Table.Pricelist.showPurchasePrice": `In my price list I want to have the purchase price and profit margin (along with the sale price)`,												
    "Settings.Table.Pricelist.showPurchasePriceInfoTitle": `Purchase Price and Profit Margin`,												
    "Settings.Table.Pricelist.showPurchasePriceInfo": `Enabling this option will automatically calculate your profit.`,												
    "Settings.Table.Pricelist.showProductDimensions": `I want to enter product dimensions (width, height and depth)`,												
    "Settings.Table.Pricelist.showProductDimensionsInfoTitle": `Product dimensions`,												
    "Settings.Table.Pricelist.showProductDimensionsInfo": `Enabling this option you will be able to enter product dimensions (width, height and depth).`,												
    "Settings.Table.Pricelist.showGroupsInPrint": `I want to add price list groups in my product names`,												
    "Settings.Table.Pricelist.showGroupsInPrintInfoTitle": `Creating Product Names`,												
    "Settings.Table.Pricelist.showGroupsInPrintInfo": `The product name is composed from group name, subgroup name, and product name. For example group BALLPOINT PEN + subgroup TOZ + product BLUE gives the whole name of the BALLPOINT PEN TOZ BLUE.`,												
    "Settings.Table.Pricelist.showGroupsInPrintInfoWindowmakers": `The product name is composed from group name, subgroup name, and product name. When most of your products are measured in width x height, we recommend switching this option on. For example, group WINDOW + subgroup TWO LEAF + product 1200 x 1200 gives whole item name WINDOW TWO LEAF 1200 x 1200. The initial price list is made under assumption that this option is switched on.`,												
    "Settings.Table.Pricelist.groupsSeparator": `Group names separator`,												
    "Settings.Table.Pricelist.groupsSeparatorDefaultText": ` -> `,												
    "Settings.Table.Pricelist.showSimilarProductPrices": `I want to see similar products and their prices while creating new offer/invoice item.`,												
    "Settings.Table.Pricelist.showSimilarProductInfoTitle": `Similar Product Prices`,												
    "Settings.Table.Pricelist.showSimilarProductPricesInfo": `Switching this option on will show you prices of products with similar names when adding new product to the offer/invoice. This makes it easier for you to determine new item price.`,												
    "Settings.Table.Pricelist.predefinedMeasurementsUnits": `Predefined Measurement Units`,												
    "Settings.Table.PredefinedText.title": `Predefined Texts`,												
    "Settings.Table.PredefinedText.offerNote": `Predefined Offer Note`,												
    "Settings.Table.PredefinedText.offerNote:lawyer": `Predefined Cost List Note`,												
    "Settings.Table.PredefinedText.invoiceNote": `Predefined Invoice Note`,												
    "Settings.Table.PredefinedText.mailOffer": `Predefined Offer Mail`,												
    "Settings.Table.PredefinedText.mailOffer:lawyer": `Predefined Cost List Mail`,												
    "Settings.Table.PredefinedText.mailInvoice": `Predefined Invoice Mail`,												
    "Settings.Table.Languages.title": `Languages`,												
    "Settings.Table.Languages.interfaceLanguage": `Interface Language`,												
    "Settings.Table.Languages.offerLanguage": `Offer Language`,												
    "Settings.Table.Languages.offerLanguage:lawyer": `Cost List Language`,												
    "Settings.Save.Message.successUpdate": `Settings successfully saved`,												
    "Settings.Save.Message.successUpdateNote": `Note: changes won't be visible on existing offers and invoices until you make an update inside individual offer/invoice settings.`,												
    "Settings.Save.Message.errorUpdate": `Error trying to save settings.`,												
    "Settings.PrintSettings.Header.pageTitle": `Print Settings`,												
    "Settings.PrintSettings.Message.successUpdate": `Print settings successfully saved.`,												
    "Settings.PrintSettings.Message.errorUpdate": `Error trying to save print settings.`,												
    "Settings.PrintSettings.Table.Pictures.title": `Header, Footer and Signature`,												
    "Settings.PrintSettings.Table.Pictures.header": `Header`,												
    "Settings.PrintSettings.Table.Pictures.headerNone": `Header doesn't exist.`,												
    "Settings.PrintSettings.Table.Pictures.headerDeleteTitle": `Delete Header`,												
    "Settings.PrintSettings.Table.Pictures.headerDelete": `Do you really want to delete header?`,												
    "Settings.PrintSettings.Table.Pictures.footer": `Footer`,												
    "Settings.PrintSettings.Table.Pictures.footerFreeNote": `You can't change footer while on free plan.`,												
    "Settings.PrintSettings.Table.Pictures.footerNone": `Footer doesn't exist.`,												
    "Settings.PrintSettings.Table.Pictures.footerDeleteTitle": `Delete Footer`,												
    "Settings.PrintSettings.Table.Pictures.footerDelete": `Do you really want to delete footer?`,												
    "Settings.PrintSettings.Table.Pictures.signature": `Signature`,												
    "Settings.PrintSettings.Table.Pictures.signatureNone": `Signature doesn't exist.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.pageTitleNew": `Add Header`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.pageTitleEdit": `Change Header`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.infoNote": `Select a picture to send from your computer (these picture types are valid: .jpg, .png, .bmp, .gif). Maximum file size is 200 KB. Picture will be resized to 2.5 cm height and proportional width to preserve aspect ratio.<br />Note: for optimum print of your offers and invoices we recommend a picture with maximum width of 2008 px and maximum height of 295 px with resolution at least 300 dpi).`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.infoNote:lawyer": `Select a picture to send from your computer (these picture types are valid: .jpg, .png, .bmp, .gif). Maximum file size is 200 KB. Picture will be resized to 2.5 cm height and proportional width to preserve aspect ratio.<br />Note: for optimum print of your cost lists and invoices we recommend a picture with maximum width of 2008 px and maximum height of 295 px with resolution at least 300 dpi).`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.selectButton": `Select`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.headerNotSelectedNote": `Picture is not selected`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.warningChangeNote": `Uploading new header will delete the existing one!<br />For quality printing of your documents, you need to have a picture with at least 300 dpi (dots per inch).`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.infoPictureRights": `By submitting this picture you guarantee that you have the rights to its distribution.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.bigPictureNote": `File is too big. Maximum file size is {{maxFileSize}} kb.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.notPictureNote": `File is not a picture.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.headerAdded": `Header was successfully uploaded.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.headerRemoved": `Header was deleted.`,												
    "Settings.PrintSettings.Table.Pictures.NewHeader.messageBadQuality": `Header wasn't uploaded.<br />For quality printing of your documents, you need to have a picture with at least 300 dpi (dots per inch).`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.pageTitleNew": `Add Footer`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.pageTitleEdit": `Change Footer`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.footerTitle": `Footer`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.warningChangeNote": `Uploading new header will delete the existing one!<br />For quality printing of your documents, you need to have a picture with at least 300 dpi (dots per inch).`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.footerAdded": `Footer was successfully uploaded.`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.footerDeleted": `Footer was deleted.`,												
    "Settings.PrintSettings.Table.Pictures.NewFooter.messageBadQuality": `Footer wasn't uploaded.<br/>For quality printing of your documents, you need to have a picture with at least 300 dpi (dots per inch).`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.pageTitleNew": `Add Signature`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.pageTitleEdit": `Change Signature`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureTitle": `Signature`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureInfo": `Select a picture to send from your computer (these picture types are valid: .jpg, .png, .bmp, .gif). Maximum file size is 200 KB. Picture will be resized to 7 cm width or 4 cm height if larger (proportionally  to preserve aspect ratio).`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureAdded": `Signature added.`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemoved": `Signature deleted.`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemoveQuestion": `Do you really want to delete current signature?`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemove": `Delete Signature`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureChange": `Change Signature`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureOverwrite": `Uploading new signature will delete the existing one!`,												
    "Settings.PrintSettings.Table.Pictures.NewSignature.signatureViolation": `Signature wasn't added. Maximum allowed width is 7 cm and height is 4 cm.`,												
    "Settings.PrintSettings.Table.Pictures.New.extraInfoInInvoice": `Note: with the new picture, your current data will also be applied (contacts, print settings).`,												
    "Settings.PrintSettings.Table.Invoice.title": `Offers/Invoices print Settings`,												
    "Settings.PrintSettings.Table.Invoice.title:lawyer": `Cost lists/Invoices print Settings`,												
    "Settings.PrintSettings.Table.Invoice.printingMethod": `Printing Method`,												
    "Settings.PrintSettings.Table.Invoice.showPaymentDate": `Always show due date (even when it's the same as invoice date)`,												
    "Settings.PrintSettings.Table.Invoice.printConversionRate": `Print conversion rate`,												
    "Settings.PrintSettings.Table.Invoice.printPaymentCode": `Print payment code on offers and invoices`,												
    "Settings.PrintSettings.Table.Invoice.PrintPaymentCode.infoTitle": `Payment bar code`,												
    "Settings.PrintSettings.Table.Invoice.PrintPaymentCode.infoText": `When scanned within Internet banking app, this code will prefill all the payment details for your customer.<br />2D payment bar code was created by the Croatian banks association and probably won't work in other countries`,												
    "Settings.PrintSettings.Table.Invoice.PrintPaymentCode.insertIBAN": `In order to print payment code, enter your IBAN`,												
    "Settings.PrintSettings.Table.Invoice.textAbovePaymentCode": `Text above payment code`,												
    "Settings.PrintSettings.Table.Invoice.textAbovePaymentCodeDefaultText": `Scan with mobile app to pay:`,												
    "Settings.PrintSettings.Table.Invoice.textWithOfferNumber": `Text with Offer Number`,												
    "Settings.PrintSettings.Table.Invoice.textWithOfferNumber:lawyer": `Text with Cost List Number`,												
    "Settings.PrintSettings.Table.Invoice.textWithOfferNumberDefaultText": `Offer number`,												
    "Settings.PrintSettings.Table.Invoice.textWithOfferNumberDefaultText:lawyer": `Cost list number`,												
    "Settings.PrintSettings.Table.Invoice.textWithInvoiceNumber": `Text with Invoice Number`,												
    "Settings.PrintSettings.Table.Invoice.textWithInvoiceNumberDefaultText": `Invoice number`,												
    "Settings.PrintSettings.Table.Invoice.invoiceLabel": `Invoice label`,												
    "Settings.PrintSettings.Table.Invoice.textWithInvoiceDate": `Text with Invoice Date`,												
    "Settings.PrintSettings.Table.Invoice.textWithInvoiceDateDefaultText": `Date and Time`,												
    "Settings.PrintSettings.Table.Invoice.textWithPaymentDate": `Text with Due Date`,												
    "Settings.PrintSettings.Table.Invoice.textWithPaymentDateDefaultText": `Due Date`,												
    "Settings.PrintSettings.Table.Invoice.textWithPersonInCharge": `Text with Responsible Person Name`,												
    "Settings.PrintSettings.Table.Invoice.textWithPersonInChargeDefaultText": `Person Responsible`,												
    "Settings.PrintSettings.Table.Invoice.namePersonInCharge": `Name of person responsible`,												
    "Settings.PrintSettings.Table.Invoice.NamePersonInCharge.infoTitle": `Name of person responsible`,												
    "Settings.PrintSettings.Table.Invoice.NamePersonInCharge.infoText": `What you write here will be written below your offer or invoice as signature. If you don't put anything here, the name of the current operator will be written.`,												
    "Settings.PrintSettings.Table.Invoice.NamePersonInCharge.infoText:lawyer": `What you write here will be written below your cost list or invoice as signature. If you don't put anything here, the name of the current operator will be written.`,												
    "Settings.PrintSettings.Table.Invoice.textWithOperator": `Text with Operator Name or Code`,												
    "Settings.PrintSettings.Table.Invoice.textWithOperatorDefaultText": `Operator:`,												
    "Settings.PrintSettings.Table.Invoice.PaymentTypes.title": `Payment Methods Texts`,												
    "Settings.PrintSettings.Table.Invoice.PaymentTypes.textWithWayOfPayment": `Text with Payment Method`,												
    "Settings.PrintSettings.Table.Invoice.PaymentTypes.textWithWayOfPaymentDefaultText": `Payment Method`,												
    "Settings.PrintSettings.Table.Invoice.PaymentType.cash": `Cash`,												
    "Settings.PrintSettings.Table.Invoice.PaymentType.bankAccount": `Bank Account`,												
    "Settings.PrintSettings.Table.Invoice.PaymentType.card": `Credit Card`,												
    "Settings.PrintSettings.Table.Invoice.PaymentType.check": `Check`,												
    "Settings.PrintSettings.Table.Invoice.textInInvoiceFooter": `Text in Invoice Footer`,												
    "Settings.PrintSettings.Table.Invoice.stornoInvoiceNote": `Invoice Cancellation Note`,												
    "Settings.PrintSettings.Table.Invoice.stornoInvoiceNoteDefaultText": `Cancellation of invoice number`,												
    "Settings.PrintSettings.Table.Invoice.StornoInvoiceNote.infoTitle": `Invoice Cancellation Note`,												
    "Settings.PrintSettings.Table.Invoice.StornoInvoiceNote.infoText": `What you write here will be printed in the invoice note that is automatically created when the invoice is cancelled and the cancelled invoice number will be appended. E.g. if you cancel invoice number 45 and here you write 'Cancellation of invoice number', in the new invoice the note 'Cancellation of invoice number 45' will be made.`,												
    "Settings.PrintSettings.Table.Invoice.Unfiscalized.infoTitle": `Unfiscalized Invoice Note`,												
    "Settings.PrintSettings.Table.Invoice.Unfiscalized.infoText": `Ovaj račun nije fiskaliziran.`,												
    "Settings.PrintSettings.Table.Columns.title": `Column Titles in Offer/Invoice Table`,												
    "Settings.PrintSettings.Table.Columns.title:lawyer": `Column Titles in Cost List/Invoice Table`,												
    "Settings.PrintSettings.Table.Columns.itemPosition": `Item Position Column Title`,												
    "Settings.PrintSettings.Table.Columns.itemPositionDefaultText": `#`,												
    "Settings.PrintSettings.Table.Columns.item": `Item Column Title`,												
    "Settings.PrintSettings.Table.Columns.itemDefaultText": `Description`,												
    "Settings.PrintSettings.Table.Columns.itemQuantity": `Item Quantity Column Title`,												
    "Settings.PrintSettings.Table.Columns.itemQuantityDefaultText": `QT`,												
    "Settings.PrintSettings.Table.Columns.measurementUnit": `Measurement Unit Column Title`,												
    "Settings.PrintSettings.Table.Columns.measurementUnitDefaultText": `UM`,												
    "Settings.PrintSettings.Table.Columns.price": `Item Price Column Title`,												
    "Settings.PrintSettings.Table.Columns.priceDefaultText": `Price`,												
    "Settings.PrintSettings.Table.Columns.discount": `Item Discount Column Title`,												
    "Settings.PrintSettings.Table.Columns.discountDefaultText": `Discount`,												
    "Settings.PrintSettings.Table.Columns.totalPrice": `Item Total Price Column Title`,												
    "Settings.PrintSettings.Table.Columns.totalPriceDefaultText": `Amount`,												
    "Settings.PrintSettings.Table.Totals.title": `Texts inside Totals Column in Offer/Invoice Table`,												
    "Settings.PrintSettings.Table.Totals.title:lawyer": `Texts inside Totals Column in Cost List/Invoice Table`,												
    "Settings.PrintSettings.TableTotalName.totalPrice": `Total Price Title`,												
    "Settings.PrintSettings.TableTotalName.totalPriceDefaultText": `Total Price`,												
    "Settings.PrintSettings.TableTotalName.tax": `Tax Title`,												
    "Settings.PrintSettings.TableTotalName.taxDefaultText": `VAT`,												
    "Settings.PrintSettings.TableTotalName.calculatedOn": `Text with Base for Tax`,												
    "Settings.PrintSettings.TableTotalName.calculatedOnDefaultText": `calculated on`,												
    "Settings.PrintSettings.TableTotalName.calculatedOnInfoTitle": `Text between tax amount and total amount of items with this tax`,												
    "Settings.PrintSettings.TableTotalName.calculatedOnInfo": `This text will appear with the tax amount 'calculated on' the amount of items with this tax.`,												
    "Settings.PrintSettings.TableTotalName.nonTaxable": `Tax Free Title`,												
    "Settings.PrintSettings.TableTotalName.nonTaxableDefaultText": `Tax Free`,												
    "Settings.PrintSettings.TableTotalName.totalPriceWithVAT": `Total with Tax Title`,												
    "Settings.PrintSettings.TableTotalName.totalPriceWithVATDefaultText": `Grand Total`,												
    "Settings.PrintSettings.Table.Contacts.title": `Text with Contact Info`,												
    "Settings.PrintSettings.TextContact.VAT": `Text with Tax Number`,												
    "Settings.PrintSettings.TextContact.VATDefaultText": `Tax ID:`,												
    "Settings.PrintSettings.TextContact.phone": `Text with Phone Number`,												
    "Settings.PrintSettings.TextContact.phoneDefaultText": `Phone:`,												
    "Settings.PrintSettings.TextContact.fax": `Text with Fax Number`,												
    "Settings.PrintSettings.TextContact.faxDefaultText": `Fax:`,												
    "Settings.PrintSettings.TextContact.telFax": `Text with Phone/Fax Number`,												
    "Settings.PrintSettings.TextContact.telFaxDefaultText": `Phone/Fax:`,												
    "Settings.PrintSettings.TextContact.telFaxInfoTitle": `Text with Phone/Fax Number`,												
    "Settings.PrintSettings.TextContact.telFaxInfo": `This text will appear if you enter the same phone and fax number.`,												
    "Settings.PrintSettings.TextContact.mobile": `Text with Mobile Number`,												
    "Settings.PrintSettings.TextContact.mobileDefaultText": `Mob:`,												
    "Settings.PrintSettings.TextContact.mail": `Text with Email`,												
    "Settings.PrintSettings.TextContact.web": `Text with Web Site Address`,												
    "Settings.PrintSettings.TextContact.IBAN": `Text with Bank Account Number`,												
    "Settings.PrintSettings.TextContact.IBANDefaultText": `Bank account:`,												
    "Settings.MyAccount.Header.pageTitle": `My Account`,												
    "Settings.MyAccount.Table.Usage.title": `Usage`,												
    "Settings.MyAccount.Usage.invoices": `Invoices`,												
    "Settings.MyAccount.Usage.offers": `Offers`,												
    "Settings.MyAccount.Usage.users": `Users`,												
    "Settings.MyAccount.Usage.salesPlaces": `Sales Places`,												
    "Settings.MyAccount.Table.Plan.title": `Plan`,												
    "Settings.MyAccount.Plan.expires": `Plan expires on {{packageExpiryDate}}.`,												
    "Settings.MyAccount.Plan.free": `You are on free plan.`,												
    "Settings.MyAccount.Table.API.title": `API`,												
    "Settings.MyAccount.API.documentation": `API documentation is available <u><a href=\"http://www.izracunko.com/api\">here (click)</a></u>.`,												
    "Settings.MyAccount.API.key": `You are on free plan.`,												
    "Settings.MyAccount.API.users": `Users`,												
    "Settings.MyAccount.API.salesPlaces": `Sales Places`,												
    "Settings.MyPictures.Header.pageTitle": `My Product Pictures`,												
    "Settings.MyPictures.Table.PredefinedPictures.title": `Izračunko Picture Set`,												
    "Settings.MyPictures.Table.PredefinedPictures.buttonBack": `Back`,												
    "Settings.MyPictures.Table.MyPictures.title": `My Pictures`,												
    "Settings.MyPictures.Table.MyPictures.buttonDelete": `Delete`,												
    "Settings.MyPictures.Table.UploadPicture.title": `Add Product Picture`,												
    "Settings.MyPictures.Table.UploadPicture.addPicturePlaceholder": `Picture is not selected`,												
    "Settings.MyPictures.Table.UploadPicture.addPictureButton": `Add`,												
    "Settings.MyPictures.Table.UploadPicture.maxPictureSize": `Maximum allowed picture size is 100 KB.`,												
    "Settings.MyPictures.Table.UploadPicture.saveButton": `Add Picture`,												
    "Settings.MyPictures.Delete.pageTitle": `Delete Picture`,												
    "Settings.MyPictures.Delete.questionYesNo": `Do you really want to delete the selected picture?`,												
    "Settings.MyPictures.Messages.successDelete": `Picture successfully deleted.`,												
    "Settings.MyPictures.Messages.errorDelete": `Picture wasn't successfully deleted.`,												
    "Settings.MyPictures.Messages.successUpload": `Picture successfully added.`,												
    "Settings.MyPictures.Messages.errorUploadType": `Picture type not allowed. Allowed picture types are: png, jpg & gif.`,												
    "Settings.MyPictures.Messages.errorUploadSize": `Picture is too big. Maximum allowed picture size is 100 KB.`,												
    "Settings.MyPictures.Messages.errorUploadUnknown": `Picture wasn't successfully added.`,												
    "Settings.MyPictures.Messages.warningTooManySelected": `You can select only one picture to add.`,												
    "Settings.EInvoice.Header.pageTitle": `eInvoice Settings`,												
    "Settings.EInvoice.Table.ObligatoryData.title": `Obligatory Data`,												
    "Settings.EInvoice.Table.obligatoryInvoiceData": `Obligatory Invoice Data`,												
    "Settings.EInvoice.Table.ObligatoryInvoiceData.infoTitle": `Obligatory Invoice Data`,												
    "Settings.EInvoice.Table.ObligatoryInvoiceData.infoText": `Since eInvoice requires you to enter obligatory invoice data as text, please enter it here.`,												
    "Settings.EInvoice.Table.AdditionalData.title": `Additional Data`,												
    "Settings.EInvoice.Table.AdditionalIBAN": `Second IBAN`,												
    "Settings.EInvoice.Table.AdditionalIBAN.infoTitle": `Another bank account (IBAN)`,												
    "Settings.EInvoice.Table.AdditionalIBAN.infoText": `In case you want to offer your clients the ability to pay you on a different bank account from the one you have entered inside company settings, enter it here and it will be a part of your eInvoices.`,												
    "Settings.EInvoice.Table.MyEInvoice.title": `Moj eRačun service settings`,												
    "Settings.EInvoice.Table.MyEInvoice.Title.infoTitle": `Moj eRačun service`,												
    "Settings.EInvoice.Table.MyEInvoice.infoText": `Moj eRačun is an external service we use to automate eInvoice sending from within our system.<br />These are login data which you obtain upon successful registration for their service. If you want to do that, please contact us at info@izracunko.com, so we can send you instructions.<br />If you want to send eInvoices using another service, you can do it by downloading eInvoice our system creates and transfer it into another service.`,												
    "Settings.EInvoice.Table.userID": `User ID`,												
    "Settings.EInvoice.Table.password": `Password`,												
    "Settings.EInvoice.Messages.successSave": `eInvoice Settings successfully saved.`,												
    "Settings.EInvoice.Messages.errorSave": `Incorrect 'Moj eRačun' service User ID/Password combination. `,												
    "Settings.EInvoice.Messages.warningMissingCompanyData": `In order to send eInvoices, you must enter tax ID, contact email and full address (street, street number, city, zip code and country). `,												
    "Settings.Fiscalisation.Header.pageTitle": `Fiscalization Settings`,												
    "Settings.Fiscalisation.Table.CurrentSettings.title": `Fiscalization`,												
    "Settings.Fiscalisation.Table.CurrentSettings.active": `Fiscalization active`,												
    "Settings.Fiscalisation.Table.CurrentSettings.certificateInfo": `Current Certificate`,												
    "Settings.Fiscalisation.Table.CurrentSettings.certificateValidUntil": `Valid until`,												
    "Settings.Fiscalisation.Table.CurrentSettings.certificateInfoDefaultText": `There is no certificate`,												
    "Settings.Fiscalisation.Table.UploadCertificate.title": `Add Certificate`,												
    "Settings.Fiscalisation.Table.UploadCertificate.titleInfoTitle": `How to Add Certificate?`,												
    "Settings.Fiscalisation.Table.UploadCertificate.titleInfoText": `In order to add certificate, you need to first select it from your computer and enter its password.<br />If you don't have a certificate yet, you need to get it from FINA (brief instructions of what you need, you have under Q&A section <a href=\"http://www.izracunko.com/cijene\" target=\"_blank\">here (click)</a>.`,												
    "Settings.Fiscalisation.Table.UploadCertificate.selectCertificate": `Select Certificate`,												
    "Settings.Fiscalisation.Table.UploadCertificate.selectCertificateDefaultText": `Certificate is not selected`,												
    "Settings.Fiscalisation.Table.UploadCertificate.selectCertificateButton": `Select`,												
    "Settings.Fiscalisation.Table.UploadCertificate.password": `Password`,												
    "Settings.Fiscalisation.Table.UploadCertificate.passwordDefaultText": `Enter certificate password`,												
    "Settings.Fiscalisation.Table.UploadCertificate.saveButton": `Add Certificate`,												
    "Settings.Fiscalisation.Messages.successActivation": `Fiscalization successfully activated.`,												
    "Settings.Fiscalisation.Messages.successDeactivation": `Fiscalization deactivated.`,												
    "Settings.Fiscalisation.Messages.errorActivationNoCertificate": `Fiscalization can't be activated without certificate.`,												
    "Settings.Fiscalisation.Messages.errorActivationCertificateExpired": `Fiscalization can't be activated because the certificate has expired.`,												
    "Settings.Fiscalisation.Messages.errorActivationOIBsDontMatch": `Fiscalization can't be activated because Tax ID doesn't match the Tax ID inside certificate.`,												
    "Settings.Fiscalisation.Messages.errorActivationIntroMessage": `Fiscalization can't be activated until you enter inside settings`,												
    "Settings.Fiscalisation.Messages.errorActivationMissingCompanyAddress": `company address`,												
    "Settings.Fiscalisation.Messages.errorActivationMissingCompanyCity": `company city`,												
    "Settings.Fiscalisation.Messages.errorActivationMissingCompanyTaxID": `company Tax ID`,												
    "Settings.Fiscalisation.Messages.errorActivationMissingPersonalIDs": `all operators' personal IDs`,												
    "Settings.Fiscalisation.Messages.errorActivationZipCodNotNumeric": `zip code with only numbers`,												
    "Settings.Fiscalisation.Messages.successUpload": `Certificate successfully added.`,												
    "Settings.Fiscalisation.Messages.errorUpload": `Certificate wasn't successfully added.`,												
    "Settings.Fiscalisation.Messages.errorUploadWrongPassword": `You haven't entered correct certificate password.`,												
    "Settings.SalesPlaces.Header.pageTitle": `Sales Places`,												
    "Settings.SalesPlaces.Header.PageTitle.infoText": `Each sales place can have its own invoice numbers. Administrator can determine which operator works at which sales place.`,												
    "Settings.SalesPlaces.Header.newSalesPlace": `Add Sales Place`,												
    "Settings.SalesPlaces.Header.NewSalesPlace.warningMessage": `You have reached the allowed number of sales places in your package. Package upgrade terms you can check <a href=\"http://www.izracunko.com/cijene#nadoplata\" target=\"_blank\">here (click)</a>.`,												
    "Settings.SalesPlaces.Side.activeSalesPlace": `Active place`,												
    "Settings.SalesPlaces.Side.activeSalesPlace.infoMessage": `Active sales place determines which invoices, offers and templates you see and you can create reports for that sales place.`,												
    "Settings.SalesPlaces.Side.activeSalesPlace.infoMessage:lawyer": `Active sales place determines which invoices, cost lists and templates you see and you can create reports for that sales place.`,												
    "Settings.SalesPlaces.Side.searchTitle": `Search`,												
    "Settings.SalesPlaces.Side.searchPlaceholder": `Part of the name`,												
    "Settings.SalesPlaces.Side.searchButton": `Search`,												
    "Settings.SalesPlaces.Table.Header.title": `Sales Places`,												
    "Settings.SalesPlaces.emptySearchMessage": `No such sales place was found.<br /><br />You may either change the search options or create a new one.`,												
    "Settings.SalesPlaces.Table.Header.numberOfSalesPlaces": `Number of Sales Places`,												
    "Settings.SalesPlaces.Table.Header.perPage": `per page`,												
    "Settings.SalesPlaces.Table.Header.name": `Sales Place`,												
    "Settings.SalesPlaces.Table.Header.city": `City`,												
    "Settings.SalesPlaces.Table.ItemMenu.edit": `Edit`,												
    "Settings.SalesPlaces.Table.ItemMenu.delete": `Delete`,												
    "Settings.SalesPlaces.Edit.Header.pageTitleEdit": `Edit Sales Place`,												
    "Settings.SalesPlaces.Edit.Header.pageTitleNew": `Add Sales Place`,												
    "Settings.SalesPlaces.Edit.infoText": `If you change sales place label, billing device label or invoice separator, the change will be visible on your invoices.<br />If you work in Croatia, these changes you must also put in your Internal Act, for more details <a href=\"https://www.izracunko.com/blog/fiskalizacija/primjeri-dokumenata-koje-po-zakonu-o-fiskalizaciji-morate-imati/\" target=\"_blank\">click here (text in Croatian)</a>.`,												
    "Settings.SalesPlaces.Edit.Table.name": `Sales Place Name`,												
    "Settings.SalesPlaces.Edit.Table.namePlaceholder": `Enter Sales Place Name`,												
    "Settings.SalesPlaces.Edit.Table.salesPlaceLabel": `Sales Place Label`,												
    "Settings.SalesPlaces.Edit.Table.salesPlaceLabelPlaceholder": `Enter Sales Place Label`,												
    "Settings.SalesPlaces.Edit.Table.SalesPlaceLabelTitle": `Labels and Invoice Number`,												
    "Settings.SalesPlaces.Edit.Table.SalesPlaceLabelInfo": `Sales Place Label, Billing Device Label and Invoice Separator are appended to the invoice number in the following way: if Sales Place Label is 'HQ', Billing Device Label is '1' and Separator is '-' all this appended to the invoice number 23 would be '23-HQ-1'.`,												
    "Settings.SalesPlaces.Edit.Table.billingDeviceLabel": `Billing Device Label`,												
    "Settings.SalesPlaces.Edit.Table.billingDeviceLabelPlaceholder": `Enter Billing Device Label`,												
    "Settings.SalesPlaces.Edit.Table.invoiceSeparator": `Invoice Separator`,												
    "Settings.SalesPlaces.Edit.Table.invoiceSeparatorPlaceholder": `Separator between number, sales place and billing device`,												
    "Settings.SalesPlaces.Edit.Table.invoiceSufix": `Invoice Sufix`,												
    "Settings.SalesPlaces.Edit.Table.invoiceSufixPlaceholder": `Invoice Sufix for this Sales Place`,												
    "Settings.SalesPlaces.Edit.Table.workingTime": `Working Hours`,												
    "Settings.SalesPlaces.Edit.Table.workingTimePlaceholder": `Enter working hours`,												
    "Settings.SalesPlaces.Edit.Table.salesPlaceHaveAddressYesNo": `Sales place has an address`,												
    "Settings.SalesPlaces.Edit.Table.street": `Street`,												
    "Settings.SalesPlaces.Edit.Table.streetPlaceholder": `Enter street name`,												
    "Settings.SalesPlaces.Edit.Table.streetNumber": `Street number`,												
    "Settings.SalesPlaces.Edit.Table.streetNumberPlaceholder": `Enter street number`,												
    "Settings.SalesPlaces.Edit.Table.city": `City`,												
    "Settings.SalesPlaces.Edit.Table.cityPlaceholder": `Enter city`,												
    "Settings.SalesPlaces.Edit.Table.zipCode": `Post Number`,												
    "Settings.SalesPlaces.Edit.Table.zipCodePlaceholder": `Enter post number`,												
    "Settings.SalesPlaces.Edit.Table.county": `County`,												
    "Settings.SalesPlaces.Edit.Table.countyPlaceholder": `Enter county name`,												
    "Settings.SalesPlaces.Edit.Table.description": `Description`,												
    "Settings.SalesPlaces.Edit.Table.descriptionPlaceholder": `This text will appear below sales place on your documents`,												
    "Settings.SalesPlaces.Edit.Message.SuccessCreate": `Sales place successfully created.`,												
    "Settings.SalesPlaces.Edit.Message.ErrorCreate": `Sales place wasn't successfully created.`,												
    "Settings.SalesPlaces.Edit.Message.SuccessUpdate": `Sales place successfully updated.`,												
    "Settings.SalesPlaces.Delete.pageTitle": `Delete Sales Place`,												
    "Settings.SalesPlaces.Delete.questionYesNo": `Do you really want to delete sales place {{salesPlaceNameAddress}}?<br /><br />After you delete it, you want be able to access invoices and offers created within it so we recommend you create a backup before deletion.`,												
    "Settings.SalesPlaces.Delete.successDelete": `You have successfully deleted sales place {{salesPlaceNameAddress}}.`,												
    "Settings.SalesPlaces.Delete.infoMessage": `You haven't deleted sales place {{salesPlaceNameAddress}}.`,												
    "Settings.Users.Header.pageTitle": `Users`,												
    "Settings.Users.Header.PageTitle.infoMessage": `Users are people who have access to your data. In Izračunko, there are two types of users: administrators and regular users. Regular users can't change price list, setting nor create invoices (although you can allow them to create invoices if you want to). They also can't see purchase prices nad margins if you use them. Administrators can do everything.`,												
    "Settings.Users.Header.newUser": `Add User`,												
    "Settings.Users.Header.NewUser.warningMessage": `You have reached the allowed number of users in your package. Package upgrade terms you can check <a href=\"http://www.izracunko.com/cijene#nadoplata\" target=\"_blank\">here (click)</a>.`,												
    "Settings.Users.Side.searchTitle": `Search`,												
    "Settings.Users.Side.searchPlaceholder": `Part of the user name`,												
    "Settings.Users.Side.searchButton": `Search`,												
    "Settings.Users.Table.Header.title": `User List`,												
    "Settings.Users.emptySearchMessage": `No such user was found.<br /><br />You may either change the search options or create a new one.`,												
    "Settings.Users.Table.Header.numberOfUsers": `Number of Users`,												
    "Settings.Users.Table.Header.perPage": `per page`,												
    "Settings.Users.Table.Header.user": `User`,												
    "Settings.Users.Table.Header.mail": `Login Email`,												
    "Settings.Users.Table.Header.admin": `Administrator`,												
    "Settings.Users.Table.ItemMenu.edit": `Edit`,												
    "Settings.Users.Table.ItemMenu.delete": `Delete`,												
    "Settings.Users.Edit.pageTitleEdit": `Edit User`,												
    "Settings.Users.Edit.pageTitleNew": `New User`,												
    "Settings.Users.Edit.name": `Name`,												
    "Settings.Users.Edit.namePlaceholder": `John`,												
    "Settings.Users.Edit.surname": `Last Name`,												
    "Settings.Users.Edit.surnamePlaceholder": `Johnson`,												
    "Settings.Users.Edit.identificationNumber": `ID`,												
    "Settings.Users.Edit.identificationNumberPlaceholder": `12345678901`,												
    "Settings.Users.Edit.sellingPlace": `Sales Place`,												
    "Settings.Users.Edit.personTitle": `Title`,												
    "Settings.Users.Edit.personTitlePlaceholder": `manager`,												
    "Settings.Users.Edit.loginMail": `Login Email`,												
    "Settings.Users.Edit.loginMailPlaceholder": `example@gmail.com (email they will use for login)`,												
    "Settings.Users.Edit.password": `Password`,												
    "Settings.Users.Edit.passwordPlaceholder": `NobodyElseKnows1#`,												
    "Settings.Users.Edit.userLabel": `User Label`,												
    "Settings.Users.Edit.userLabelPlaceholder": `e.g. 01`,												
    "Settings.Users.Edit.userIsAdmin": `User is administrator`,												
    "Settings.Users.Edit.userIsAdminInfo": `If you want to allow user to do everything as you can, select the option '(Yes) User is administrator'. If you find it better not to allow this user to change price list, settings or create invoices, don't select this option (No). Even if you select 'No', you will still be able to allow this user to create invoices if you want to.`,												
    "Settings.Users.Edit.salesPlacesChangesAllowed": `User can change sales places`,												
    "Settings.Users.Edit.invoicesAllowed": `User can create invoices`,												
    "Settings.Users.Edit.saveAndSend": `Send login info to user's email`,												
    "Settings.Users.Edit.Message.successCreate": `User successfully created.`,												
    "Settings.Users.Edit.Message.successUpdate": `User data successfully updated.`,												
    "Settings.Users.Edit.Message.errorUpdate": `User data wasn't successfully updated.`,												
    "Settings.Users.Edit.Message.errorUpdateMailExists": `User with this email already exists.`,												
    "Settings.Users.Delete.pageTitle": `Delete User`,												
    "Settings.Users.Delete.questionYesNo": `Do you really want to delete user {{userName}}?`,												
    "Settings.Users.Delete.successDelete": `You have successfully deleted user {{userName}}.`,												
    "Settings.Users.Delete.errorDelete": `User {{userName}} wasn't deleted.`												
    }
    