import { Field, Form, Formik } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { OfferionErrorMessage } from '../../../shared/forms/validation/OfferionErrorMessage';
import { ChangeFiscalizationAvailabilityResult, FiscalizationApi } from '../../../services/FiscalizationApi'
import { toastError, toastSuccess, toastWarning } from '../../../shared/toastr';
import { date, dateWithTime } from '../../../shared/utils/formatters';
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation';
import { OfferionTooltip } from '../../../shared/tooltip';
import * as Yup from 'yup'

const Section = ({ sectionTitleKey, children, isClosed, tooltipTitleKey, tooltipBodyKey }: { sectionTitleKey: string, children: ReactNode, isClosed: boolean, tooltipTitleKey?: string, tooltipBodyKey?: string }) => {

    const { t } = useOfferionTranslation();
    const [isCollapsed, setIsCollapsed] = useState(isClosed);

    useEffect(() => {
        setIsCollapsed(isClosed);
    }, [isClosed])

    return <>
        <div className="invoices__title invoices__title--short  invoices__title--with-button">
            <h1 style={{ display: "inline", cursor: "pointer" }} onClick={() => setIsCollapsed(!isCollapsed)}>
                {t(sectionTitleKey)}
                {tooltipTitleKey && tooltipBodyKey && <OfferionTooltip placement="right" titleKey={tooltipTitleKey} bodyKey={tooltipBodyKey} />}

            </h1>
            <button type="button" className="button button--gray right" onClick={() => setIsCollapsed(!isCollapsed)}>
                <span className={`${isCollapsed ? 'icon icon__down-blue' : 'icon icon__up'}`}></span>
            </button>
        </div>

        <div className="table__outer info__additional info__additional--hide" style={{ height: isCollapsed ? "0px" : "auto" }}>
            <div className="info__container">
                {children}
            </div>
        </div>
    </>
}

export const FiscalizationSettingsContainer = () => {

    const { t } = useOfferionTranslation();
    const [certificateName, setCertificateName] = useState("");
    const [notAfter, setNotAfter] = useState("");
    const [isFiscalizationActive, setIsFiscalizationActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [error, setError] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        FiscalizationApi.getSettings().then(result => {
            setIsFiscalizationActive(result.isFiscalizationActive);
            setCertificateName(result.currentCertificate);
            setNotAfter(result.notAfter)
            setDataLoaded(true);
        })
    }, [])

    const uploadCertificate = async ({ password }: { password: string }) => {

        if (!selectedFile)
            return;

        try {
            const { certificateName, notAfter } = await FiscalizationApi.uploadCertificate(selectedFile, password);

            setCertificateName(certificateName);
            setNotAfter(notAfter);

            toastSuccess('Settings.Fiscalisation.Messages.successUpload');

            setError(false);

            changeFiscalizationStatus(true).then((result) => setIsFiscalizationActive(result));
        }
        catch {
            setError(true);
        }
    }

    const changeFiscalizationStatus = (isActive: boolean) => {
        return FiscalizationApi.updateFiscalizationSettings(isActive).then(result => {

            if (!result.length) {
                setIsFiscalizationActive(isActive);

                toastSuccess(isActive ? 'Settings.Fiscalisation.Messages.successActivation' : 'Settings.Fiscalisation.Messages.successDeactivation');

                return true;
            }

            let errorMessage = '';

            let complexMessages: string[] = [];

            result.forEach(error => {
                switch (error) {

                    case ChangeFiscalizationAvailabilityResult.CertificateExpired:
                        errorMessage += `${t('Settings.Fiscalisation.Messages.errorActivationCertificateExpired')} `;
                        break;

                    case ChangeFiscalizationAvailabilityResult.NoCertificate:
                        errorMessage += `${t('Settings.Fiscalisation.Messages.errorActivationNoCertificate')} `;
                        break;

                    case ChangeFiscalizationAvailabilityResult.CertificateAndCompanyDoNotMatch:
                        errorMessage += `${t('Settings.Fiscalisation.Messages.errorActivationOIBsDontMatch')} `;
                        break;

                    case ChangeFiscalizationAvailabilityResult.CompanyMissingOib:
                        complexMessages.push(`${t('Settings.Fiscalisation.Messages.errorActivationMissingCompanyTaxID')}`);
                        break;

                    case ChangeFiscalizationAvailabilityResult.CompanyMissingCity:
                        complexMessages.push(`${t('Settings.Fiscalisation.Messages.errorActivationMissingCompanyCity')}`);
                        break;

                    case ChangeFiscalizationAvailabilityResult.CompanyMissingStreet:
                        complexMessages.push(`${t('Settings.Fiscalisation.Messages.errorActivationMissingCompanyAddress')}`);
                        break;

                    case ChangeFiscalizationAvailabilityResult.UserMissingOib:
                        complexMessages.push(`${t('Settings.Fiscalisation.Messages.errorActivationMissingPersonalIDs')}`);
                        break;
                }
            })

            const finalMessage = `${errorMessage ? `${errorMessage} ` : ''}${complexMessages.length ? `${t('Settings.Fiscalisation.Messages.errorActivationIntroMessage')} ${complexMessages.join(', ')}.` : ''}`;

            toastWarning(finalMessage, true, 10000);

            return false;
        })
    }

    if (!dataLoaded)
        return null;

    const schema = Yup.object().shape({
        fileName: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t("PriceList.Import.Table.fileButton") }))
            .nullable(),
        password: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t("Login.password") }))
            .nullable(),
    });

    return <main className="main">

        <BreadcrumbsItem to="/fiscalization">
            {t("Settings.Fiscalisation.Header.pageTitle")}
        </BreadcrumbsItem>

        <div className="main__header">
            <div className="main-title">{t('Settings.Fiscalisation.Header.pageTitle')}</div>

        </div>

        <div className="table__container curled-paper settings">

            <Section sectionTitleKey="Settings.Fiscalisation.Table.CurrentSettings.title" isClosed={!certificateName}>
                <div className="info__row">
                    <label className="info__row-name" htmlFor="isFiscalizationON">{t('Settings.Fiscalisation.Table.CurrentSettings.active')}</label>
                    <div className="info__row-content info__row-content--extended">
                        <input id="isFiscalizationON"
                            type="checkbox"
                            className="checkbox"
                            onChange={e => changeFiscalizationStatus(e.target.checked)}
                            checked={isFiscalizationActive}
                            name="isFiscalizationON" />
                        <label htmlFor="isFiscalizationON"
                            className="button button--gray checkbox-button right">
                            <span className="checkbox-button__icon"></span>
                        </label>
                    </div>
                </div>
                <div className="info__row">
                    <label className="info__row-name">{t('Settings.Fiscalisation.Table.CurrentSettings.certificateInfo')}</label>
                    <div className="info__row__name">
                        <span className="mod-padding-top-6" data-input="file-upload1">{certificateName ? certificateName : t('Settings.Fiscalisation.Table.CurrentSettings.certificateInfoDefaultText')}</span>
                    </div>
                </div>
                {notAfter && certificateName ? <div className="info__row" style={{ borderTop: 0, paddingTop: 4 }}>
                    <label className="info__row-name">{t('Settings.Fiscalisation.Table.CurrentSettings.certificateValidUntil')}</label>
                    <div className="info__row__name">
                        <span className="mod-padding-top-6" data-input="file-upload1">{date(notAfter)}</span>
                    </div>
                </div> : null}
            </Section>

            <Section sectionTitleKey='Settings.Fiscalisation.Table.UploadCertificate.title' isClosed={!!certificateName}
                tooltipTitleKey="Settings.Fiscalisation.Table.UploadCertificate.titleInfoTitle"
                tooltipBodyKey="Settings.Fiscalisation.Table.UploadCertificate.titleInfoText">
                <Formik validationSchema={schema} initialValues={{ password: "", fileName: "" }} onSubmit={uploadCertificate}>
                    {
                        formikProps => <Form id="uploadCertificateForm" name="uploadCertificateForm" noValidate={true}>

                            <div className="info__row mod-margin-top-6">
                                <label className="info__row-name" htmlFor="file-upload">{t('Settings.Fiscalisation.Table.UploadCertificate.selectCertificate')}</label>

                                <div className="info__row-content">

                                    <div className="file-upload__form-wrapper">
                                        <label htmlFor="file-upload" className="button button--padded button--gray file-input__button">
                                            <input id="file-upload"
                                                type="file"
                                                name="certificate"
                                                accept=".pfx,.p12"
                                                onChange={e => {
                                                    if (!e.target.files)
                                                        return;

                                                    setSelectedFile(e.target.files[0])

                                                    formikProps.setFieldValue("fileName", e.target.files[0].name)
                                                }
                                                }
                                            />
                                            <span className="icon icon__document-import"></span>
                                            <span className="button-text button-text--always-show">{t('Settings.Fiscalisation.Table.UploadCertificate.selectCertificateButton')}</span>
                                        </label>

                                    </div>
                                    <span className={`mod-padding-top-6 ${formikProps.errors.fileName ? 'error-message' : ''}`} data-input="file-upload">{selectedFile ? selectedFile.name : t('Settings.Fiscalisation.Table.UploadCertificate.selectCertificateDefaultText')}</span>
                                </div>
                            </div>

                            <div className="info__row mod-margin-top-6">
                                <label className="info__row-name" htmlFor="password">{t('Settings.Fiscalisation.Table.UploadCertificate.password')}</label>

                                <div className="info__row-content">
                                    <div className="">
                                        <Field id="password" type="password" name="password"
                                            className={`input file-input ${error ? 'error' : ""}`}
                                            placeholder={t('Settings.Fiscalisation.Table.UploadCertificate.passwordDefaultText')}
                                        />
                                    </div>
                                    <OfferionErrorMessage name="password" />

                                </div>
                            </div>

                            <button className="button button--padded button--gray button--accept mod-margin-top-8 mod-margin-left-16" type="submit">
                                <span className="icon icon__check-green"></span>
                                <span className="button-text button-text--always-show">{t('Settings.Fiscalisation.Table.UploadCertificate.saveButton')}</span>
                            </button>
                        </Form>
                    }
                </Formik>
            </Section>
        </div>
    </main >
}