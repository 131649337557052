import React, { useEffect, useState } from 'react';
import { PriceListApi, PriceListItem } from '../../../../services/PriceListApi';
import { Currency } from '../../../models';
import { CurrencyLabel } from '../../../ui/currency';
import { Formik, Form, Field } from 'formik';
import { group } from 'console';
import { Overlay } from '../../../elements/Overlay';
import { useOfferionTranslation } from '../../../store/hooks/useOfferionTranslation';

interface Props {
    onCloseButtonClicked: () => void;
    currency: Currency;
    selectedProductComponent: React.ComponentType<{ selectedProduct: PriceListItem }>
    allowGroupSelection?: boolean;
    priceListPromise: (groupId: number | null, searchString: string | null) => Promise<PriceListItem[]>;
}

export const PriceListModalForm = (props: Props) => {

    const { t } = useOfferionTranslation();

    const [priceListItems, setPriceListItems] = useState<PriceListItem[]>([]);
    const [previousGroupIds, setPreviousGroupIds] = useState<(number | null)[]>([]);
    const [groupId, setGroupId] = useState<number | null>(null);
    const [searchString, setSearchString] = useState<string | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<PriceListItem | null>(null);

    useEffect(() => {

        setSelectedProduct(null)
        setPriceListItems([]);

        props.priceListPromise(groupId, searchString).then(result => {

            setPriceListItems(result)
        });
    }, [searchString, groupId])

    const goBackInHierarchy = () => {
        const previousGroupId = previousGroupIds.pop();

        if (previousGroupId === undefined)
            return;

        setPreviousGroupIds([...previousGroupIds]);
        setPriceListItems([]);
        setSearchString(null);
        setGroupId(previousGroupId);
    }

    const goToGroup = (newGroupId: number) => {
        setGroupId(newGroupId);
        setPriceListItems([]);
        setSearchString(null);
        setPreviousGroupIds([...previousGroupIds, groupId]);
    }

    const elements = priceListItems.filter(x => selectedProduct == null || x == selectedProduct).map((item, index) => {
        return <li className="table__row" key={`${item.groupId}-${item.productId}`}>
            <span className="table__cell__title table__cell__title--full-width">
                {item.productId && <span>
                    <span className="icon icon__item cursor-pointer" onClick={() => setSelectedProduct(selectedProduct ? null : item)}></span>
                    <span className="cursor-pointer" onClick={() => setSelectedProduct(selectedProduct ? null : item)}>{item.name}</span>
                    <CurrencyLabel className="mod-margin-left-4 price-list-amount" amount={item.price} currency={props.currency} />
                    <input id={`selected-product-${index}`} type="checkbox" className="checkbox" checked={selectedProduct == item} />
                    <label htmlFor={`selected-product-${index}`}
                        onClick={() => setSelectedProduct(selectedProduct ? null : item)}
                        className="button button--gray button--padded checkbox-button button--print  mod-margin-left-4">
                        <span className="checkbox-button__icon"></span>
                    </label>
                </span>}
                {item.groupId && <span style={{ cursor: item.numberOfElements ? "pointer" : "default" }} onClick={() => item.groupId && item.numberOfElements && goToGroup(item.groupId)}>
                    <span className="icon icon__group"></span>
                    <span>{item.name}</span>
                </span>}
                {!!item.numberOfElements && <span className="table__cell__item-count mod-margin-left-4">({item.numberOfElements})</span>}
                {!!item.parentGroupName && <span>[{item.parentGroupName}]</span>}
                {item.groupId && props.allowGroupSelection ? <>
                    <input id={`selected-group-${index}`} type="checkbox" className="checkbox" />
                    <label htmlFor={`selected-group-${index}`}
                        onClick={() => setSelectedProduct(selectedProduct ? null : item)}
                        className="button button--gray button--padded checkbox-button button--print mod-margin-left-4">
                        <span className="checkbox-button__icon"></span>
                    </label>
                </> : null}
            </span>
        </li >
    })

    return <Overlay>
        <h1 className="overlay__title">{t('Invoice.Table.buttonNewFromPricelist')}</h1>
        <button className="button overlay__close" data-hook="close-overlay" onClick={props.onCloseButtonClicked}>
            <span className="icono-cross"></span>
        </button>

        <div className="info__container">
            <div className="info__row">
                <label className="info__row-name">{t("Header.search")}</label>

                <div className="info__row-content info__row-content--extended">
                    <Formik initialValues={{ searchString: searchString || "" }} onSubmit={({ searchString }) => {
                        setSearchString(searchString)
                    }}>
                        <Form className="nav__search-container nav__search-container--responsive">

                            <Field className="nav__search" type="text" placeholder="Dio naziva proizvoda" name="searchString" />

                            <div className="nav__search-buton-container">
                                <button className="nav__search-button button--blue" type="submit"><span className="icon icon__search"></span></button>
                            </div>
                        </Form>
                    </Formik>

                </div>
            </div>

            <div className="table__wrapper">
                <ul className="table" data-hook="stop-propagation">
                    {previousGroupIds.length > 0 && <li className="table__row" style={{ cursor: "pointer", minHeight: "55px" }} >
                        <>
                            <span className="overlay__item overlay__item--right-middle"></span>
                            <span onClick={goBackInHierarchy} className="table__cell table__cell__icon mod-cursor-pointer">
                                <span className="icon icon__back"></span>
                            </span>
                        </>
                    </li>}
                    {elements}
                </ul>
            </div>


            {selectedProduct && React.createElement(props.selectedProductComponent, { selectedProduct })}
        </div>

    </Overlay>
}