import React from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'

type Props = {
    loadOptions?: (val: string) => Promise<any>
    placeholder?: string
    createNewLabel?: string
    onChange?: (newValue: any) => void
    onCreateOption?: (inputValue: string) => void
    noOptionsMessage?: string
}

export default function AutocompleteInput({ loadOptions, placeholder = 'Select...', onChange, onCreateOption, createNewLabel, noOptionsMessage }: Props) {
    // const noOptionsMsg = noOptionsMessage ? () => "Kreiraj novog klijenta" : undefined;
    const noOptionsMsg = () => "Trenutno nemate klijenata, kreirajte novog klijenta";


    return (
        <AsyncCreatableSelect
            onChange={onChange}
            onCreateOption={onCreateOption}
            placeholder={placeholder}
            cacheOptions
            defaultOptions
            autoFocus
            noOptionsMessage={noOptionsMsg}
            loadOptions={loadOptions}
            className="react-select-container"
            classNamePrefix="react-select"
            formatCreateLabel={createNewLabel ? inputValue => <div className='react-select__create-new-record'>{createNewLabel} "{inputValue}"</div> : undefined}
        />
    )
}
